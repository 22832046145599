import { ApiQuestion, ApiSpace } from "@operations-hero/lib-api-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateQuestion } from "../pages/account-settings/venue-form/Questions/QuestionFormReducerTypes";
import { VenueFormValues } from "../pages/account-settings/venue-form/VenueForm";

export interface SpacesQuestions extends ApiSpace {
  savedQuestions: string[];
  questionsToAdd?: CreateQuestion[] | ApiQuestion[];
  questionsToRemove?: string[];
}

export interface VenueSpaces {
  spaces: SpacesQuestions[];
  savedSpaces: string[];
  spacesToAdd?: string[];
  spacesToRemove?: string[];
}

export interface VenueSpacesListSliceState {
  data: VenueSpaces;
  values?: VenueFormValues;
}

export const venueSpacesList = createSlice({
  name: "venueSpacesList",
  initialState: {
    data: {},
  } as VenueSpacesListSliceState,
  reducers: {
    unload: (state: VenueSpacesListSliceState) => {
      state.data = {
        spaces: [],
        savedSpaces: [],
      };
      state.values = undefined;
    },
    setState: (
      state: VenueSpacesListSliceState,
      action: PayloadAction<VenueSpaces>
    ) => {
      state.data = action.payload;
    },
    setSpaces: (
      state: VenueSpacesListSliceState,
      action: PayloadAction<SpacesQuestions[]>
    ) => {
      const orderedArray = action.payload.sort((a, b) =>
        a.location.name.localeCompare(b.location.name)
      );
      state.data.spaces = orderedArray;
    },
    setSavedSpaces: (
      state: VenueSpacesListSliceState,
      action: PayloadAction<string[]>
    ) => {
      state.data.savedSpaces = action.payload;
    },
    setSpacesToAdd: (
      state: VenueSpacesListSliceState,
      action: PayloadAction<string[]>
    ) => {
      state.data.spacesToAdd = action.payload;
    },
    setSpacesToRemove: (
      state: VenueSpacesListSliceState,
      action: PayloadAction<string[] | undefined>
    ) => {
      state.data.spacesToRemove = action.payload;
    },
    setStateQuestions: (
      state: VenueSpacesListSliceState,
      action: PayloadAction<{ spaceId: string; questions: ApiQuestion[] }>
    ) => {
      const { spaceId, questions } = action.payload;
      const indexSpace = state.data.spaces.findIndex(
        (space) => space.id === spaceId
      );

      if (indexSpace !== -1) {
        state.data.spaces[indexSpace].questions = questions;
      }
    },
    setQuestionsToAdd: (
      state: VenueSpacesListSliceState,
      action: PayloadAction<{
        spaceId: string;
        questionsToAdd: CreateQuestion[] | ApiQuestion[];
      }>
    ) => {
      const index = state.data.spaces.findIndex(
        (data) => data.id === action.payload.spaceId
      );

      if (index !== -1) {
        state.data.spaces[index].questionsToAdd = action.payload.questionsToAdd;
      }
    },
    setQuestionsToRemove: (
      state: VenueSpacesListSliceState,
      action: PayloadAction<{ spaceId: string; questionsToRemove: string[] }>
    ) => {
      const index = state.data.spaces.findIndex(
        (data) => data.id === action.payload.spaceId
      );

      if (index !== -1) {
        state.data.spaces[index].questionsToRemove =
          action.payload.questionsToRemove;
      }
    },
    setValues: (
      state: VenueSpacesListSliceState,
      action: PayloadAction<VenueFormValues>
    ) => {
      state.values = action.payload;
    },
    unloadValues: (state: VenueSpacesListSliceState) => {
      state.values = undefined;
    },
  },
});

export const {
  unload,
  setState,
  setSpaces,
  setSavedSpaces,
  setSpacesToAdd,
  setSpacesToRemove,
  setStateQuestions,
  setQuestionsToAdd,
  setQuestionsToRemove,
  setValues,
  unloadValues,
} = venueSpacesList.actions;

export default venueSpacesList.reducer;
