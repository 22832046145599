import { useMemo } from "react";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";
import { RootState } from "../../store";
import { useAuthentication } from "../auth/AuthProvider";
import { useProductSubscriptions } from "../shell/AppShell";

export const useProjectField = () => {
  const { project } = useSelector((state: RootState) => state.projectPageSlice);

  const { source } = useSelector((state: RootState) => state.newRequestForm);

  const { isPlanningAdmin } = useAuthentication();
  const { hasPlanning } = useProductSubscriptions();
  const location = useLocation();

  const isProjectPage = useMemo(() => {
    const isProjectPage = matchPath(
      { path: "/planning/projects/:id/scheduling", end: true },
      location.pathname
    );
    return isProjectPage;
  }, [location]);

  const value = useMemo(() => {
    if (isProjectPage && source === "planning.scheduling") return project;
    return null;
  }, [isProjectPage, source, project]);

  return {
    value,
    isDisabled:
      isProjectPage && source === "planning.scheduling" ? true : false,
    isPlanningAdmin,
    hasPlanning,
  };
};
