import { Box, useColorModeValue } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { SubFormHeader } from "../common-components/SubFormHeader";
import { SubFormFooter } from "../common-components/SubFormFooter";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { TaskForm } from "./TaskForm";
import { FormikProps, useFormikContext } from "formik";
import {
  ApiTaskbookTask,
  TaskBookFormValues,
} from "../../../../store/taskbook.slice";
import { newTaskPrefix } from "../TaskBookForm";

const getNewTask = (lastOrder: number): Partial<ApiTaskbookTask> => ({
  name: "",
  description: "",
  isRequired: true,
  order: lastOrder + 1,
  taskAttachments: [],
  id: `${newTaskPrefix}${new Date().getTime()}`,
});

export interface TaskFormSectionProps {
  compareTasksOrder: (task1: ApiTaskbookTask, task2: ApiTaskbookTask) => number;
}

export const TaskFormSection: React.FC<TaskFormSectionProps> = ({
  compareTasksOrder,
}) => {
  const [isNew, setIsNew] = useState(false);
  const titleColor = useColorModeValue("black", "white");
  const errorColor = useColorModeValue("red.500", "red.200");
  const { values, setValues, getFieldMeta }: FormikProps<TaskBookFormValues> =
    useFormikContext();

  const addNewTask = useCallback(() => {
    const newTask = getNewTask(values.tasks.length);

    const newTaskArray = [...values.tasks, newTask as ApiTaskbookTask];
    const newValues = { ...values, tasks: newTaskArray };

    setValues(newValues);
    setIsNew(true);
  }, [setValues, values]);

  const removeTask = useCallback(
    (idx: number) => {
      const newTaskArray = values.tasks.filter((_t, index) => index !== idx);
      const newTasksValues = newTaskArray.map((task, index) => {
        task.order = index + 1;
        return task;
      });
      const newValues = { ...values, tasks: newTasksValues };

      setValues(newValues);
      setIsNew(false);
    },
    [setValues, values]
  );

  const reorder = useCallback(
    (list: ApiTaskbookTask[], startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const sourceOrder = result[startIndex].order;
      const targetOrder = result[endIndex].order;

      result[startIndex].order = targetOrder;
      result[endIndex].order = sourceOrder;

      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      const reorderedList = result.sort(compareTasksOrder);
      return reorderedList;
    },
    [compareTasksOrder]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const reorderedList = reorder(
        values.tasks,
        result.source.index,
        result.destination.index
      );

      const newValues = { ...values, tasks: reorderedList };
      setValues(newValues);
    },
    [reorder, setValues, values]
  );

  useEffect(() => {
    if (isNew) {
      const lastStep = values.tasks[values.tasks.length - 1];
      document
        .getElementById(lastStep.id)
        ?.scrollIntoView({ behavior: "smooth" });
      setIsNew(false);
    }
  }, [values, isNew]);

  const hasErrors = useCallback(
    (index: number, fields: string[]): boolean => {
      let response = false;
      fields.forEach((field) => {
        const { error, touched } = getFieldMeta(`tasks[${index}].${field}`);
        if (error && touched) {
          response = true;
        }
      });
      return response;
    },
    [getFieldMeta]
  );

  return (
    <>
      <SubFormHeader
        title="Task Steps"
        textColor={titleColor}
        buttonLabel="Add Step"
        buttonColor="blue.500"
        addSubFormFunction={addNewTask}
      />
      <Box display="block" boxSizing="content-box">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="tasks-droppable-area">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {values.tasks.map((task, index) => {
                  return (
                    <Draggable
                      index={index}
                      key={task.id}
                      draggableId={task.id}
                    >
                      {(provided, snapshot) => (
                        <Box
                          id={task.id}
                          minH={6}
                          padding={4}
                          mt={8}
                          borderRadius={4}
                          border="1px solid"
                          borderColor={
                            hasErrors(index, ["name", "description"])
                              ? errorColor
                              : "blue.500"
                          }
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <TaskForm
                            task={task}
                            index={index}
                            provided={provided}
                            deleteTaskStep={removeTask}
                          />
                        </Box>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <SubFormFooter
        title="Add Step to Task Book"
        color="blue.500"
        addSubFormFunction={addNewTask}
        isEnabled={values.tasks.length > 0}
      />
    </>
  );
};
