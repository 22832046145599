import { Box, Icon, IconButton } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { FC, useCallback, useMemo, useState } from "react";
import { MdQrCode2 } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { setQRLookupModalIsOpen } from "../../../../store/qr-lookup.slice";
import { useAuthentication } from "../../../auth/AuthProvider";
import { useProductSubscriptions } from "../../AppShell";
import { CreateMenu } from "./CreateMenu";
import { SearchAutocomplete } from "./SearchAutocomplete";

export type AllowedProductSearch = {
  canSearchRequests: boolean;
  canSearchEvents: boolean;
  canSearchInvRequests: boolean;
  canSearchInvoices: boolean;
};

export type QuickActionsProps = {};

export const QuickActions: FC<QuickActionsProps> = () => {
  const dispatch = useDispatch();
  const [isSearchBoxVisible, setSearchBoxVisible] = useState(false);

  const { isApprover, isTechnician, isReviewer, isAdmin, isVenueManager } =
    useSelector((root: RootState) => root.localCache);
  const { enableInvoicesForEvents } = useSelector(
    (root: RootState) => root.eventSettingsSlice
  );
  const { isEventAdmin, isInventoryAdmin, isProductAdmin } =
    useAuthentication();
  const { hasRequests, hasEvents, hasInventory } = useProductSubscriptions();

  const allowedProductSearch: AllowedProductSearch = useMemo(() => {
    const hasWorkflowAccess =
      isApprover || isTechnician || isReviewer || isAdmin;
    return {
      canSearchRequests: hasRequests && (isProductAdmin || hasWorkflowAccess),
      canSearchEvents: hasEvents && (isEventAdmin || isVenueManager),
      canSearchInvRequests: hasInventory && isInventoryAdmin,
      canSearchInvoices: hasEvents && !!enableInvoicesForEvents && isEventAdmin,
    };
  }, [
    hasRequests,
    isProductAdmin,
    isApprover,
    isTechnician,
    isReviewer,
    isAdmin,
    hasEvents,
    isEventAdmin,
    isVenueManager,
    hasInventory,
    isInventoryAdmin,
    enableInvoicesForEvents,
  ]);

  const showSearchBox = useMemo(() => {
    const {
      canSearchEvents,
      canSearchInvRequests,
      canSearchInvoices,
      canSearchRequests,
    } = allowedProductSearch;
    return (
      canSearchEvents ||
      canSearchInvRequests ||
      canSearchInvoices ||
      canSearchRequests
    );
  }, [allowedProductSearch]);

  const handleOpenQRLookup = () => {
    dispatch(setQRLookupModalIsOpen(true));
  };
  const onGlobalSearchClick = useCallback((value: boolean) => {
    setSearchBoxVisible(value);
  }, []);

  return (
    <Box
      width="full"
      position="relative"
      justifyContent="flex-end"
      flex="1"
      alignSelf="center"
    >
      {!showSearchBox ? (
        <Box
          width="full"
          display="flex"
          flexDir="row"
          flexWrap="wrap"
          justifyContent="flex-end"
          alignItems="flex-end"
          gap="2"
          marginTop="2"
        >
          <CreateMenu />
          <IconButton
            size="sm"
            aria-label="Scan QR Code"
            onClick={handleOpenQRLookup}
            icon={<Icon h={8} w={8} as={MdQrCode2} />}
          />
        </Box>
      ) : (
        <AnimatePresence>
          {!isSearchBoxVisible && (
            <Box
              top={isSearchBoxVisible ? 4 : "unset"}
              width="full"
              as={motion.div}
              animate={{
                zIndex: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.3,
                },
              }}
              display="flex"
              flexDir="row"
              flexWrap="wrap"
              justifyContent="flex-end"
              alignContent="stretch"
              alignItems="center"
              gap="2"
              marginTop="1.5"
            >
              <CreateMenu />
              <IconButton
                size="sm"
                aria-label="Scan QR Code"
                onClick={handleOpenQRLookup}
                icon={<Icon h={8} w={8} as={MdQrCode2} />}
              />
              {/*
              This icon is just to simulate the positioning of the "real" one.
              This way the siblings layout is kept even when the search control is expanded or shrink.
              */}
              <IconButton aria-label="search-shadow" visibility="hidden" />
            </Box>
          )}
        </AnimatePresence>
      )}

      {!showSearchBox ? null : (
        <Box
          as={motion.div}
          position="absolute"
          alignSelf="baseline"
          top={1.5}
          right={0}
          animate={{
            left: isSearchBoxVisible ? 0 : "unset",
            transition: {
              duration: 0.3,
              type: "tween",
            },
          }}
        >
          <SearchAutocomplete
            onSearchClick={onGlobalSearchClick}
            isSearchBoxExpanded={isSearchBoxVisible}
            allowedProductSearch={allowedProductSearch}
          />
        </Box>
      )}
    </Box>
  );
};
