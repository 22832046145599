import { Checkbox, Divider, Grid, GridItem, Heading } from "@chakra-ui/react";
import { ApiBudgetSummary } from "@operations-hero/lib-api-client";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AssigneeAutocompleteControl } from "../../../components/form-helpers/AssigneeAutocompleteControl";
import { BudgetAutocompleteControl } from "../../../components/form-helpers/BudgetAutocompleteControl";
import { HoursFieldInputControl } from "../../../components/form-helpers/HoursFieldInputControl";
import { LocationAutocompleteControl } from "../../../components/form-helpers/LocationAutocompleteControl";
import { NumberInputControl } from "../../../components/form-helpers/NumberInputControl";
import { ReasonAutocompleteControl } from "../../../components/form-helpers/ReasonAutocompleteControl";
import { ReportingCategoryAutocompleteControl } from "../../../components/form-helpers/ReportingCategoryAutocompleteControl";
import { RequesterAutocompleteControl } from "../../../components/form-helpers/RequesterAutocompleteControl";
import { TextEditorControl } from "../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import { TextInputControl } from "../../../components/form-helpers/TextInputControl";
import { WorkflowAutocompleteControl } from "../../../components/form-helpers/WorkflowAutocompleteControl";
import { RootState } from "../../../store";
import {
  ScheduledRequestProps,
  setScheduleRequestWorkflow,
  setWillRequestRepeat,
} from "../../../store/scheduled-request-form/schedule-request-form.slice";
import { Products } from "../location-list/LocationList";
import { ScheduleRequestFormSchema } from "./ScheduleFormsSchemas";

export interface ScheduledRequestFormProps {
  debouncedChangeFormikValues: (values: ScheduledRequestProps) => void;
  isEdit: boolean;
  activeStep: number;
  totalSteps: number;
}

export const ScheduledRequestForm: FC<ScheduledRequestFormProps> = ({
  isEdit,
  debouncedChangeFormikValues,
  activeStep,
  totalSteps,
}) => {
  const { scheduleRequestForm } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handleOnChangeWillRepeat = useCallback(() => {
    dispatch(setWillRequestRepeat(!scheduleRequestForm.willRequestRepeat));
  }, [scheduleRequestForm.willRequestRepeat, dispatch]);

  const { scheduledRequest, willRequestRepeat, isLoading } =
    scheduleRequestForm;

  const reasonFilterOptions = useMemo(
    () => [
      "Audit",
      "Inspection",
      "Preventive",
      "Safety",
      "State Mandated Inspection",
    ],
    []
  );

  return (
    <>
      {!isLoading && (
        <>
          <Heading my={4} fontSize={["2xl", "3xl"]}>
            {isEdit ? "Update" : "Create"}{" "}
            {`Schedule Request (${activeStep} of ${totalSteps})`}
          </Heading>
          <Checkbox
            fontWeight="bold"
            onChange={handleOnChangeWillRepeat}
            isChecked={willRequestRepeat}
          >
            This request will repeat
          </Checkbox>
          <Divider bgColor="gray.300" my={4} />
          <Formik
            onSubmit={() => {}}
            initialValues={scheduledRequest}
            validationSchema={ScheduleRequestFormSchema}
            initialTouched={{
              name: true,
              location: true,
              workflow: true,
              requester: true,
            }}
            enableReinitialize
          >
            {(props) => {
              const { values } = props;
              debouncedChangeFormikValues(values);

              return (
                <Form>
                  <Grid
                    templateColumns={"repeat(12, 1fr)"}
                    gap={4}
                    mt={2}
                    alignItems="center"
                  >
                    <GridItem colSpan={12}>
                      <TextInputControl
                        name="name"
                        value={null}
                        autoFocus={true}
                        label="Name this Schedule"
                        placeholder="Ex. Montly HVAC Check"
                      />
                    </GridItem>

                    {values.workflow && (
                      <GridItem colSpan={[12, 12, 6, 6]}>
                        <WorkflowAutocompleteControl
                          name="workflow"
                          label="Workflow"
                          allowEmpty={false}
                          value={values.workflow}
                          onChange={(work) =>
                            dispatch(
                              setScheduleRequestWorkflow(
                                work ? work : undefined
                              )
                            )
                          }
                        />
                      </GridItem>
                    )}

                    {values.workflow && values.requester && (
                      <GridItem colSpan={[12, 12, 6, 6]}>
                        <RequesterAutocompleteControl
                          name="requester"
                          label="Requester"
                          workflow={values.workflow}
                          value={values.requester}
                          location={values.location!}
                        />
                      </GridItem>
                    )}

                    <GridItem colSpan={12}>
                      <LocationAutocompleteControl
                        value={values.location ? values.location : null}
                        label="Location"
                        name="location"
                        productName={Products.HeroHQ}
                      />
                    </GridItem>

                    <GridItem colSpan={[12, 12, 6, 6]}>
                      <ReportingCategoryAutocompleteControl
                        name="reportingCategory"
                        label="Category"
                        value={values.reportingCategory}
                        workflow={values.workflow}
                      />
                    </GridItem>

                    {values.workflow && (
                      <GridItem colSpan={[12, 12, 6, 6]}>
                        <ReasonAutocompleteControl
                          label="Reason"
                          name="reason"
                          value={values.reason}
                          workflow={values.workflow}
                          defaultValue="Preventive"
                          filterOptions={reasonFilterOptions}
                        />
                      </GridItem>
                    )}

                    {values.workflow && values.location && (
                      <GridItem colSpan={12}>
                        <AssigneeAutocompleteControl
                          name="assignees"
                          label="Assign to (Optional)"
                          workflow={values.workflow}
                          location={values.location}
                          reportingCategory={
                            values.reportingCategory ?? undefined
                          }
                          value={values.assignees || null}
                        />
                      </GridItem>
                    )}

                    <GridItem colSpan={12}>
                      <BudgetAutocompleteControl
                        label="Budget (optional)"
                        name="budget"
                        value={(values.budget as ApiBudgetSummary) || null}
                        placeholder="Search existing budgets"
                        allowEmpty
                      />
                    </GridItem>

                    <GridItem colSpan={[12, 12, 6, 6]}>
                      <NumberInputControl
                        prefix="$"
                        name="estimatedCost"
                        value={values.estimatedCost}
                        label="Estimated Cost (Optional)"
                        precision={2}
                      />
                    </GridItem>

                    <GridItem colSpan={[12, 12, 6, 6]}>
                      <HoursFieldInputControl
                        name="estimatedHours"
                        value={values.estimatedHours || 0}
                        label="Estimated Hours (Optional)"
                        placeholder="Ex. 2h 30m"
                      />
                    </GridItem>

                    <GridItem colSpan={12}>
                      <TextEditorControl
                        name="summary"
                        label="Summary"
                        value={values.summary || ""}
                      />
                    </GridItem>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
};
