import {
  ApiAccount,
  ApiAsset,
  ApiComment,
  ApiLocation,
  ApiRequest,
  ApiRequestTaskBookDetail,
  ApiTaskBookType,
  ApiWorkflow,
  ApiWorkflowField,
} from "@operations-hero/lib-api-client";
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { PrintableRequestFields } from "./PrintableRequestFields";

export const PdfStyles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    paddingVertical: 24,
    paddingHorizontal: 36,
    fontSize: 14,
  },
  header: {
    flexDirection: "row",
    fontSize: 12,
    marginBottom: 20,
  },
  headerContainer: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  headerText: {
    flex: 1,
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  headerLogo: {
    flexGrow: 1,
    maxWidth: 100,
    maxHeight: 60,
    minHeight: 60,
  },
  row: {
    flexGrow: 1,
    flexDirection: "row",
    margin: 0,
    padding: 0,
  },
  fields: {
    marginBottom: 20,
  },
  longFieldLabel: {
    backgroundColor: "#ccc",
    border: 1,
    borderColor: "#ccc",
    fontWeight: "bold",
    padding: 5,
    width: "20%",
    margin: 0,
  },
  longFieldValue: {
    border: 1,
    borderColor: "#ccc",
    width: "80%",
    padding: 5,
    margin: 0,
  },
  fieldLabel: {
    backgroundColor: "#ccc",
    border: 1,
    borderColor: "#ccc",
    fontWeight: "bold",
    padding: 5,
    width: "40%",
    margin: 0,
  },
  fieldValue: {
    border: 1,
    borderColor: "#ccc",
    width: "60%",
    padding: 5,
    margin: 0,
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 36,
    right: 36,
    marginTop: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 8,
  },
});

export interface PrintableRequestProps {
  request: ApiRequest;
  assets?: ApiAsset[];
  ancestorsAssets?: Record<string, ApiLocation[]>;
  taskbooks?: ApiRequestTaskBookDetail[];
  ancestors?: ApiLocation[];
  visibleFields: ApiWorkflowField[];
  account: ApiAccount;
  format: "full" | "slim";
  comments: ApiComment[] | undefined;
  workflow: ApiWorkflow;
}

export const PrintableRequest = ({
  request,
  assets,
  taskbooks,
  ancestorsAssets,
  ancestors,
  visibleFields,
  account,
  format,
  comments,
  workflow,
}: PrintableRequestProps) => {
  return (
    <Page size="A4" style={PdfStyles.page} wrap={true}>
      {format === "full" && (
        <View style={PdfStyles.header}>
          <View style={PdfStyles.headerText}>
            <Text style={{ fontSize: 20 }}>{request.key}</Text>
            <View style={PdfStyles.row}>
              <Text style={{ marginTop: 10 }}>
                Priority: {request.priority.toUpperCase()}
              </Text>
              <Text
                style={{
                  marginTop: 10,
                  marginLeft: 10,
                  flexGrow: 1,
                }}
              >
                Type: {request.type.toUpperCase()}
              </Text>
            </View>
          </View>
          <View style={PdfStyles.headerLogo}>
            {!account.logo && (
              <Text style={{ fontSize: 14, textAlign: "right" }}>
                {account.name}
              </Text>
            )}
            {account.logo && (
              <Image
                src={account.logo}
                style={{
                  width: "auto",
                  height: "auto",
                  alignSelf: "flex-end",
                }}
              />
            )}
          </View>
        </View>
      )}
      <PrintableRequestFields
        request={request}
        ancestors={ancestors}
        visibleFields={visibleFields}
        format={format}
        workflow={workflow}
      />

      {format === "full" && (
        <>
          {comments && comments.length > 0 && (
            <View style={{ marginBottom: 20 }}>
              <View style={{ flexDirection: "column" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={PdfStyles.fieldLabel}>Comments</Text>
                </View>
              </View>
              <View
                style={{
                  flexGrow: 1,
                  minHeight: 60,
                  border: 1,
                  borderColor: "#ccc",
                }}
              >
                {comments &&
                  comments.map((comment, index) => (
                    <View
                      key={comment.id}
                      style={{
                        borderBottom:
                          index < comments.length - 1
                            ? "1px solid #ccc"
                            : "none",
                        padding: "5px",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 10,
                          margin: "5px",
                          fontWeight: 900,
                        }}
                      >
                        {comment.createdBy.firstName}{" "}
                        {comment.createdBy.lastName}:
                      </Text>
                      <Text
                        style={{
                          fontSize: 9,
                          margin: "5px",
                          fontWeight: "light",
                          fontStyle: "italic",
                        }}
                      >
                        {comment.comment}
                      </Text>
                    </View>
                  ))}
              </View>
            </View>
          )}

          {assets &&
            assets.map((asset) => (
              <View wrap={true}>
                <View style={PdfStyles.row}>
                  <View style={{ flexGrow: 1, flexDirection: "row" }}>
                    <Text style={PdfStyles.fieldLabel}>Name:</Text>
                    <Text
                      wrap
                      style={{ ...PdfStyles.fieldValue, maxWidth: "165px" }}
                    >
                      {asset.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexGrow: 1,
                      flexDirection: "row",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <Text style={PdfStyles.fieldLabel}>Tag:</Text>
                    <Text
                      wrap
                      style={{ ...PdfStyles.fieldValue, maxWidth: "165px" }}
                    >
                      {asset.externalId}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexGrow: 1,
                    flexDirection: "row",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <Text style={{ ...PdfStyles.fieldLabel, width: "20%" }}>
                    Location:
                  </Text>
                  <Text style={PdfStyles.longFieldValue}>
                    {`${asset.location?.name || "N/A"}`}
                    {ancestorsAssets &&
                      ancestorsAssets[asset.id] &&
                      ancestorsAssets[asset.id].length > 0 &&
                      ` (${ancestorsAssets[asset.id]
                        .map((l) => l.name)
                        .join(" > ")})`}
                    {asset.location?.active === false && ` (inactive)`}
                  </Text>
                </View>
              </View>
            ))}

          {taskbooks &&
            taskbooks.map((taskbook) => (
              <View>
                <View style={PdfStyles.row}>
                  <View
                    style={{
                      flexGrow: 1,
                      flexDirection: "row",
                    }}
                  >
                    <Text style={{ ...PdfStyles.fieldLabel, width: "30%" }}>
                      Taskbook Name:
                    </Text>
                    <Text style={{ ...PdfStyles.fieldValue, width: "70%" }}>
                      {taskbook.taskbook.name}
                    </Text>
                  </View>
                </View>
                {taskbook.tasks
                  .sort((a, b) => a.task.order - b.task.order)
                  .map((task) => (
                    <View
                      wrap={true}
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingTop: 8,
                        alignItems: "center",
                      }}
                      key={`taskbooKTask::${task.id}`}
                    >
                      <View style={{ flex: 1, marginRight: 10 }}>
                        <Text
                          style={{
                            flexShrink: 1,
                            textAlign: "left",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {task.task.name}:
                        </Text>
                      </View>
                      {taskbook.taskbook.type === ApiTaskBookType.scoring ? (
                        <View
                          style={{
                            flexDirection: "row",
                          }}
                        >
                          {/* Scoring Table 1-5 */}
                          {[1, 2, 3, 4, 5].map((value) => (
                            <View
                              style={{
                                width: 20,
                                height: 20,
                                border: "1px solid black",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor:
                                  task.score >= value ? "gray" : "transparent",
                              }}
                              key={`taskbooKTask::${task.id}::${value}`}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {value}
                              </Text>
                            </View>
                          ))}
                        </View>
                      ) : (
                        //Empty Box
                        <View
                          style={{
                            width: 20,
                            height: 20,
                            border: "1px solid black",
                            justifyContent: "center",
                            backgroundColor:
                              task.status === "complete" ? "gray" : "white",
                          }}
                        ></View>
                      )}
                    </View>
                  ))}
              </View>
            ))}
          <View style={{ marginBottom: 50, paddingTop: 10 }} fixed></View>
          <View style={PdfStyles.footer} fixed>
            <View style={{ width: "30%" }}>
              <Text style={PdfStyles.fieldLabel}>Name</Text>
              <View
                style={{
                  flexGrow: 1,
                  minHeight: 40,
                  borderBottom: 1,
                  borderTop: 1,
                  borderLeft: 1,
                  borderColor: "#ccc",
                  padding: 5,
                }}
              ></View>
            </View>
            <View style={{ width: "40%" }}>
              <Text style={PdfStyles.fieldLabel}>Signature</Text>
              <View
                style={{
                  flexGrow: 1,
                  minHeight: 40,
                  border: 1,
                  borderColor: "#ccc",
                  padding: 5,
                }}
              ></View>
            </View>
            <View style={{ width: "20%" }}>
              <Text style={PdfStyles.fieldLabel}>Date</Text>
              <View
                style={{
                  flexGrow: 1,
                  minHeight: 40,
                  borderBottom: 1,
                  borderTop: 1,
                  borderColor: "#ccc",
                  padding: 5,
                }}
              ></View>
            </View>
            <View style={{ width: "10%" }}>
              <Text style={{ ...PdfStyles.fieldLabel, width: "100%" }}>
                Hours
              </Text>
              <View
                style={{
                  flexGrow: 1,
                  minHeight: 40,
                  border: 1,
                  borderColor: "#ccc",
                  padding: 5,
                }}
              ></View>
            </View>
          </View>
          {/*   footer*/}
          <View style={{ ...PdfStyles.footer, bottom: 5 }} fixed>
            <Text>
              {request.created
                ? new Date(request.created).toLocaleString()
                : ""}
            </Text>
            <Text>HeroHQ by OperationsHero</Text>
            <Text>{new Date().toLocaleString()}</Text>
          </View>
        </>
      )}
    </Page>
  );
};
