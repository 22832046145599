import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box, Flex, Icon } from "@chakra-ui/react";
import { isValid, parseISO } from "date-fns";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  default as DatePicker,
  default as ReactDatePicker,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoChevronDownOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useDateTimeFormatter } from "../../hooks/useIntlFormatter";
import { RootState } from "../../store";
import { DEFAULT_LOCALE_DATE_OPTS } from "../dates/LocaleDate";
import "./styled-date-picker.css";

export interface StyledDatePickerProps {
  value: Date | string | null;
  format?: string;
  showTime?: boolean;
  showTimeOnly?: boolean;
  onChange?: (date: Date | null, name?: string) => void;
  placeholder?: string;
  isDisabled?: boolean;
  name: string;
  className?: string;
  hasError?: boolean;
  excludeDates?: Date[];
  filterDate?: (date: Date) => boolean;
  filterTime?: (date: Date) => boolean;
  showCustomLocaleInput?: boolean;
  customLocaleInputOpts?: Intl.DateTimeFormatOptions;
}

export const StyledDatePicker = ({
  value,
  format,
  showTime,
  showTimeOnly,
  onChange,
  placeholder,
  isDisabled,
  name,
  hasError = false,
  className = "",
  excludeDates,
  filterDate,
  filterTime,
  showCustomLocaleInput,
  customLocaleInputOpts,
}: StyledDatePickerProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const themeClass = useColorModeValue(
    "light-theme-styled-date-picker",
    "dark-theme-styled-date-picker"
  );
  const datepickerRef = useRef<ReactDatePicker>(null);

  const { locale } = useSelector((state: RootState) => state.global);
  const { formatDateTime } = useDateTimeFormatter(locale);

  const handleDateChange = useCallback(
    (newValue: Date) => {
      if (onChange) onChange(newValue, name);
      else setSelectedDate(newValue);
    },
    [onChange, name]
  );

  const setDatepickerFocus = useCallback(() => {
    if (isDisabled) {
      return;
    }
    datepickerRef.current?.setFocus();
  }, [isDisabled]);

  useEffect(() => {
    if (!value) {
      setSelectedDate(null);
      return;
    }

    if (typeof value === "string") {
      const parsedDate = parseISO(value);
      if (isValid(parsedDate)) {
        setSelectedDate(parsedDate);
      }
      return;
    }

    setSelectedDate(value);
  }, [value]);

  const dateFormat = useMemo(() => {
    if (format) {
      return format;
    }
    return showTime ? "MMM-dd-yyyy h:mm aa" : "MMM-dd-yyyy";
  }, [format, showTime]);

  const CustomLocaleInput = forwardRef(({ value, onClick }: any, ref: any) => {
    if (!value) return <Box />;
    return (
      <Flex
        p={4}
        h="full"
        w="full"
        ml={-4}
        ref={ref}
        cursor="pointer"
        onClick={onClick}
        position="absolute"
        alignItems="center"
      >
        {formatDateTime(new Date(value), {
          ...DEFAULT_LOCALE_DATE_OPTS,
          ...customLocaleInputOpts,
        })}
      </Flex>
    );
  });

  return (
    <Box
      borderRadius={4}
      className={`${themeClass} ${className} ${hasError && "datepickerError"}`}
    >
      <DatePicker
        placeholderText={placeholder || "Select a date"}
        selected={selectedDate}
        onChange={handleDateChange}
        timeIntervals={15}
        showTimeSelect={showTime === true}
        showTimeSelectOnly={showTimeOnly}
        disabled={isDisabled}
        autoComplete="off"
        name={name}
        id={name}
        ref={datepickerRef}
        filterDate={filterDate}
        filterTime={filterTime}
        excludeDates={excludeDates}
        dateFormat={!showCustomLocaleInput ? dateFormat : undefined}
        customInput={
          showCustomLocaleInput && selectedDate ? (
            <CustomLocaleInput />
          ) : undefined
        }
      />
      <Icon
        as={IoChevronDownOutline}
        position="relative"
        float="right"
        mt={-25}
        mr={4}
        zIndex={0}
        onClick={setDatepickerFocus}
      />
    </Box>
  );
};
