import { Box } from "@chakra-ui/react";
import {
  ApiUserGroupMemberType,
  ApiUserSummary,
  ApiWorkflowGroupPolicy,
} from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import { FocusEventHandler, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useShowToast } from "../../hooks/showToast";
import { RootState } from "../../store";
import { debounce } from "../../utils/debounce";
import { useAuthentication } from "../auth/AuthProvider";
import { UserBadge } from "../badges/UserBadge";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomSelectComponentProp,
  UserOptionsProps,
  UserSingleValueSelect,
} from "./select-overrides-types";

export interface UserAutocompleteProps {
  name?: string;
  value: ApiUserSummary | null;
  allowEmpty?: boolean;
  emptyText?: string;
  onChange?: (user: ApiUserSummary | null) => void;
  onBlur?: FocusEventHandler;
  isDisabled?: boolean;
  isInvalid?: boolean;
  displayLeadGroups?: boolean;
  notInGroups?: string | string[];
  bgColor?: string;
}

export interface UserAutocompleteGroup
  extends Omit<ApiUserSummary, "timeZone"> {
  isGroup?: boolean;
  groupMembers?: ApiUserSummary[];
}

const CustomOptionComponent = createOptionComponent(UserBadge);

const CustomSingleValueComponent = (props: UserSingleValueSelect) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <UserBadge value={data} />
    </Box>
  );
};

export const UserAutocomplete = ({
  allowEmpty,
  emptyText,
  onChange,
  onBlur,
  name,
  value: user,
  isDisabled,
  displayLeadGroups,
  notInGroups,
  bgColor,
}: UserAutocompleteProps) => {
  const showToast = useShowToast();
  const { currentAccount, apiClient, currentUser, isProductAdmin } =
    useAuthentication();

  const { workflow } = useSelector((state: RootState) => state.requestForm);
  const { policyMap } = useSelector((state: RootState) => state.localCache);

  const canAddGroups = useMemo(() => {
    const isWorkflowAdmin = workflow && policyMap[workflow.id].admin;

    return isProductAdmin || isWorkflowAdmin;
  }, [isProductAdmin, policyMap, workflow]);

  const nullUser: ApiUserSummary = useMemo(
    () => ({
      firstName: emptyText || "No User",
      lastName: "",
      id: "null-user",
      email: "",
      phone: "",
      profileImage: "",
      timeZone: null,
    }),
    [emptyText],
  );

  const handleChange = useCallback(
    (value: ApiUserSummary | null) => {
      const newValue =
        value === null || value.id === nullUser.id ? null : value;
      if (onChange) onChange(newValue);
    },
    [onChange, nullUser],
  );

  const loadUserGroups = useCallback(
    async (searchText: string) => {
      if (!workflow) return [] as UserAutocompleteGroup[];
      const response = await apiClient.findWorkflowPolicies(
        currentAccount.id,
        workflow.id,
        { type: "group", search: searchText },
      );

      const userGroups = (response.data as ApiWorkflowGroupPolicy[])
        .filter((item) => item.type === "group")
        .map((g) => g.group);

      const groups = await Promise.all(
        userGroups.map(async (group) => {
          return {
            ...group,
            members: await apiClient.findUserGroupMembers(
              currentAccount.id,
              group.id,
              {
                search: searchText,
              },
            ),
          };
        }),
      );

      const result = groups.filter((item) => {
        return item.members.data.some(
          (member) =>
            canAddGroups ||
            (member.memberType === ApiUserGroupMemberType.lead &&
              member.user.id === currentUser.id),
        );
      });

      const mapValues: UserAutocompleteGroup[] = result.map((group) => ({
        id: group.id,
        firstName: group.name,
        lastName: "",
        email: "",
        phone: "",
        profileImage: "",
        isGroup: true,
        groupMembers: group.members.data.map((item) => item.user),
      }));

      return mapValues;
    },
    [apiClient, canAddGroups, currentAccount.id, currentUser.id, workflow],
  );

  const loadOptions = useCallback(
    async (inputValue: string, cb: any) => {
      try {
        const users: any = [];

        if (allowEmpty) {
          users.push(nullUser);
        }
        let response: ApiUserSummary[];

        const res = await apiClient.findAccountUsers(currentAccount.id, {
          search: inputValue,
          notInGroups,
        });

        response = res.data;

        users.push(...response);
        if (displayLeadGroups) {
          const groups = await loadUserGroups(inputValue);
          users.push(...groups);
        }
        cb(users);
        return users;
      } catch (error) {
        showToast("error", "Something went wrong loading users");
      }
    },
    [
      allowEmpty,
      apiClient,
      currentAccount.id,
      displayLeadGroups,
      nullUser,
      loadUserGroups,
      showToast,
      notInGroups,
    ],
  );

  const debouncedLoadOptions = debounce(loadOptions, 250);

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: UserOptionsProps) => CustomOptionComponent(props),
      SingleValue: (props: UserSingleValueSelect) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  return (
    <AsyncSelect
      key={`users::${user?.id}`}
      name={name}
      onBlur={onBlur}
      defaultOptions={true}
      value={user || nullUser}
      cacheOptions={true}
      loadOptions={debouncedLoadOptions}
      onChange={handleChange}
      components={components}
      getOptionValue={(item: ApiUserSummary) => item.id}
      isDisabled={isDisabled}
      chakraStyles={commonStyles}
    />
  );
};
