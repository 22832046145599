import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ExportType } from "@operations-hero/lib-api-client";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { FaHeart } from "react-icons/fa";
import { FiHeart } from "react-icons/fi";
import { IoDownloadOutline } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { ExportModal } from "../../../components/export/ExportModal";
import { RootState, useThunkDispatch } from "../../../store";
import {
  getRequestFilter,
  setRequestListTitle,
  updatedSavedFilters,
  updateRequestFilters,
} from "../../../store/request-list.slice";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { BulkPrintButton } from "../print/BulkPrintButton";

const MAX_FILTERS = 19;
const MIN_FILTERS = 0;

export interface SaveDataObject {
  name: string;
  created: string;
  filter: string;
}

const RequestListActions = () => {
  const { apiClient, currentAccount } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const {
    isOpen: isSaveModalOpen,
    onOpen: onSaveModalOpen,
    onClose: onSaveModalClose,
  } = useDisclosure();
  const {
    isOpen: isSearchModalOpen,
    onOpen: onSearchModalOpen,
    onClose: onSearchModalClose,
  } = useDisclosure();
  const {
    isOpen: isExportModalOpen,
    onOpen: onExportModalOpen,
    onClose: onExportModalClose,
  } = useDisclosure();

  const {
    savedSearchFilters,
    queryStringFilter,
    totalRequests,
    filters,
    sort,
  } = useSelector((state: RootState) => state.requestList);
  const { displayMode } = useSelector(
    (state: RootState) => state.requestsSlice
  );

  const options = getRequestFilter(sort, filters);
  const [searchName, setSearchName] = useState("");
  const [selectedFilterIndex, setSelectedFilterIndex] = useState<number | null>(
    null
  );

  const isMobileMode = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  const isDesktop = useMemo(
    () => !isMobileMode && !isTablet,
    [isMobileMode, isTablet]
  );

  const textColor = useColorModeValue("blue.500", "white");
  const isDarkMode = useColorModeValue(false, true);

  const scrollColorDark = useMemo(
    () => ({
      "&::-webkit-scrollbar": {
        width: "16px",
        borderRadius: "6px",
        bgColor: "gray.500",
      },
      "&::-webkit-scrollbar-thumb": {
        bgColor: "gray.300",
        borderRadius: "6px",
      },
    }),
    []
  );

  const scrollColorLight = useMemo(
    () => ({
      "&::-webkit-scrollbar": {
        width: "16px",
        borderRadius: "6px",
        bgColor: "gray.100",
      },
      "&::-webkit-scrollbar-thumb": {
        bgColor: "gray.300",
        borderRadius: "6px",
      },
    }),
    []
  );

  const handleInputChange = useCallback((event: any) => {
    setSearchName(event.target.value);
  }, []);

  const onDelete = useCallback(
    (filtertoDelete: SaveDataObject) => {
      const updatedFilters = savedSearchFilters.filter((filter) => {
        return filter !== filtertoDelete;
      });
      thunkDispatch(
        updatedSavedFilters({
          apiClient,
          accountId: currentAccount.id,
          savedFilters: updatedFilters as [],
        })
      );
    },
    [apiClient, currentAccount.id, savedSearchFilters, thunkDispatch]
  );

  const onSave = useCallback(() => {
    if (queryStringFilter !== "") {
      const saveFilter: SaveDataObject = {
        name: searchName,
        created: new Date().toISOString(),
        filter: queryStringFilter,
      };
      const savedFiltersArray = [saveFilter, ...savedSearchFilters];
      thunkDispatch(
        updatedSavedFilters({
          apiClient,
          accountId: currentAccount.id,
          savedFilters: savedFiltersArray as [],
        })
      );
    }
    onSaveModalClose();
  }, [
    queryStringFilter,
    onSaveModalClose,
    searchName,
    savedSearchFilters,
    thunkDispatch,
    apiClient,
    currentAccount.id,
  ]);

  const onConfirm = useCallback(
    (filter: SaveDataObject) => {
      onDelete(filter);
      setSelectedFilterIndex(null);
    },
    [onDelete]
  );

  const canSave = useMemo(() => {
    return queryStringFilter === "" ? false : true;
  }, [queryStringFilter]);

  const handleOnClickLink = useCallback(
    (filterName: string, filterObj: string) => {
      onSearchModalClose();
      dispatch(setRequestListTitle(capitalizeFirstLetter(filterName)));
      const decodedFilter = JSON.parse(decodeURIComponent(atob(filterObj)));
      dispatch(updateRequestFilters(decodedFilter));
    },
    [onSearchModalClose, dispatch]
  );

  useEffect(() => {
    if (!isSearchModalOpen) setSelectedFilterIndex(null);
  }, [isSearchModalOpen]);

  return (
    <Box>
      <HStack>
        <Button
          ml={isDesktop ? 2 : 0}
          colorScheme="blue"
          variant="ghost"
          onClick={onSearchModalOpen}
          size={isDesktop ? "md" : "sm"}
          _focus={{ boxShadow: "none" }}
        >
          {isMobileMode ? "Searches" : "My Searches"}
        </Button>

        <IconButton
          colorScheme="blue"
          aria-label="Save filter"
          variant="outline"
          icon={<FiHeart />}
          onClick={onSaveModalOpen}
          isDisabled={!canSave}
          size={isDesktop ? "md" : "sm"}
        />

        {(isTablet || isDesktop) && (
          <>
            <IconButton
              colorScheme="blue"
              aria-label="Export"
              variant="outline"
              icon={<IoDownloadOutline />}
              onClick={onExportModalOpen}
              size={isDesktop ? "md" : "sm"}
              isDisabled={displayMode === "column"}
            />
            <BulkPrintButton isDisabled={displayMode === "column"} />
          </>
        )}
      </HStack>

      {/* Save Search Modal */}
      <Modal
        blockScrollOnMount={false}
        isOpen={isSaveModalOpen}
        onClose={onSaveModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <FaHeart />
              <Text>Save this search</Text>
            </HStack>
          </ModalHeader>
          <ModalBody>
            <Text color="gray.400">
              You can re-run these searches quickly in the future by clicking in
              the future by clicking on{" "}
              <Text as="span" fontWeight="bold">
                "My searches"
              </Text>
            </Text>

            <Text mt="5">Name this search: </Text>
            <Input
              value={searchName}
              onChange={handleInputChange}
              placeholder="Ex. HVAC Requests in York County"
              size="sm"
              mt="2"
            />
            <Box mt={6}>
              <Divider />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onSaveModalClose}>
              Close
            </Button>
            <Button colorScheme="blue" onClick={onSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* My Searches Modal */}
      <Modal
        blockScrollOnMount={false}
        isOpen={isSearchModalOpen}
        onClose={onSearchModalClose}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <FaHeart />
              <Text>Your Saved Searches</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            mb={8}
            maxH="md"
            sx={isDarkMode ? scrollColorDark : scrollColorLight}
          >
            <Stack
              justifyContent={"space-between"}
              flexDir="row"
              flexWrap="wrap"
            >
              {savedSearchFilters && savedSearchFilters.length > 0 ? (
                savedSearchFilters
                  .slice(MIN_FILTERS, MAX_FILTERS)
                  .map((filter, index) => (
                    <Fragment key={index}>
                      <Divider py={2} />
                      <StackItem
                        width="70%"
                        alignItems="center"
                        alignSelf="center"
                      >
                        <Button
                          variant="link"
                          onClick={() =>
                            handleOnClickLink(filter.name, filter.filter)
                          }
                          color={textColor}
                          _focus={{ boxShadow: "none" }}
                        >
                          <Icon as={BsSearch} mr={2} />
                          <Text
                            noOfLines={2}
                            maxW={
                              selectedFilterIndex === index ? "220px" : "300px"
                            }
                          >
                            {filter.name}
                          </Text>
                        </Button>
                      </StackItem>
                      <StackItem
                        w="30%"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        {selectedFilterIndex === index ? (
                          <ButtonGroup spacing="4" mt={4}>
                            <Button
                              colorScheme="red"
                              size="xs"
                              onClick={() => onConfirm(filter)}
                            >
                              Confirm
                            </Button>
                            <Button
                              size="xs"
                              onClick={() => setSelectedFilterIndex(null)}
                            >
                              Cancel
                            </Button>
                          </ButtonGroup>
                        ) : (
                          <IconButton
                            variant="outline"
                            colorScheme="red"
                            aria-label="Delete request"
                            icon={<RiDeleteBinLine />}
                            onClick={() => setSelectedFilterIndex(index)}
                            mt={2}
                          />
                        )}
                      </StackItem>
                    </Fragment>
                  ))
              ) : (
                <Text as="i">No filters saved</Text>
              )}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* export modal, this modal require remount to reset state */}
      {isExportModalOpen && (
        <ExportModal
          isOpen={isExportModalOpen}
          onClose={onExportModalClose}
          total={totalRequests}
          options={options}
          type={ExportType.request}
        />
      )}
    </Box>
  );
};

export default RequestListActions;
