import {
  Box,
  Button,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Stack,
  StackItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  ApiAsset,
  ApiLocation,
  ApiLocationReference,
  ApiLocationSummary,
  ApiReportingCategory,
  ApiRequestAsset,
  ApiWorkflowReportingCategorySummary,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BsPlus } from "react-icons/bs";
import { IoTrashSharp } from "react-icons/io5";
import { MdQrCode2 } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { OutlinedIconButton } from "../../../components/custom-icons/OutlinedIconButton";
import { Pager } from "../../../components/pager/Pager";
import { QrQuickScanModal } from "../../../components/qr-quick-scan/QrQuickScanModal";
import { LocationAutocomplete } from "../../../components/selects/LocationAutocomplete";
import { ReportingCategoryAutocomplete } from "../../../components/selects/ReportingCategoryAutocomplete";
import { RootState, useThunkDispatch } from "../../../store";
import { setAssetsCurrentPage } from "../../../store/request-form/request-form.slice";
import { addAsset } from "../../../store/request-form/thunks/addAsset.thunk";
import { loadAssets } from "../../../store/request-form/thunks/loadAssets.thunk";
import { removeAsset } from "../../../store/request-form/thunks/removeAsset.thunk";
import { useCategoryUtils } from "../../../utils/categoryUtils";
import { getVisibleFields } from "../../../utils/getVisibleFields";
import { useLocationUtils } from "../../../utils/locationUtils";
import { AssetCard } from "../../account-settings/asset-list/AssetCard";
import { AssetDetailModal } from "./AssetDetailModal";
import { AssetSearchAutocomplete } from "./AssetSearchAutocomplete";
import { DeleteAssetConfirmModal } from "./DeleteAssetConfirmModal";

interface AssetSectionProps {
  fieldRef?: RefObject<HTMLSelectElement>;
  isAssetRendered?: boolean;
  updateAssetRendered?: (value: boolean) => void;
  requestLocation?: ApiLocationSummary;
  reportingCategory?: ApiWorkflowReportingCategorySummary;
}
export const AssetSection = ({
  fieldRef,
  updateAssetRendered,
  requestLocation,
  reportingCategory,
}: AssetSectionProps) => {
  const { apiClient, currentAccount: account } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const toast = useToast();
  const displayValue = useBreakpointValue({
    base: "inline-block",
    sm: "inline-flex",
  });
  const textColor = useColorModeValue("gradarkslategrey", "white");
  const qrColor = useColorModeValue("black", "white");
  const { getChildrenId } = useLocationUtils();
  const { findAllChildrenForNodesRecursive } = useCategoryUtils();

  const [isLoading, setIsLoading] = useState(true);
  const [assetToAdd, setAssetToAdd] = useState<ApiAsset | null>(null);
  const [assetToRemove, setAssetToRemove] = useState<ApiAsset>();
  const [assetToView, setAssetToView] = useState<ApiAsset | null>();
  const [location, setLocation] = useState<ApiLocationSummary | null>(
    requestLocation || null
  );
  const [category, setCategory] = useState(reportingCategory);
  const [locationIds, setLocationIds] = useState<string[] | undefined>(
    requestLocation
      ? getChildrenId([requestLocation as ApiLocation])
      : undefined
  );
  const [categoriesIds, setCategoriesIds] = useState<string[] | undefined>(
    reportingCategory
      ? Array.from(
          findAllChildrenForNodesRecursive([reportingCategory.id])
        ).map((cat) => cat.id)
      : undefined
  );

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [showAssetSection, setShowAssetSection] = useState(false);

  const {
    isRequesterOnly,
    isApproverOnly,
    isContractorOnly,
    formIsLocked,
    request,
    assets: { data, page, total, pageSize },
    workflow,
    visibleFields,
  } = useSelector((state: RootState) => state.requestForm);

  const { showAssets } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const canAddRemoveAssets = useMemo(
    () =>
      workflow &&
      showAssets &&
      !formIsLocked &&
      !isRequesterOnly &&
      !isApproverOnly,
    [workflow, formIsLocked, isRequesterOnly, isApproverOnly, showAssets]
  );

  const shouldHideSection = useMemo(() => {
    if (!workflow) {
      return true;
    }

    if (!canAddRemoveAssets && data.length === 0) {
      return true;
    }

    if (!showAssets && data.length === 0) {
      return true;
    }

    return false;
  }, [data, workflow, canAddRemoveAssets, showAssets]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      dispatch(setAssetsCurrentPage(newPage));
    },
    [dispatch]
  );

  const assetToAddOnChange = useCallback(
    (asset: ApiAsset | null) => {
      if (!request || !asset) {
        return;
      }
      setAssetToAdd(asset);

      if (data.find((d) => d.id === asset.id)) {
        toast({
          duration: 1500,
          isClosable: true,
          position: "top",
          status: "success",
          title: "Asset already added",
        });
        setAssetToAdd(null);
        return;
      }

      thunkDispatch(
        addAsset({
          apiClient,
          account,
          idOrKey: request.key,
          assetId: asset.id,
        })
      )
        .then(unwrapResult)
        .then(() => {
          setAssetToAdd(null);
          toast({
            duration: 1500,
            isClosable: true,
            position: "top",
            status: "success",
            title: "Asset added",
          });
        })
        .catch(() => {
          toast({
            duration: 1500,
            isClosable: true,
            position: "top",
            status: "error",
            title: "Error adding asset",
          });
        });
    },
    [thunkDispatch, apiClient, account, request, data, toast]
  );

  const confirmRemoveAsset = useCallback(
    (asset: ApiRequestAsset, e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setAssetToRemove(asset);
    },
    []
  );

  const handleCancelRemove = useCallback(() => {
    setAssetToRemove(undefined);
  }, []);

  const handleAssetCardClick = useCallback(
    (asset: ApiAsset) => {
      if (!canAddRemoveAssets) return;
      setAssetToView(asset);
    },
    [canAddRemoveAssets]
  );

  const handleAssetDetailClose = useCallback(() => {
    setAssetToView(undefined);
  }, []);

  const handleRemoveAsset = useCallback(() => {
    if (!request || !assetToRemove || !canAddRemoveAssets) {
      return Promise.resolve();
    }
    return thunkDispatch(
      removeAsset({
        apiClient,
        account,
        idOrKey: request.key,
        assetId: assetToRemove.id,
      })
    )
      .then(unwrapResult)
      .then(() => {
        toast({
          duration: 1500,
          isClosable: true,
          position: "top",
          status: "success",
          title: "Asset Removed",
        });
      })
      .catch(() => {
        toast({
          duration: 1500,
          isClosable: true,
          position: "top",
          status: "error",
          title: "Error removing asset",
        });
      })
      .finally(() => {
        setAssetToRemove(undefined);
      });
  }, [
    apiClient,
    account,
    assetToRemove,
    canAddRemoveAssets,
    request,
    toast,
    thunkDispatch,
  ]);

  const handleOnChangeLocation = useCallback(
    (newLocation: ApiLocationSummary | null) => {
      setLocation(newLocation);
      if (!newLocation) {
        setLocationIds(undefined);
        return;
      }
      const childrens = getChildrenId([newLocation as ApiLocation]);
      if (childrens.length > 0) setLocationIds(childrens);
      else setLocationIds(undefined);
    },
    [getChildrenId]
  );

  const handleOnChangeCategory = useCallback(
    (value: ApiWorkflowReportingCategorySummary | null) => {
      if (!value) {
        setCategory(undefined);
        setCategoriesIds(undefined);
        return;
      }
      setCategory(value);
      const categoryChilds = value
        ? findAllChildrenForNodesRecursive([value.id])
        : new Set<ApiReportingCategory>();
      const categories = Array.from(categoryChilds);
      setCategoriesIds(categories ? categories.map((cat) => cat.id) : []);
    },
    [findAllChildrenForNodesRecursive]
  );

  const handleQrData = useCallback(
    (externalIds: string) => {
      apiClient
        .findAssets(account.id, { externalIds: [externalIds] })
        .then((result) => {
          assetToAddOnChange &&
            result.data.forEach((rData) => {
              assetToAddOnChange(rData);
            });
        });

      onClose();
    },
    [onClose, apiClient, account.id, assetToAddOnChange]
  );

  useEffect(() => {
    if (!request) {
      return;
    }
    setIsLoading(true);
    thunkDispatch(
      loadAssets({
        apiClient,
        account,
        key: request.key,
        page: page,
      })
    ).finally(() => setIsLoading(false));
  }, [thunkDispatch, apiClient, account, request, page]);

  useEffect(() => {
    if (updateAssetRendered) updateAssetRendered(true);
  }, [updateAssetRendered]);

  return workflow && shouldHideSection ? null : (
    <>
      <Box mb={4} w="100%" position="relative" alignItems="center">
        <HStack width="100%" justifyContent="space-between">
          <Heading size="md" w="40%" mb={4}>
            Assets
          </Heading>
          <HStack>
            <Button
              colorScheme="blue"
              variant="outline"
              leftIcon={<Icon as={BsPlus} />}
              onClick={() => {
                setShowAssetSection(true);
              }}
            >
              Add Asset
            </Button>
            <Button
              onClick={onOpen}
              color={qrColor}
              onTouchEnd={onOpen}
              zIndex={100}
              w={7}
            >
              <Icon h={7} w={7} as={MdQrCode2} />
            </Button>
          </HStack>
          <QrQuickScanModal
            isOpen={isOpen}
            onClose={onClose}
            onData={handleQrData}
          />
        </HStack>
        {canAddRemoveAssets && showAssetSection && (
          <Stack w="100%" spacing={4}>
            <StackItem w="100%">
              <FormLabel>Location</FormLabel>
              <LocationAutocomplete
                value={(location as ApiLocationReference) || null}
                onChange={handleOnChangeLocation}
              />
            </StackItem>
            <StackItem width="100%" display={displayValue} gap={4}>
              <Box width={["100%", "50%"]} pb={[4, "unset"]}>
                <ReportingCategoryAutocomplete
                  value={category ?? null}
                  onChange={handleOnChangeCategory}
                />
              </Box>
              <Box width={["100%", "50%"]}>
                <AssetSearchAutocomplete
                  value={assetToAdd}
                  onChange={assetToAddOnChange}
                  fieldRef={fieldRef}
                  locations={locationIds}
                  categories={categoriesIds}
                  key={`assets::${locationIds}::${categoriesIds}`}
                />
              </Box>
            </StackItem>
          </Stack>
        )}
      </Box>
      {data.length === 0 && (
        <Text pb={6} pt={2} color={textColor} fontSize="md">
          Search to add assets to the request.
        </Text>
      )}
      <Stack spacing={2}>
        {!isLoading &&
          data.map((asset) => (
            <AssetCard
              onClick={handleAssetCardClick}
              asset={asset}
              key={asset.id}
            >
              {canAddRemoveAssets && !isContractorOnly ? (
                <OutlinedIconButton
                  icon={<Icon as={IoTrashSharp} boxSize={5} />}
                  onClick={(e) => confirmRemoveAsset(asset, e)}
                />
              ) : (
                <></>
              )}
            </AssetCard>
          ))}
      </Stack>
      {total > pageSize && (
        <Pager
          pageSize={pageSize}
          currentPage={page}
          total={total}
          onPageChange={handlePageChange}
        />
      )}

      {assetToRemove && request && (
        <DeleteAssetConfirmModal
          onConfirm={handleRemoveAsset}
          onCancel={handleCancelRemove}
          asset={assetToRemove}
          request={request}
        />
      )}
      {assetToView && request && (
        <AssetDetailModal
          onClose={handleAssetDetailClose}
          asset={assetToView}
          request={request}
        />
      )}
    </>
  );
};
