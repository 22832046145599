import { ApiRequest } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import {
  getRequestFilter,
  LoadRequestsThunkParams,
} from "../../request-list.slice";
import { LoadingStatus } from "../project-list";

type FindRequestParams = Omit<
  LoadRequestsThunkParams,
  "pageSize" | "account"
> & {
  projectId: string;
};

export const findProjectRequests = createAsyncThunk(
  "project-requests/find",
  async ({ apiClient, projectId }: FindRequestParams, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { currentAccount } = state.auth;
    const { sort, filters } = state.requestList;
    let newFilters = { ...filters, pageSize: 20 };

    const options = getRequestFilter(sort, newFilters);

    const requests = await apiClient.findRequests(currentAccount.id, {
      ...options,
      projects: [projectId],
    });

    return {
      requests,
      options,
    };
  }
);

type RequestHandlers =
  | {
      requests: ApiRequest[];
      initCompleted: boolean;
    }
  | {
      loadingStatus: LoadingStatus;
    };
export const projectRequestsHandlers = <T extends RequestHandlers>(
  builder: ActionReducerMapBuilder<T>
) => {
  builder.addCase(findProjectRequests.fulfilled, (state, action) => {
    if ("requests" in state) {
      state.requests = action.payload.requests.data;
    }
    if ("initCompleted" in state) {
      state.initCompleted = true;
    }
  });
};
