import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react";
import {
  ApiComment,
  ApiEventOccurrence,
  ApiSpaceSummary,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  EventOccurrenceUpdateProps,
  removeQuestionResponse,
  toggleActivateEventOccurrence,
} from "../../../../store/events/event-details.slice";
import { createUpdateEventComment } from "../../../../store/events/thunks/eventComments.thunk";
import { getObjectArraysDifference } from "../../../../utils/getObjectArraysDifference";
import { getUniqueArrayObjects } from "../../../../utils/getUniqueArrayObjects";
import { CommentForm } from "../../../request-form/comments/CommentForm";
import { OccurrenceModalProps } from "./OcurrencesModal";

interface DeleteOccurrencesProps
  extends Omit<OccurrenceModalProps, "isOpen" | "occurrence" | "modalType"> {
  occurrences?: ApiEventOccurrence[];
  setLocalOccurrences?: Dispatch<SetStateAction<EventOccurrenceUpdateProps[]>>;
}

export const DeleteOccurrences: FC<DeleteOccurrencesProps> = ({
  eventId,
  onClose,
  occurrencesIds,
}) => {
  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const { apiClient, currentAccount } = useAuthentication();
  const [currentComment, setCurrentComment] = useState<ApiComment>();

  const { event, eventDates } = useSelector(
    (state: RootState) => state.eventDetails
  );

  const createEventComment = useCallback(async () => {
    try {
      if (!currentComment) return;

      await thunkDispatch(
        createUpdateEventComment({
          apiClient,
          eventId: eventId,
          accountId: currentAccount.id,
          isPublic: currentComment.isPublic,
          commentText: currentComment.comment,
        })
      );
    } catch (error) {
      showToast(
        "error",
        "Something went wrong creating a comment, please try again!"
      );
    }
  }, [
    apiClient,
    currentAccount.id,
    currentComment,
    eventId,
    showToast,
    thunkDispatch,
  ]);

  const deleteResponses = useCallback(
    (ids: string[]) => {
      // Get venues that are going to be canceled
      const venues = getUniqueArrayObjects(
        eventDates.map((date) => {
          if (ids.some((id) => id === date.id)) {
            return date.venue;
          }
          return undefined;
        }, [])
      );

      // Get remaining venues
      const oldVenues = getUniqueArrayObjects(
        eventDates.map((date) => {
          if (ids.every((id) => id !== date.id)) return date.venue;
          return undefined;
        })
      );

      // Get which venues responses need to be removed
      const venuesToRemove = getObjectArraysDifference(venues, oldVenues);

      // Remove venues responses if they exist
      if (event && venuesToRemove.length > 0) {
        apiClient
          .bulkDeleteQuestionResponse(currentAccount.id, {
            eventId: event.id,
            venueIds: venuesToRemove.map((v) => v.id),
          })
          .then((response) => {
            dispatch(removeQuestionResponse(response));
          });
      }

      // Get spaces that are going to be canceled
      const spaces = getUniqueArrayObjects(
        eventDates.reduce((result, data) => {
          if (ids.some((id) => id === data.id)) {
            if (result.length > 0) result = result.concat(data.spaces);
            else result = data.spaces;
          }
          return result;
        }, {} as ApiSpaceSummary[])
      );

      // Get remaining spaces
      const oldSpaces = getUniqueArrayObjects(
        eventDates.reduce((result, data) => {
          if (ids.every((id) => id !== data.id)) {
            if (result.length > 0) result = result.concat(data.spaces);
            else result = data.spaces;
          }
          return result;
        }, {} as ApiSpaceSummary[])
      );

      // Get which spaces responses need to be removed
      const spacesToRemove = getObjectArraysDifference(spaces, oldSpaces);

      // Remove spaces responses if they exist
      if (event && spacesToRemove.length > 0) {
        apiClient
          .bulkDeleteQuestionResponse(currentAccount.id, {
            eventId: event.id,
            spaceIds: spacesToRemove.map((s) => s.id),
          })
          .then((response) => {
            dispatch(removeQuestionResponse(response));
          });
      }
    },
    [apiClient, currentAccount.id, dispatch, event, eventDates]
  );

  const removeOccurrence = useCallback(() => {
    if (!occurrencesIds) return;
    deleteResponses(occurrencesIds);
    thunkDispatch(
      toggleActivateEventOccurrence({
        apiClient,
        accountId: currentAccount.id,
        occurrencesIds,
        eventId,
        type: "deactivate",
      })
    )
      .then(unwrapResult)
      .then(() => {
        showToast(
          "success",
          `Event ${
            occurrencesIds.length > 1 ? "occurrences were" : "occurrence was"
          } deleted successfully`
        );
      })
      .catch(() => {
        showToast(
          "error",
          "Something went wrong deactivating  occurrence(s), please try again!"
        );
      });
  }, [
    apiClient,
    currentAccount.id,
    deleteResponses,
    eventId,
    occurrencesIds,
    showToast,
    thunkDispatch,
  ]);

  const handleOnSubmit = useCallback(() => {
    if (!occurrencesIds || occurrencesIds.length === 0) return;
    currentComment && createEventComment();
    removeOccurrence();
    onClose();
  }, [
    createEventComment,
    currentComment,
    occurrencesIds,
    onClose,
    removeOccurrence,
  ]);

  return (
    <Box>
      <Box>
        <Text pb={4}>No request associated</Text>

        <Box>
          <Grid templateColumns="repeat(6 ,1fr)" gap={4}>
            <GridItem colSpan={6}>
              <Divider />
            </GridItem>

            <GridItem colSpan={6}>
              <Heading fontSize="xl">Add a comment</Heading>
            </GridItem>

            <GridItem colSpan={6}>
              <CommentForm
                commentFrom="event"
                onSave={() => {}}
                comment={currentComment}
                autoFocusComment={false}
                showCommentButtons={false}
                setCurrentComment={setCurrentComment}
              />
            </GridItem>

            <GridItem colSpan={6}>
              <Divider />
            </GridItem>
            <GridItem display="flex" justifyContent="space-between" colSpan={6}>
              <Button
                variant="outline"
                borderColor="blue.500"
                onClick={onClose}
              >
                Close
              </Button>
              <Button colorScheme="blue" onClick={handleOnSubmit}>
                Cancel and Close
              </Button>
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
