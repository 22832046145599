import {
  ApiClient,
  ApiEvent,
  ApiEventApprover,
  ApiEventGroup,
  ApiEventGroupSumary,
  ApiEventOccurrence,
  ApiEventStatus,
  ApiGroupStatus,
  ApiRentableEquipment,
  ApiService,
  ApiVenueSummary,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EventQueryStringFilter,
  filtersInitialState,
} from "../../pages/events/events-list/filters/Default";
import { filterEventHandlers } from "./thunks/filterEventList.thunk";

interface InitEventListParams {
  apiClient: ApiClient;
  accountId: string;
  filters?: EventQueryStringFilter;
}

export const initEventList = createAsyncThunk(
  "events-list/init",
  async (params: InitEventListParams, thunkAPI) => {
    const { apiClient, accountId, filters } = params;
    const events = await apiClient.findEvents(accountId, {
      ...filters,
    });

    return events;
  }
);

export type EventWithOccurrences = ApiEvent & {
  occurrences: ApiEventOccurrence[];
};

export interface EventListSlice {
  loading: "idle" | "pending" | "succeeded" | "failed";
  total: number;
  isLoading: boolean;
  events: ApiEvent[];
  venues: ApiVenueSummary[];
  eventGroups: ApiEventGroup[];
  services: ApiService[];
  equipments: ApiRentableEquipment[];
  filters: EventQueryStringFilter;
  expiredInsurance?: boolean;
}
const initialState: EventListSlice = {
  loading: "idle",
  total: 0,
  events: [],
  isLoading: false,
  venues: [],
  eventGroups: [],
  services: [],
  equipments: [],
  filters: filtersInitialState,
  expiredInsurance: false,
};

export const eventList = createSlice({
  name: "events-list",
  initialState: { ...initialState },
  reducers: {
    unloadEventList: (state: EventListSlice) => {
      state.loading = "idle";
      state.total = 0;
      state.events = [];
      state.isLoading = false;
    },
    updateSingleEventData: (
      state: EventListSlice,
      action: PayloadAction<{
        eventId: string;
        values: Partial<EventWithOccurrences>;
      }>
    ) => {
      const { eventId, values } = action.payload;
      const eventIndex = state.events.findIndex(
        (event) => event.id === eventId
      );

      if (eventIndex !== -1) {
        const eventCopy = { ...state.events[eventIndex] };
        state.events[eventIndex] = { ...eventCopy, ...values };
      }
    },
    updateEventStatus: (
      state: EventListSlice,
      action: PayloadAction<{ eventId: string; status: ApiEventStatus }>
    ) => {
      const { eventId, status } = action.payload;
      const index = state.events.findIndex((event) => event.id === eventId);
      if (index !== -1) {
        state.events[index].status = status;
      }
    },
    setApproverEventList: (
      state: EventListSlice,
      action: PayloadAction<{ eventId: string; approver: ApiEventApprover }>
    ) => {
      const { eventId, approver } = action.payload;
      const index = state.events.findIndex((event) => event.id === eventId);
      if (index !== -1) {
        const registeredIndex = state.events[index].eventApprovers.findIndex(
          (item) => item.id === approver.id
        );
        if (registeredIndex === -1) {
          state.events[index].eventApprovers.unshift(approver);
        } else {
          state.events[index].eventApprovers[registeredIndex] = approver;
        }
      }
    },
    updateEventList: (
      state: EventListSlice,
      action: PayloadAction<{
        eventId: string;
        eventGroup: ApiEventGroupSumary;
      }>
    ) => {
      const eventFinded = state.events.find(
        (event) => event.id === action.payload.eventId
      );
      if (eventFinded) eventFinded.eventGroup = action.payload.eventGroup;
    },
    updateEventListGroupStatus: (
      state: EventListSlice,
      action: PayloadAction<{
        eventGroupId: string;
        eventGroupStatus: ApiGroupStatus;
      }>
    ) => {
      state.events.forEach((event) => {
        if (event.eventGroup.id === action.payload.eventGroupId)
          event.eventGroup.status = action.payload.eventGroupStatus;
      });
    },
    setExpiredInsurance: (
      state: EventListSlice,
      action: PayloadAction<{
        expired: boolean;
      }>
    ) => {
      state.expiredInsurance = action.payload.expired;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(initEventList.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.events = action.payload.data;
      state.total = action.payload.total;
    });
    builder.addCase(initEventList.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(initEventList.rejected, (state) => {
      state.loading = "failed";
      state.events = [];
    });
    filterEventHandlers(builder);
  },
});

export const {
  setApproverEventList,
  unloadEventList,
  updateSingleEventData,
  updateEventList,
  updateEventStatus,
  updateEventListGroupStatus,
  setExpiredInsurance,
} = eventList.actions;

export default eventList.reducer;
