import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import {
  ApiAttachment,
  ApiBillingGroupType,
  ApiLocation,
  ApiSpace,
  ApiSpaceService,
  ApiVenue,
  CreateApiEventOccurrence,
} from "@operations-hero/lib-api-client";
import { differenceInMinutes } from "date-fns";
import { FastField, useFormikContext } from "formik";
import React, {
  Dispatch,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MdAdd } from "react-icons/md";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { VenueAutocompleteControl } from "../../../../components/form-helpers/VenueAutocompleteControl";
import { FormikObserver } from "../../../../hooks/formikObserver";
import { useBillingGroupRates } from "../../../../hooks/useBillingGroupRates";
import { openImageFromNewTab } from "../../../../utils/imagesHelper";
import { AccountModal } from "../../../account-settings/account-modal/AccountModal";
import { SpacesForm } from "../../spaces/SpaceForm";
import { EventFormValues } from "../EventForm";
import { EventServiceData } from "./ServicesMulticheck";
import { SpaceItem } from "./SpaceItem";
import { VenuesUsesFilter } from "./VenuesUsesFilter";

interface VenueSpaceProps {
  firstOccurrence?: CreateApiEventOccurrence;
  setSpacesCost: Dispatch<React.SetStateAction<number>>;
  occurrences: CreateApiEventOccurrence[];
}

export const VenueSpacesSection: FC<VenueSpaceProps> = ({
  firstOccurrence,
  setSpacesCost,
  occurrences,
}) => {
  const { values, submitCount, setFieldValue } =
    useFormikContext<EventFormValues>();

  const [venueDetail, setVenueDetail] = useState<ApiVenue>();
  const { apiClient, currentAccount } = useAuthentication();
  const errorColor = useColorModeValue("red.500", "red.300");
  const isDesktop = useBreakpointValue({
    base: false,
    sm: true,
    md: true,
    lg: true,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [venueAttachments, setVenueAttachments] = useState<ApiAttachment[]>([]);
  const [prevVenueId, setPrevVenueId] = useState<string | null>(null);
  const [venuesUsesOptions, setVenuesUsesOptions] = useState<string[]>([]);
  const [selectedUsesOptions, setSelectedUsesOptions] = useState<string[]>([]);

  const rateGroupId = useMemo(() => {
    if (!values) return;
    if (!values.eventGroup || !values.eventGroup.rateGroup) return;
    return values.eventGroup.rateGroup.id;
  }, [values]);

  const { getItemRates, getAllRatesByItemId } = useBillingGroupRates({
    rateGroupId,
    type: ApiBillingGroupType.space,
  });

  const maxAttachmentsToShow = useBreakpointValue({ sm: 1, md: 3, lg: 5 });

  const handleFormikValuesChanges = useCallback(
    async (values: EventFormValues) => {
      if (values.venue && values.venue.id !== prevVenueId) {
        const attachments = await apiClient.findVenueAttachments(
          currentAccount.id,
          values.venue.id
        );
        setVenueAttachments(
          attachments.data.filter((item) => item.contentType.includes("image"))
        );
        setPrevVenueId(values.venue.id);
      }
    },
    [apiClient, currentAccount.id, prevVenueId]
  );

  const eventHoursDuration = useMemo(() => {
    if (!firstOccurrence) return 0;
    const startDate = new Date(firstOccurrence.start);
    const endDate = new Date(firstOccurrence.end);
    const occurrenceMinutes = differenceInMinutes(endDate, startDate);
    const hours = parseFloat((occurrenceMinutes / 60).toFixed(2));
    return hours;
  }, [firstOccurrence]);

  const setSpacesTotalCost = useCallback(() => {
    const total = values.spaces.reduce((acc, current) => {
      const spaceId = typeof current === "string" ? current : current.id;
      const rates = getItemRates(
        spaceId,
        true,
        occurrences,
        eventHoursDuration
      );
      let spacesCost = 0;
      if (rates) {
        rates?.forEach((r) => {
          if (r) spacesCost += r.total;
        });
      }
      return acc + spacesCost;
    }, 0);

    setSpacesCost(total);
  }, [
    eventHoursDuration,
    values.spaces,
    setSpacesCost,
    occurrences,
    getItemRates,
  ]);

  const getVenueRequiredServices = useCallback(
    (venue: ApiVenue) => {
      if (!venueDetail && !venue) return;
      const workingVenue = venue || venueDetail;
      const venueRequiredServices = workingVenue.services
        .filter((vs) => vs.isRequired)
        .reduce(
          (hash, serv) => {
            hash[serv.service.id] = {
              assignees: [],
              isRequired: true,
              service: serv.service,
              additionalNotes: "",
            };
            return hash;
          },
          {} as Record<string, EventServiceData>
        );
      return venueRequiredServices;
    },
    [venueDetail]
  );

  const getServicesWhenSpacesChange = useCallback(
    async (spacesIds: string[]) => {
      const { services } = values;

      if (!venueDetail) return services;
      const allServicesHash: Record<string, EventServiceData> =
        venueDetail.services.reduce(
          (hash, venueService) => {
            hash[venueService.service.id] = {
              assignees: [],
              service: venueService.service,
              isRequired: venueService.isRequired,
              additionalNotes: "",
            };
            return hash;
          },
          {} as Record<string, EventServiceData>
        );

      const spacesDetails = (
        await apiClient.findSpaces(currentAccount.id, { ids: spacesIds })
      ).data;

      const spacesServices: ApiSpaceService[] = [];
      const spacesDetailsHash = spacesDetails.reduce(
        (hash, space) => {
          spacesServices.push(...space.services);
          hash[space.id] = space;
          return hash;
        },
        {} as Record<string, ApiSpace>
      );

      const requiredSpacesServices = spacesServices.filter((spaceService) => {
        const key = `${spaceService.service.id}:${spaceService.spaceId}`;
        allServicesHash[key] = spaceService;
        return spaceService.isRequired;
      });

      // This is to avoid removing selected services from other spaces or venues
      const newServices = { ...values.services };
      for (const [key] of Object.entries(newServices)) {
        const isAdded = !!allServicesHash[key];
        if (!isAdded) {
          delete newServices[key];
        }
      }

      requiredSpacesServices.forEach((spaceService) => {
        const key = `${spaceService.service.id}:${spaceService.spaceId}`;
        newServices[key] = {
          assignees: [],
          isRequired: spaceService.isRequired,
          service: spaceService.service,
          additionalNotes: "",
          spaceId: spaceService.spaceId,
          spaceName: spacesDetailsHash[spaceService.spaceId].location.name,
        };
      });

      return newServices;
    },
    [apiClient, currentAccount.id, values, venueDetail]
  );

  const handleOnAddSpaces = useCallback(
    async (spaces: ApiSpace[]) => {
      if (!venueDetail) return;
      setFieldValue("spaces", spaces);
      const ids = spaces.map((s) => s.id);
      const newServices = await getServicesWhenSpacesChange(ids);
      setFieldValue("services", newServices);
    },
    [getServicesWhenSpacesChange, setFieldValue, venueDetail]
  );

  const removeSelectedSpaceServices = useCallback(
    (space: ApiSpace) => {
      space.services.forEach((service) => {
        const key = `${service.service.id}:${space.id}`;
        if (!!values.services[key]) {
          const servicesValuesCopy = { ...values.services };
          delete servicesValuesCopy[key];
          setFieldValue("services", servicesValuesCopy);
        }
      });
    },
    [setFieldValue, values.services]
  );

  const handleRemoveSpace = useCallback(
    (spaceId: String) => {
      const index = values.spaces.findIndex((space) => space.id === spaceId);
      if (index === -1) return;
      const spaceCopy = { ...values.spaces[index] };
      const spacesCopy = [...values.spaces];
      spacesCopy.splice(index, 1);
      setFieldValue("spaces", spacesCopy);
      removeSelectedSpaceServices(spaceCopy);
    },
    [removeSelectedSpaceServices, setFieldValue, values.spaces]
  );

  useEffect(() => {
    setSpacesTotalCost();
  }, [setSpacesTotalCost]);

  useEffect(() => {
    if (!values.venue) {
      setFieldValue("services", {});
      return;
    }
    if (venueDetail && values.venue.id === venueDetail.id) {
      return;
    }

    apiClient
      .getVenue(currentAccount.id, values.venue.id, {
        includeQuestions: true,
        excludeInactiveQuestions: true,
      })
      .then((venue) => {
        setVenueDetail(venue);
        setFieldValue("venue", venue);
        const requieredVenueServices = getVenueRequiredServices(venue);
        setFieldValue("services", requieredVenueServices);
      });
  }, [
    values.venue,
    apiClient,
    currentAccount.id,
    venueDetail,
    setFieldValue,
    getVenueRequiredServices,
  ]);

  const memoizedSpaces = useMemo(() => {
    return venueDetail?.spaces && venueDetail.spaces.length > 0 ? (
      <>
        <Flex justifyContent="space-between">
          <Heading fontSize="lg">{`Spaces (${values.spaces.length})`}</Heading>
          <Button
            size="sm"
            onClick={onOpen}
            colorScheme="blue"
            disabled={!values.venue}
          >
            <Icon as={MdAdd} /> Add Space
          </Button>
        </Flex>

        <Flex gap={4} flexDir="column" p={2}>
          {values.spaces.map((space) => (
            <SpaceItem
              space={space}
              occurrences={occurrences}
              getItemRates={getItemRates}
              key={`eventForm::${space.id}`}
              handleRemoveSpace={handleRemoveSpace}
              eventHoursDuration={eventHoursDuration}
              getAllRatesByItemId={getAllRatesByItemId}
            />
          ))}
        </Flex>

        {submitCount > 0 && values.spaces.length === 0 && (
          <>
            <Text mt={2} color={errorColor} fontSize="sm">
              You must select at least 1 space
            </Text>
          </>
        )}
      </>
    ) : null;
  }, [
    venueDetail,
    values.spaces,
    values.venue,
    onOpen,
    submitCount,
    errorColor,
    occurrences,
    getItemRates,
    handleRemoveSpace,
    eventHoursDuration,
    getAllRatesByItemId,
  ]);

  const getVenuesUses = useCallback(() => {
    apiClient.findVenuesUses(currentAccount.id).then((response) => {
      setVenuesUsesOptions(response);
    });
  }, [apiClient, currentAccount.id]);

  useEffect(() => {
    getVenuesUses();
  }, [getVenuesUses]);

  return (
    <>
      <FormikObserver cb={handleFormikValuesChanges} />
      <Stack
        direction={["column", "row"]}
        width="100%"
        justifyContent="space-between"
      >
        <Heading fontSize="lg">Choose a venue</Heading>
        <Box minWidth="255px">
          <VenuesUsesFilter
            values={selectedUsesOptions}
            onChange={setSelectedUsesOptions}
            options={venuesUsesOptions}
          />
        </Box>
      </Stack>
      <Box maxW={["100%", null, null, "75%"]}>
        <FastField key={`venues::${selectedUsesOptions.length}`} name="venue">
          {() => (
            <VenueAutocompleteControl
              name="venue"
              value={values.venue}
              label="Select a main venue"
              cleanValuesNames={["spaces", "services", "rentableEquipment"]}
              uses={selectedUsesOptions}
            />
          )}
        </FastField>
      </Box>

      {memoizedSpaces}

      {venueAttachments.length > 0 && (
        <>
          <Text py={2} fontStyle="italic">
            Venue images:
          </Text>
          <Wrap>
            {venueAttachments
              .slice(0, maxAttachmentsToShow)
              .map((attachment) => {
                return (
                  <WrapItem key={attachment.id} w={["104px", "104px", "120px"]}>
                    <Image
                      fit="cover"
                      cursor="pointer"
                      borderRadius={6}
                      id={attachment.id}
                      src={attachment.url}
                      fallback={<Skeleton w="120px" height="120px" />}
                      boxSize={["104px", "104px", "120px"]}
                      onClick={() => openImageFromNewTab(attachment.url)}
                    />
                  </WrapItem>
                );
              })}
          </Wrap>
        </>
      )}
      {isOpen && values.venue && (
        <AccountModal
          isOpen={isOpen}
          onClose={onClose}
          contentProps={{ minW: isDesktop ? "xl" : undefined }}
          title={`Spaces (${venueDetail?.spaces?.length})`}
          content={
            <SpacesForm
              viewMode="list"
              includeCarousel
              onClose={onClose}
              values={values.spaces}
              cb={handleOnAddSpaces}
              parent={values.venue.location as ApiLocation}
              venue={values.venue}
              action="get"
            />
          }
        />
      )}
    </>
  );
};
