import { ApiComment, ApiUserSummary } from "@operations-hero/lib-api-client";

const markDownFormatExp = /[**_`]/g;

export const transformCommentToPrint = (comments: ApiComment[]) => {
  let response: ApiComment[] = [];
  comments.forEach((comment) => {
    let newComment = { ...comment };
    const message = transformComment(comment.comment, comment.mentioned);
    newComment.comment = message;
    response.push(newComment);
  });
  return response;
};

const transformComment = (comment: string, mentioned: ApiUserSummary[]) => {
  let resComment = comment.slice();
  mentioned.forEach((mention) => {
    resComment = resComment.replaceAll(
      `<u:${mention.id}>`,
      `@${mention.firstName} ${mention.lastName}`
    );
  });
  resComment = resComment.replaceAll(markDownFormatExp, "");
  return resComment;
};
