export const TRANSACTION_DATE_FORMAT = "MM-dd-yy";

export const hoursFormatRegex = new RegExp(
  /^((([0-9]{0,3})d\s?)?(([01]?[0-9]|2[0-3])h\s?)?([0-5]?[0-9]m)?$|^([0-5]?[0-9]m)$)/
);

export const getFormatedHour = (value: number) => {
  const valueInMinutes = Math.round(value * 60);
  const days = Math.floor(valueInMinutes / (24 * 60));
  const rest = valueInMinutes - days * 24 * 60;
  const hours = Math.floor(rest / 60);
  const minutes = rest % 60;

  if (days && hours && minutes) {
    return `${days}d ${hours}h ${minutes}m`;
  } else if (hours && minutes) {
    return `${hours}h ${minutes}m`;
  } else if (days && !hours && !minutes) {
    return `${days}d`;
  } else if (days && !hours && minutes) {
    return `${days}d ${minutes}m`;
  } else if (days && hours && !minutes) {
    return `${days}d ${hours}h`;
  }
  return hours ? `${hours}h` : `${minutes}m`;
};
