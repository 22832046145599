import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Stack,
  StackItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiAsset,
  ApiAssignee,
  ApiLocation,
  ApiLocationReference,
  ApiLocationSummary,
  ApiProject,
  ApiReportingCategory,
  ApiRequest,
  ApiRequestPriority,
  ApiRequestStatus,
  ApiRequestType,
  ApiWorkflow,
  ApiWorkflowFieldDataType,
  ApiWorkflowReasonSummary,
  ApiWorkflowReportingCategorySummary,
  ApiWorkflowSchemaField,
  CreateApiRequest,
  WorkflowPolicy,
} from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import axios, { AxiosProgressEvent } from "axios";
import { EditorState } from "draft-js";
import { Form, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { MdQrCode2 } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { FormikObserver } from "../../hooks/formikObserver";
import { useShowToast } from "../../hooks/showToast";
import { useIsInModal } from "../../hooks/useIsInModal";
import { AssetCard } from "../../pages/account-settings/asset-list/AssetCard";
import { Products } from "../../pages/account-settings/location-list/LocationList";
import { AssetSearchAutocomplete } from "../../pages/request-form/assets/AssetSearchAutocomplete";
import { convertToSave } from "../../pages/request-form/comments/Comment";
import { RootState } from "../../store";
import {
  setFormikModal,
  setIsSubmitting,
} from "../../store/formik-modal.slice";
import {
  addAsset,
  addAttachment,
  removeAsset,
  removeAttachment,
  unloadAssets,
  unloadForm,
  updateAttachment,
} from "../../store/new-request-form.slice";
import { useCategoryUtils } from "../../utils/categoryUtils";
import { SETTING_USER_ALLOW_LAST_LOCATION_NEW_REQUESTS } from "../../utils/emailSettingUtils";
import { getVisibleFields } from "../../utils/getVisibleFields";
import { useLocationUtils } from "../../utils/locationUtils";
import { Attachment, Attachments } from "../attachments/Attachments";
import { useAuthentication } from "../auth/AuthProvider";
import { AssigneeAutocompleteControl } from "../form-helpers/AssigneeAutocompleteControl";
import { CustomFieldInputControl } from "../form-helpers/CustomFieldInputControl";
import { DatePickerControl } from "../form-helpers/DatePickerControl";
import FocusError from "../form-helpers/FocusError";
import { LocationAutocompleteControl } from "../form-helpers/LocationAutocompleteControl";
import { ProjectAutocompleteControl } from "../form-helpers/ProjectAutocompleteControl";
import { ReasonAutocompleteControl } from "../form-helpers/ReasonAutocompleteControl";
import { ReportingCategoryAutocompleteControl } from "../form-helpers/ReportingCategoryAutocompleteControl";
import { RichTextEditorComments } from "../form-helpers/rich-text-editor/RichTextEditorComments";
import { UserAutocompleteControl } from "../form-helpers/UserAutocompleteControl";
import { QrQuickScanModal } from "../qr-quick-scan/QrQuickScanModal";
import { LocationAutocomplete } from "../selects/LocationAutocomplete";
import { ReportingCategoryAutocomplete } from "../selects/ReportingCategoryAutocomplete";
import { WorkflowAutocomplete } from "../selects/WorkflowAutocomplete";
import { useProjectField } from "./useProjectField";

export interface NewRequestFormContext {
  workflow: ApiWorkflow;
  schemaFields: ApiWorkflowSchemaField[];
  rulesEngine: SchemaRulesEngine;
  policy: WorkflowPolicy;
  project?: ApiProject;
}

export interface NewRequestFormProps {
  context?: NewRequestFormContext;
  onSave: (request: ApiRequest) => void;
  onCancel: () => void;
  handleWorkflowChange: (workflow: ApiWorkflow | null) => void;
  hideWorkflow?: boolean;
}

export const NewRequestForm = ({
  context,
  onCancel,
  onSave,
  handleWorkflowChange,
  hideWorkflow,
}: NewRequestFormProps) => {
  return (
    <>
      {!hideWorkflow && (
        <Box
          width="100%"
          position={!context ? "absolute" : "inherit"}
          pb={8}
          paddingRight={!context ? 12 : 0}
          zIndex="100"
        >
          <FormControl>
            <FormLabel>Workflow</FormLabel>
            <WorkflowAutocomplete
              workflow={context ? context.workflow : null}
              onChange={handleWorkflowChange}
              allowEmpty={true}
            />
          </FormControl>
        </Box>
      )}

      <Collapse in={Boolean(context)}>
        {context && (
          <NewRequestFormBody
            context={context}
            onCancel={onCancel}
            onSave={onSave}
          />
        )}
      </Collapse>
    </>
  );
};

export interface NewRequestFormBodyProps {
  context: NewRequestFormContext;
  onSave: (request: ApiRequest) => void;
  onCancel: () => void;
}

const NewRequestFormBody = ({
  context,
  onCancel,
  onSave,
}: NewRequestFormBodyProps) => {
  const { currentUser, currentAccount, apiClient, isProductAdmin } =
    useAuthentication();
  const dispatch = useDispatch();
  const showToast = useShowToast();
  const isInModal = useIsInModal();
  const ref = useRef(null);

  const { locationMap, userSettings, categoriesMap, isContractorOnly } =
    useSelector((state: RootState) => state.localCache);
  const attachments = useSelector(
    (state: RootState) => state.newRequestForm.attachments
  );
  const assets = useSelector((state: RootState) => state.newRequestForm.assets);
  const {
    reportingCategoryId: categoryIdFromState,
    locationId: locationIdFromState,
  } = useSelector((state: RootState) => state.newRequestForm);

  const [isUploading, setIsUploading] = useState(false);
  const [summary, setSummary] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [summaryToSave, setSummaryToSave] = useState<string | undefined>();
  const [isSummaryInvalid, setInvalidSummary] = useState(false);
  const [assetToAdd, setAssetToAdd] = useState<ApiAsset | null>(null);
  const { getChildrenId, getUserPolicyLocationsWithChildrens } =
    useLocationUtils();
  const { findAllChildrenForNodesRecursive } = useCategoryUtils();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const qrColor = useColorModeValue("black", "white");
  const [showAssetSection, setShowAssetSection] = useState(false);
  const [categoriesIds, setCategoriesIds] = useState<string[]>();

  const {
    isDisabled: isProjectDisabled,
    value: initProject,
    isPlanningAdmin,
    hasPlanning,
  } = useProjectField();

  const displayValue = useBreakpointValue({
    base: "inline-block",
    sm: "inline-flex",
  });

  const allowedLocations = useMemo(() => {
    const { admin } = context.policy;

    if (
      isProductAdmin ||
      admin ||
      context.workflow.allowUsersToSubmitRequests
    ) {
      return [];
    }

    const restrictedLocations = getUserPolicyLocationsWithChildrens(
      context.policy
    );

    return restrictedLocations.map((loc) => loc.id);
  }, [context, isProductAdmin, getUserPolicyLocationsWithChildrens]);

  const defaultLocation = useMemo(() => {
    const lastLocationId = userSettings[
      SETTING_USER_ALLOW_LAST_LOCATION_NEW_REQUESTS
    ]
      ? localStorage.getItem(`${currentAccount.id}-request-location`)
      : null;

    // Pick a default location for the request in the following order.
    // 1. Location from the state
    // 2. Last location used from local storage
    // 3. Only location allowed
    // 4. Only location available
    let defaultLocationCandidates: (string | null)[] = [
      locationIdFromState,
      lastLocationId,
      allowedLocations.length === 1 ? allowedLocations[0] : null,
      Object.keys(locationMap).length === 1
        ? Object.keys(locationMap)[0]
        : null,
    ];

    let result: ApiLocation | null = null;
    for (let id of defaultLocationCandidates) {
      if (!id) continue;

      const location = locationMap[id];
      if (!location || !location.active) continue;

      if (allowedLocations.length > 0 && !allowedLocations.includes(id)) {
        continue;
      }

      result = location;
      break;
    }

    return result;
  }, [
    currentAccount,
    locationMap,
    locationIdFromState,
    allowedLocations,
    userSettings,
  ]);

  const [assetLocationFilter, setAssetLocationFilter] =
    useState<ApiLocationSummary | null>(defaultLocation || null);

  const assetSearchLocationIds = useMemo(() => {
    if (!assetLocationFilter) {
      return undefined;
    }

    const childrens = getChildrenId([assetLocationFilter]);
    return childrens.length > 0 ? childrens : undefined;
  }, [assetLocationFilter, getChildrenId]);

  const [category, setCategory] =
    useState<ApiWorkflowReportingCategorySummary>();

  const initialValues = useMemo<
    CreateApiRequest & { project: ApiProject | null }
  >(() => {
    const initialCategory = categoryIdFromState
      ? categoriesMap[categoryIdFromState]
      : null;

    return {
      assignees: [],
      scheduling: {
        start: null,
        due: null,
        completed: null,
      },
      location: defaultLocation || null,
      metadata: {},
      priority: ApiRequestPriority.standard,
      reportingCategory: initialCategory,
      reason: null,
      requester: currentUser,
      status: ApiRequestStatus.new,
      summary: "",
      type: ApiRequestType.corrective,
      workflow: context.workflow,
      estimatedCost: null,
      estimatedHours: null,
      scheduledRequestId: null,
      project: initProject,
    };
  }, [
    categoryIdFromState,
    categoriesMap,
    currentUser,
    context.workflow,
    defaultLocation,
    initProject,
  ]);

  const visibleFields = useMemo(() => {
    return context.rulesEngine.getVisibleFields({
      //@ts-ignore
      request: ref.current ? ref.current.values : initialValues,
      includeDeleted: false,
    });
  }, [context, initialValues]);

  const customVisibleFields = useMemo(() => {
    const fields = visibleFields.filter(
      (field) => field.dataType !== ApiWorkflowFieldDataType.system
    );

    const res = context.schemaFields.filter((sf) =>
      fields.some((fi) => fi.id === sf.field.id)
    );

    return res.sort((a, b) => {
      if (!a.position) return 1;
      if (!b.position) return -1;
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
      return 0;
    });
  }, [visibleFields, context.schemaFields]);

  const {
    showCategory,
    showStartDate,
    showDueDate,
    showAssets,
    showReason,
    showAssignTo,
  } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const validationSchema = useMemo(() => {
    const shape: any = {
      type: yup.string().required().oneOf(Object.values(ApiRequestType)),
      requester: yup.object().required("Requester is required"),
      location: yup.object().nullable().required("Location is required"),
    };

    if (showCategory) {
      shape.category = yup.object().optional().nullable();
    }

    if (showStartDate || showDueDate) {
      shape.scheduling = yup.object().shape({
        start: yup.date().optional().nullable(),
        due: yup.date().optional().nullable(),
      });
    }

    const metadataShape = context.rulesEngine
      .getRequiredFields({
        // @ts-ignore
        request: ref.current ? ref.current.values : initialValues,
      })
      .reduce<Record<string, any>>((result, field) => {
        let key = "";
        switch (field.key) {
          case "SYSTEM-CATEGORY":
            key = "reportingCategory";
            break;
          case "SYSTEM-START-DATE":
            key = "scheduling.start";
            break;
          case "SYSTEM-DUE-DATE":
            key = "scheduling.due";
            break;
          case "SYSTEM-REASON":
            key = "reason";
            break;
          case "SYSTEM-ASSIGN-TO":
            key = "assignees";
            break;
          default:
            key = field.key;
            break;
        }

        let name = "";
        switch (field.key) {
          case "SYSTEM-CATEGORY":
            name = "Category";
            break;
          case "SYSTEM-START-DATE":
            name = "Start date";
            break;
          case "SYSTEM-DUE-DATE":
            name = "Due date";
            break;
          case "SYSTEM-REASON":
            name = "Reason";
            break;
          case "SYSTEM-ASSIGN-TO":
            name = "Assign to";
            break;
          default:
            name = field.name;
            break;
        }
        result[key] = (
          field.dataType === ApiWorkflowFieldDataType.checkbox
            ? yup.boolean()
            : field.dataType === ApiWorkflowFieldDataType.date
              ? yup.date()
              : field.dataType === ApiWorkflowFieldDataType.location
                ? yup.object()
                : field.dataType === ApiWorkflowFieldDataType.number
                  ? yup.number()
                  : field.dataType === ApiWorkflowFieldDataType.selection
                    ? yup.object()
                    : field.dataType === ApiWorkflowFieldDataType.user
                      ? yup.object()
                      : field.dataType === ApiWorkflowFieldDataType.system &&
                          (field.key === "SYSTEM-CATEGORY" ||
                            field.key === "SYSTEM-REASON")
                        ? yup.object()
                        : field.dataType === ApiWorkflowFieldDataType.system &&
                            field.key === "SYSTEM-ASSIGN-TO"
                          ? yup.array().min(1, " Assign To is required")
                          : yup.string()
        )
          .nullable()
          .required(`${name} is required`);

        return result;
      }, {});

    const newMetadataShape: Record<string, any> = {};
    Object.keys(metadataShape).forEach((key) => {
      if (
        key.substring(0, 6) !== "SYSTEM" &&
        key !== "reportingCategory" &&
        key !== "scheduling.start" &&
        key !== "scheduling.due" &&
        key !== "reason" &&
        key !== "assignees"
      ) {
        newMetadataShape[key] = metadataShape[key];
      }
    });

    if (Object.keys(newMetadataShape).length > 0) {
      shape.metadata = yup.object().shape(newMetadataShape);
    }

    let newShape = { ...shape };

    if (metadataShape["reportingCategory"]) {
      newShape = {
        ...newShape,
        reportingCategory: metadataShape["reportingCategory"],
      };
    }

    if (metadataShape["scheduling.start"]) {
      newShape = {
        ...newShape,
        scheduling: yup.object().shape({
          start: metadataShape["scheduling.start"],
          due: newShape.scheduling.fields.due,
        }),
      };
    }

    if (metadataShape["scheduling.due"]) {
      newShape = {
        ...newShape,
        scheduling: yup.object().shape({
          start: newShape.scheduling.fields.start,
          due: metadataShape["scheduling.due"],
        }),
      };
    }

    if (metadataShape["reason"]) {
      newShape = {
        ...newShape,
        reason: metadataShape["reason"],
      };
    }

    if (metadataShape["assignees"]) {
      newShape = {
        ...newShape,
        assignees: metadataShape["assignees"],
      };
    }

    return yup.object().shape(newShape);

    // eslint-disable-next-line
  }, [context, initialValues, showCategory, showDueDate, showStartDate]);

  const handleFormikSubmit = useCallback(
    (
      values: CreateApiRequest & { project: ApiProject | null },
      formikHelpers: FormikHelpers<
        CreateApiRequest & { project: ApiProject | null }
      >
    ) => {
      const isEmpty = !summary.getCurrentContent().hasText();
      isEmpty && !isSummaryInvalid && setInvalidSummary(isEmpty);

      if (isEmpty) {
        return;
      }

      // the initialValues does not set workflow property,
      // this ensures the selected workflow is used.
      isInModal && dispatch(setIsSubmitting(true));

      const newValues = { ...values };
      const { project, ...restNewValues } = newValues;
      const payload: CreateApiRequest = {
        ...restNewValues,
        summary: summaryToSave ? summaryToSave : "",
        workflow: context.workflow,
        projectId: newValues.project?.id,
      };

      return apiClient
        .createRequest(currentAccount.id, payload)
        .then((newRequest) => {
          return attachments.length === 0
            ? newRequest
            : Promise.all(
                attachments.map((a) =>
                  apiClient.createRequestAttachment(
                    currentAccount.id,
                    newRequest.key,
                    {
                      uploadId: a.uploadId!,
                      name: a.name,
                    }
                  )
                )
              ).then(() => newRequest);
        })
        .then((newRequest) => {
          return assets.length === 0
            ? newRequest
            : Promise.all(
                assets.map((a) =>
                  apiClient.createRequestAsset(
                    currentAccount.id,
                    newRequest.key,
                    {
                      assetId: a.id,
                    }
                  )
                )
              ).then(() => newRequest);
        })
        .then((newRequest) => {
          if (newRequest.location) {
            localStorage.setItem(
              `${currentAccount.id}-request-location`,
              newRequest.location.id
            );
          }
          onSave(newRequest);
        })
        .catch(() => {
          showToast("error", "Something went wrong while creating new request");
        })
        .finally(() => {
          isInModal && dispatch(setIsSubmitting(false));
        });
    },
    [
      summary,
      isSummaryInvalid,
      summaryToSave,
      context.workflow,
      apiClient,
      currentAccount.id,
      attachments,
      assets,
      onSave,
      showToast,
      isInModal,
      dispatch,
    ]
  );

  const handleNewFiles = useCallback(
    (files: Attachment[]) => {
      // start upload process
      files.forEach((file) => {
        if (!file.file) {
          return;
        }

        // Get a signed url
        // -- store uploadId for later use on the attachment
        // upload to signed url
        // -- set uploading false
        apiClient
          .createUpload(currentAccount.id)
          .then((uploadResult) => {
            const newAttachment: Attachment = {
              ...file,
              isUploading: true,
              uploadId: uploadResult.id,
              progress: 0,
            };
            dispatch(addAttachment(newAttachment));
            return { attachment: newAttachment, upload: uploadResult };
          })
          .then(({ attachment, upload }) => {
            return axios
              .put(upload.url, attachment.file, {
                headers: { "Content-Type": attachment.file!.type },
                onUploadProgress: (progressEvent: AxiosProgressEvent) =>
                  dispatch(
                    updateAttachment({
                      ...attachment,
                      progress: Math.round(
                        (progressEvent.loaded * 100) /
                          (progressEvent.total || 1)
                      ),
                    })
                  ),
              })
              .then(() => ({ attachment, upload }));
          })
          .then(({ attachment, upload }) => {
            const updated: Attachment = {
              ...attachment,
              isUploading: false,
              progress: undefined,
            };
            dispatch(updateAttachment(updated));
          });
      });
    },
    [apiClient, currentAccount, dispatch]
  );

  const handleRemoveFile = useCallback(
    (attachment: Attachment) => {
      dispatch(removeAttachment(attachment));
    },
    [dispatch]
  );

  const handleRemoveAssetFactory = useCallback(
    (a: ApiAsset) => () => {
      dispatch(removeAsset(a));
    },
    [dispatch]
  );

  const handleSummaryBlur = useCallback((summary: EditorState) => {
    setSummary(summary);
    const isEmpty = !summary.getCurrentContent().hasText();
    setInvalidSummary(isEmpty);
    const response = convertToSave(summary);
    setSummaryToSave(response);
  }, []);

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const assetToAddOnChange = useCallback(
    (asset: ApiAsset | null) => {
      if (!asset) {
        setAssetToAdd(null);
        return;
      }
      setAssetToAdd(asset);

      if (assets.find((d) => d.id === asset.id)) {
        showToast("error", "Asset already added");
        setAssetToAdd(null);
        return;
      }
      setAssetToAdd(null);
      dispatch(addAsset(asset));
    },
    [showToast, assets, dispatch]
  );

  const handleOnChangeAssetLocationFilter = useCallback(
    (newLocation: ApiLocationSummary | null) => {
      setAssetLocationFilter(newLocation);
    },
    []
  );

  const handleOnChangeCategory = useCallback(
    (value: ApiWorkflowReportingCategorySummary | null) => {
      if (!value) {
        setCategory(undefined);
        setCategoriesIds(undefined);
        return;
      }
      setCategory(value);
      const categoryChilds = value
        ? findAllChildrenForNodesRecursive([value.id])
        : new Set<ApiReportingCategory>();
      const categories = Array.from(categoryChilds);
      setCategoriesIds(categories ? categories.map((cat) => cat.id) : []);
    },
    [findAllChildrenForNodesRecursive]
  );

  const handleQrData = useCallback(
    (externalIds: string) => {
      apiClient
        .findAssets(currentAccount.id, { externalIds: [externalIds] })
        .then((result) => {
          assetToAddOnChange &&
            result.data.forEach((rData) => {
              assetToAddOnChange(rData);
            });
        });

      onClose();
    },
    [onClose, apiClient, currentAccount.id, assetToAddOnChange]
  );

  useEffect(() => {
    setIsUploading(attachments.some((a) => a.isUploading === true));
  }, [attachments]);

  useEffect(
    () => () => {
      dispatch(unloadForm());
      dispatch(unloadAssets());
    },
    [dispatch]
  );

  const handleOnChangeForm = useCallback(
    async (
      values: CreateApiRequest,
      setFieldValue: any,
      isSubmitting: boolean,
      handleSubmit: any
    ) => {
      if (!isInModal) {
        return;
      }
      dispatch(
        setFormikModal({ values, isSubmitting, handleSubmit, isUploading })
      );
    },
    [dispatch, isInModal, isUploading]
  );

  return (
    <Box px={1}>
      <Formik
        onSubmit={handleFormikSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={ref}
      >
        {(props) => (
          <Form>
            <FormikObserver cb={handleOnChangeForm} />
            <Grid
              templateColumns={[
                "repeat(2, 1fr)",
                null,
                "repeat(6, 1fr)",
                "repeat(6, 1fr)",
              ]}
              verticalAlign="center"
              mb={2}
              gap={6}
            >
              {hasPlanning && isPlanningAdmin && (
                <GridItem colSpan={[2, 2, 6, 6]}>
                  <ProjectAutocompleteControl
                    name="project"
                    value={props.values.project || null}
                    isDisabled={isProjectDisabled}
                    label="Project"
                  />
                </GridItem>
              )}

              <GridItem colSpan={[2, null, showCategory ? 3 : 6]}>
                <UserAutocompleteControl
                  label="Requester"
                  name="requester"
                  // @ts-ignore
                  value={props.values.requester}
                />
              </GridItem>

              {showCategory && (
                <GridItem colSpan={[2, null, 3]}>
                  <ReportingCategoryAutocompleteControl
                    workflow={context.workflow}
                    label="Category"
                    name="reportingCategory"
                    // @ts-ignore
                    value={props.values.reportingCategory}
                  />
                </GridItem>
              )}

              {showAssignTo && (
                <GridItem colSpan={[2, null, 3]}>
                  <AssigneeAutocompleteControl
                    workflow={context.workflow}
                    value={props.values.assignees as ApiAssignee[]}
                    name="assignees"
                    label="Assign To"
                  />
                </GridItem>
              )}

              {showReason && !isContractorOnly && (
                <GridItem colSpan={[2, null, 3]}>
                  <ReasonAutocompleteControl
                    workflow={context.workflow}
                    value={props.values.reason as ApiWorkflowReasonSummary}
                    name="reason"
                    label="Reason"
                  />
                </GridItem>
              )}

              <GridItem colSpan={[2, null, 6]}>
                <LocationAutocompleteControl
                  allowedLocations={allowedLocations}
                  label="Location"
                  name="location"
                  // @ts-ignore
                  value={props.values.location}
                  productName={Products.HeroHQ}
                />
              </GridItem>
              <GridItem colSpan={[2, null, 6]}>
                <RichTextEditorComments
                  id="comment-edit"
                  value={summary}
                  label={"Description/Summary"}
                  onBlur={handleSummaryBlur}
                  isInvalid={isSummaryInvalid}
                  invalidMessage={
                    isSummaryInvalid ? "Summary is required" : undefined
                  }
                />
              </GridItem>
              {/* Custom Fields */}
              {customVisibleFields && customVisibleFields.length ? (
                <GridItem colSpan={[2, null, 6]}>
                  <Grid templateColumns={["repeat(2, 1fr)"]}>
                    {customVisibleFields.map((schemaField) => {
                      return (
                        <GridItem
                          colSpan={[2, null, 3]}
                          pb={[3, null, 3]}
                          key={schemaField.field.id}
                          display="flex"
                          alignItems="flex-end"
                        >
                          <CustomFieldInputControl
                            value={
                              props.values.metadata[schemaField.field.key] ||
                              null
                            }
                            name={`metadata.${schemaField.field.key}`}
                            field={schemaField.field}
                          />
                          {/* <CustomFieldInput
                      field={field}
                      value={metadata[field.key]}
                      onChange={handleCustomFieldChange(field)}
                    /> */}
                        </GridItem>
                      );
                    })}
                  </Grid>
                </GridItem>
              ) : null}

              {(showStartDate || showDueDate) && (
                <>
                  {showStartDate && (
                    <GridItem colSpan={[2, null, 3, 3]}>
                      <DatePickerControl
                        label="Start"
                        value={props.values.scheduling.start}
                        name="scheduling.start"
                        showTime={true}
                      />
                    </GridItem>
                  )}
                  {showDueDate && (
                    <GridItem colSpan={[2, null, 3, 3]}>
                      <DatePickerControl
                        label="Due"
                        value={props.values.scheduling.start}
                        name="scheduling.due"
                        showTime={true}
                      />
                    </GridItem>
                  )}
                </>
              )}

              <GridItem colSpan={[2, null, 6]} pb={[3, null, 3]}>
                <Attachments
                  attachments={attachments}
                  onDeleteAttachment={handleRemoveFile}
                  onNewAttachments={handleNewFiles}
                />
              </GridItem>

              {isUploading ? (
                <GridItem colSpan={[2, null, 6, 6]} pb={[3, null, 3]}>
                  <Text>Uploading files.</Text>
                </GridItem>
              ) : null}
              {(showAssets || assetToAdd) && (
                <GridItem colSpan={[2, null, 6, 6]} pb={[3, null, 6]}>
                  <HStack width="100%" justifyContent="space-between">
                    <Heading size="md" mb={3}>
                      Add Asset(s)
                    </Heading>
                    <HStack>
                      <Button
                        colorScheme="blue"
                        variant="outline"
                        leftIcon={<Icon as={BsPlus} />}
                        onClick={() => {
                          setShowAssetSection(true);
                        }}
                      >
                        Add Asset
                      </Button>
                      <Button
                        onClick={onOpen}
                        color={qrColor}
                        onTouchEnd={onOpen}
                        zIndex={100}
                        w={7}
                      >
                        <Icon h={7} w={7} as={MdQrCode2} />
                      </Button>
                    </HStack>
                    <QrQuickScanModal
                      isOpen={isOpen}
                      onClose={onClose}
                      onData={handleQrData}
                    />
                  </HStack>
                  {showAssetSection && (
                    <Stack w="100%" spacing={4}>
                      <StackItem w="100%">
                        <FormLabel>Location</FormLabel>
                        <LocationAutocomplete
                          menuPlacement="top"
                          value={
                            (assetLocationFilter as ApiLocationReference) ||
                            null
                          }
                          onChange={handleOnChangeAssetLocationFilter}
                        />
                      </StackItem>
                      <StackItem width="100%" display={displayValue} gap={4}>
                        <Box width={["100%", "50%"]} pb={[4, "unset"]}>
                          <ReportingCategoryAutocomplete
                            menuPlacement="top"
                            value={category || null}
                            onChange={handleOnChangeCategory}
                          />
                        </Box>
                        <Box width={["100%", "50%"]}>
                          <AssetSearchAutocomplete
                            menuPlacement="top"
                            value={assetToAdd}
                            onChange={assetToAddOnChange}
                            locations={assetSearchLocationIds}
                            categories={categoriesIds}
                          />
                        </Box>
                      </StackItem>
                    </Stack>
                  )}
                </GridItem>
              )}
              {showAssetSection && assets && assets.length === 0 && (
                <GridItem colSpan={[2, null, 6, 6]} px={2}>
                  Please, choose asset(s)
                </GridItem>
              )}
              {assets && assets.length > 0 && (
                <GridItem colSpan={[2, null, 6, 6]} pb={[3, null, 3]}>
                  <Stack spacing={4}>
                    {assets.map((a) => (
                      <AssetCard asset={a} key={`new-request-asset::${a.id}`}>
                        <Button onClick={handleRemoveAssetFactory(a)}>
                          Remove
                        </Button>
                      </AssetCard>
                    ))}
                  </Stack>
                </GridItem>
              )}
              {!isInModal && (
                <>
                  <GridItem colSpan={[1, null, 3, 3]} py={[3, null, 3]}>
                    <Button
                      variant="outline"
                      isDisabled={props.isSubmitting}
                      onClick={handleCancel}
                      size="lg"
                      p={3}
                    >
                      Cancel
                    </Button>
                  </GridItem>
                  <GridItem
                    colSpan={[1, null, 3, 3]}
                    pb={[3, null, 3]}
                    textAlign="right"
                  >
                    <Button
                      type="submit"
                      variant="solid"
                      colorScheme="blue"
                      isDisabled={props.isSubmitting || isUploading}
                      size="lg"
                      isLoading={props.isSubmitting}
                    >
                      Save Request
                    </Button>
                  </GridItem>
                </>
              )}
            </Grid>
            <FocusError />
          </Form>
        )}
      </Formik>
    </Box>
  );
};
