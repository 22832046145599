import { Box, Text } from "@chakra-ui/layout";
import { ApiBudget, ApiBudgetSummary } from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import { FocusEventHandler, useCallback, useMemo } from "react";
import { useAuthentication } from "../auth/AuthProvider";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  BudgetOptionpProp,
  BudgetSingleValueSelect,
  CustomSelectComponentProp,
} from "./select-overrides-types";

export interface BudgetAutocompleteProps {
  value: ApiBudgetSummary | ApiBudget | null;
  onChange: (value: ApiBudgetSummary | ApiBudget | null) => void;
  name?: string;
  placeholder?: string;
  onBlur?: FocusEventHandler;
  isDisabled?: boolean;
  isClearable?: boolean;
}

const BudgetBadge = ({ value }: { value: ApiBudgetSummary | ApiBudget }) => (
  <>
    <Text as="span" fontWeight="bold">
      {value.code}
    </Text>
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Text as="span" isTruncated>
        {value.name}
      </Text>
    </Box>
  </>
);

const CustomOptionComponent = createOptionComponent(
  ({ value }: { value: ApiBudgetSummary | ApiBudget }) => (
    <Box alignContent="center">
      <BudgetBadge value={value} />
    </Box>
  ),
);

const CustomSingleValueComponent = (props: BudgetSingleValueSelect) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps} alignContent="center">
      <BudgetBadge value={data} />
    </Box>
  );
};

export const BudgetAutocomplete = ({
  onChange,
  onBlur,
  name,
  placeholder,
  value,
  isDisabled,
  isClearable,
}: BudgetAutocompleteProps) => {
  const { currentAccount, apiClient } = useAuthentication();

  const handleChange = useCallback(
    (newValue: ApiBudgetSummary | ApiBudget | null) => {
      onChange(newValue);
    },
    [onChange],
  );

  const loadOptions = useCallback(
    (inputValue: string, cb: any) => {
      apiClient
        .findBudgets(currentAccount.id, {
          search: inputValue,
          pageSize: 10,
        })
        .then((results) => {
          cb(results.data);
        });
    },
    [apiClient, currentAccount],
  );

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: BudgetOptionpProp) => CustomOptionComponent(props),
      SingleValue: (props: BudgetSingleValueSelect) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  return (
    <AsyncSelect
      cacheOptions={true}
      components={components}
      defaultOptions={true}
      isDisabled={isDisabled}
      loadOptions={loadOptions}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      placeholder={placeholder || "Budget code or keyword"}
      value={value}
      getOptionValue={(item: ApiBudgetSummary | ApiBudget) => item.id}
      isClearable={isClearable}
      chakraStyles={commonStyles}
    />
  );
};
