import { Center, Divider, Flex, HStack, Text } from "@chakra-ui/react";
import { ApiProject } from "@operations-hero/lib-api-client";
import { Children, FC, PropsWithChildren, useMemo } from "react";
import { StatusBadge } from "../../../../components/badges/StatusBadge";
import { UsersBadge } from "../../components/UsersBadge";

type TotalAmount = "Total Allocated" | "Total Spent" | "Total Remaining";
type HeaderItemProps = PropsWithChildren & { title: string; value?: string };
type HeaderProps = {
  project: ApiProject;
};

const HeaderItem: FC<HeaderItemProps> = (props) => {
  const { title, value, children } = props;
  return (
    <Flex flexDir="column" alignItems="start">
      <Text color="gray" fontWeight="semibold" flex="1" fontSize="smaller">
        {title}
      </Text>
      {Children.count(children) > 0 ? (
        children
      ) : (
        <Text fontSize="larger" fontWeight="bold">
          {value}
        </Text>
      )}
    </Flex>
  );
};

export const Header: FC<HeaderProps> = ({ project }) => {
  const totals = useMemo(() => {
    return [
      {
        name: "Total Allocated",
        amount: project.fundsAllocated,
      },
      {
        name: "Total Spent",
        amount: project.fundsSpent,
      },
      {
        name: "Total Remaining",
        amount: project.fundsRemaining,
      },
    ] satisfies Array<{ name: TotalAmount; amount: number }>;
  }, [project.fundsAllocated, project.fundsRemaining, project.fundsSpent]);

  return (
    <HStack
      w="full"
      divider={
        <Center height="50px">
          <Divider orientation="vertical" />
        </Center>
      }
      gap={10}
    >
      <Flex gap={6} flexBasis="25%">
        {totals.map((t) => {
          return (
            <HeaderItem
              key={`header-item::${t.name}`}
              title={t.name}
              value={`$${t.amount.toString()}`}
            />
          );
        })}
      </Flex>
      <HStack justifyContent="space-between" flex="1">
        <HeaderItem title={"Status"}>
          <StatusBadge status={project.status} />
        </HeaderItem>
        <HeaderItem title="Assignee">
          <UsersBadge value={project.supervisors} />
        </HeaderItem>
      </HStack>
    </HStack>
  );
};
