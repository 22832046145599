import {
  Badge,
  Box,
  Image,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { Link } from "react-router-dom";

export default function Logo(props: any) {
  const logoUrl = useColorModeValue(
    "/OH_FinalLogo_ColorRGB.png",
    "/OH_FinalLogo_DarkMode_RGB1.png"
  );

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const mobileLogo = "/logo192.png";

  const showDevEnvironmentWarning = useMemo(
    () => process.env.REACT_APP_NODE_ENV !== "production",
    []
  );

  return (
    <Box {...props}>
      <Link to="/">
        <Box position="relative">
          <Image
            src={isMobile ? mobileLogo : logoUrl}
            alt="Operations Hero"
            maxH="50px"
          />
          {showDevEnvironmentWarning && (
            <Tooltip label="Currently connected to a testing environment">
              <Badge
                position="absolute"
                top={"35%"}
                w={"100px"}
                left="50%"
                marginLeft="-50px"
                colorScheme="red"
                textAlign="center"
                transform="rotate(-26deg)"
              >
                Test Env
              </Badge>
            </Tooltip>
          )}
        </Box>
      </Link>
    </Box>
  );
}
