import { Box, Button, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import {
  CumulioDashboard,
  CumulioDashboardComponent,
} from "@cumul.io/react-cumulio-dashboard";
import { ApiReport } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { ReportsAutocomplete } from "../../components/selects/ReportsAutocomplete";
import { useShowToast } from "../../hooks/showToast";
import useCumulioTheme from "../../hooks/useCumulioTheme";
import { RootState } from "../../store";
import { setCurrentReport } from "../../store/reports/reports.slice";
import { ReportRouteParams } from "./ReportPage";

type ReportsByProductProps = {
  product: string;
};
const DashboardConfig = {
  collectionId: "5422840d-565d-4cb2-89e1-88852c11714b",
};

export const ReportsByProduct: FC<ReportsByProductProps> = ({ product }) => {
  const luzmoUrl = process.env.REACT_APP_LUZMO_URL || "";

  const navigate = useNavigate();
  const { id: reportId } = useParams<ReportRouteParams>();
  const { currentReport } = useSelector(
    (state: RootState) => state.reportsSlice
  );

  const showToast = useShowToast();
  const dispatch = useDispatch();
  const dashboardRef = useRef<CumulioDashboard>();
  const [cumulio, setCumulio] = useState({ id: "", token: "" });
  const { widgetTheme, loader } = useCumulioTheme();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [isRenderingDashboard, setIsRenderingDashboard] = useState(true);
  const { apiClient, currentAccount, currentUser: user } = useAuthentication();

  const handleOnChange = useCallback(
    (selectedItem: ApiReport | null) => {
      if (selectedItem) {
        navigate(
          `/reports/${selectedItem.product.name.toLowerCase()}/${
            selectedItem.id
          }`,
          { replace: true }
        );
        dispatch(setCurrentReport(selectedItem));
      }
    },
    [navigate, dispatch]
  );

  const onClickDownload = useCallback(() => {
    if (dashboardRef?.current) {
      setIsExporting(true);
      dashboardRef.current
        .exportDashboard("pdf")
        .then(() => {
          setIsExporting(false);
          showToast("success", "File exported succesfully");
        })
        .catch(() => {
          showToast("error", "An error ocurred. Please try again");
          setIsExporting(false);
        });
    }
  }, [showToast]);

  useEffect(() => {
    apiClient
      .getEmbeddedToken(currentAccount.id, DashboardConfig.collectionId, {
        theme: widgetTheme,
      })
      .then((result) => {
        setCumulio(result);
      });
  }, [apiClient, currentAccount, widgetTheme]);

  useEffect(() => {
    setIsRenderingDashboard(true);
  }, [currentReport]);

  // effect to load initial report from a deeplink
  useEffect(() => {
    if (!isFirstLoad) {
      return;
    }

    // auth not loaded yet or report already selected;
    if (!cumulio.token || !cumulio.id) {
      return;
    }

    setIsFirstLoad(false);

    if (!reportId || (currentReport && currentReport.id === reportId)) {
      return;
    }

    apiClient.getAccountReport(currentAccount.id, reportId).then((report) => {
      dispatch(setCurrentReport(report));
    });
  }, [
    dispatch,
    apiClient,
    currentAccount,
    cumulio,
    currentReport,
    reportId,
    isFirstLoad,
  ]);

  return (
    <Grid w="full" gridTemplateColumns="repeat(5,1fr)" rowGap="5" columnGap="2">
      <GridItem colSpan={[4, 4, 3, 2]}>
        {!isFirstLoad && product && (
          <ReportsAutocomplete
            value={currentReport || null}
            onChange={handleOnChange}
            placeholder="Search for a report"
            product={product}
          />
        )}
      </GridItem>
      <GridItem colStart={5} justifySelf="end">
        <Button
          colorScheme="blue"
          isDisabled={isRenderingDashboard === true}
          onClick={onClickDownload}
          isLoading={isExporting}
          loadingText={"Preparing file..."}
        >
          Download PDF
        </Button>
      </GridItem>
      <GridItem colSpan={5}>
        {currentReport && cumulio.token && cumulio.id ? (
          <Box>
            <CumulioDashboardComponent
              ref={dashboardRef}
              authKey={cumulio.id}
              authToken={cumulio.token}
              loaderBackground={loader.bg}
              loaderSpinnerColor={loader.spinner}
              appServer={luzmoUrl}
              dashboardId={currentReport.dashboardId}
              load={() => {
                setIsRenderingDashboard(false);
              }}
              timezoneId={
                user.timeZone || currentAccount.timeZone || "America/New_York"
              }
            />
          </Box>
        ) : (
          <Box w="full" textAlign="center">
            <Icon as={FaFileAlt} width={10} height={10} color="gray.400" />
            <Text color="gray.400">Please select a report to show</Text>
          </Box>
        )}
      </GridItem>
    </Grid>
  );
};
