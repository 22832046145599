import {
  ApiEventSort,
  ApiEventsQueryFilter,
  ApiEventStatus,
} from "@operations-hero/lib-api-client";

export type DateFields = ApiEventSort;

export type RelativeDateOptions =
  | "thisWeek"
  | "nextWeek"
  | "thisMonth"
  | "nextMonth";

export type AbsoluteDateFilter = {
  field: DateFields;
  type: "absolute";
  value: (string | null)[];
};

export type RelativeDateFilter = {
  field: DateFields;
  type: "relative";
  value: RelativeDateOptions;
};

export type DateFilter = {
  field: DateFields;
  type: "relative" | "absolute";
  value: (string | null)[];
};
export interface EventQueryStringFilter {
  created: string[];
  updated: string[];
  eventDate: string[];
  date: RelativeDateFilter | AbsoluteDateFilter | null;
  dateRelative: RelativeDateFilter | undefined;
  page?: number;
  pageSize?: number;
  search: string;
  status?: ApiEventStatus | ApiEventStatus[];
  includeInactive: boolean;
  quickFilter: ApiEventsQueryFilter;
  venues: string[];
  eventGroups: string[];
  spaces: string[];
  services: string[];
  equipments: string[];

  sort: ApiEventSort;
  direction: "asc" | "desc";
  key?: string;
}

export const filtersInitialState: EventQueryStringFilter = {
  created: [],
  updated: [],
  eventDate: [],
  date: null,
  dateRelative: undefined,
  page: 1,
  pageSize: 20,
  search: "",
  status: undefined,
  includeInactive: false,
  quickFilter: ApiEventsQueryFilter.MY_EVENTS,
  venues: [],
  eventGroups: [],
  spaces: [],
  services: [],
  equipments: [],
  sort: "created",
  direction: "desc",
  key: undefined,
};
