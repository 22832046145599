import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Collapse,
  Container,
  Flex,
  Icon,
  IconButton,
  Img,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { SSOTokenType, WorkflowPolicy } from "@operations-hero/lib-api-client";
import { useCallback, useMemo, useRef } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useShowToast } from "../../../hooks/showToast";
import { useAllowedDashboards } from "../../../hooks/useDashboards";
import { RootState } from "../../../store";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { useAuthentication } from "../../auth/AuthProvider";
import { useProductSubscriptions } from "../AppShell";
import { AccountMenu } from "./AccountMenu";
import Logo from "./Logo";
import { QuickActions } from "./quick-actions/QuickActions";

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  to?: string;
  Element?: (params: { onClick: () => void }) => JSX.Element;
}

const getNavItems = (
  isProductStandard: boolean,
  isProductAdmin: boolean,
  isReviewer: boolean,
  hasRequests: boolean,
  hasEvents: boolean,
  hasInventory: boolean,
  isEventAdmin: boolean,
  isEventStandard: boolean,
  isTechnician: boolean,
  isInventoryStandard: boolean,
  isInventoryAdmin: boolean,
  hasEnergy: boolean,
  isEnergyUser: boolean,
  hasPlanning: boolean,
  isPlanningAdmin: boolean,
  isAdmin: boolean,
  policyMap: { [key: string]: WorkflowPolicy },
  reportsSummary: { [key: string]: number },
  allowInternalUserSubmitEvents?: boolean,
  allowInternalUsersViewCalendar?: boolean
) => {
  const items = [];
  if (
    Object.keys(policyMap).length > 0 &&
    hasRequests &&
    (isProductAdmin || isProductStandard)
  ) {
    items.push({
      label: "Requests",
      to: "/requests",
    });
  }

  if (
    isProductAdmin ||
    isAdmin ||
    isReviewer ||
    isEventAdmin ||
    isEventStandard ||
    isTechnician ||
    allowInternalUsersViewCalendar
  ) {
    items.push({
      label: "Calendar",
      to: "/calendar",
    });
  }

  if (
    hasEvents &&
    (allowInternalUserSubmitEvents || isEventAdmin || isEventStandard)
  ) {
    items.unshift({
      label: "Events",
      to: "/events",
    });
  }

  if (hasInventory && (isInventoryStandard || isInventoryAdmin)) {
    items.push({
      label: "Inventory",
      to: isInventoryAdmin ? "/inventory/items" : "/inventory/requests",
    });
  }

  if (
    reportsSummary["Total"] &&
    reportsSummary["Total"] > 0 &&
    (isAdmin ||
      (hasInventory && isInventoryAdmin) ||
      (hasEvents && isEventAdmin) ||
      (hasRequests && isProductAdmin))
  )
    items.push({
      label: "Reports",
      to: "/reports",
    });

  if (hasEnergy && isEnergyUser) {
    items.push({ label: "Energy" });
  }

  if (hasPlanning && isPlanningAdmin) {
    items.push({ label: "Planning", to: "/planning" });
  }

  return items;
};

export function NavBar() {
  const accountButtonRef = useRef(null);
  const { isOpen, onToggle } = useDisclosure();
  const {
    apiClient,
    isProductStandard,
    isProductAdmin,
    currentAccount,
    isEventAdmin,
    isEventStandard,
    isInventoryStandard,
    isInventoryAdmin,
    isEnergyUser,
    isPlanningAdmin,
  } = useAuthentication();
  const { isAdmin, isReviewer, policyMap, isTechnician, reportsSummary } =
    useSelector((state: RootState) => state.localCache);
  const { allowInternalUserSubmitEvents, allowInternalUsersViewCalendar } =
    useSelector((state: RootState) => state.eventSettingsSlice);
  const { hasRequests, hasEvents, hasInventory, hasEnergy, hasPlanning } =
    useProductSubscriptions();
  const showToast = useShowToast();

  const navItems: NavItem[] = useMemo(() => {
    const items: NavItem[] = [];

    const navBarItems = getNavItems(
      isProductStandard,
      isProductAdmin,
      isReviewer,
      hasRequests,
      hasEvents,
      hasInventory,
      isEventAdmin,
      isEventStandard,
      isTechnician,
      isInventoryStandard,
      isInventoryAdmin,
      hasEnergy,
      isEnergyUser,
      hasPlanning,
      isPlanningAdmin,
      isAdmin,
      policyMap,
      reportsSummary,
      allowInternalUserSubmitEvents,
      allowInternalUsersViewCalendar
    );
    items.push(...navBarItems);

    return items;
  }, [
    isProductAdmin,
    isProductStandard,
    isReviewer,
    hasRequests,
    hasEvents,
    hasInventory,
    isEventAdmin,
    isEventStandard,
    isTechnician,
    isInventoryStandard,
    isInventoryAdmin,
    hasEnergy,
    isEnergyUser,
    hasPlanning,
    isPlanningAdmin,
    isAdmin,
    policyMap,
    reportsSummary,
    allowInternalUserSubmitEvents,
    allowInternalUsersViewCalendar,
  ]);

  const energyCallback = useCallback(() => {
    apiClient
      .getSsoToken(currentAccount.id, SSOTokenType.energy)
      .then((response) => {
        const { jwt } = response;
        window.open(
          `${process.env.REACT_APP_NIMBLE_ENERGY_SSO_URL}?jwt=${jwt}`,
          "_blank"
        );
      })
      .catch(() => {
        showToast("error", "Something went wrong. Please try again later.");
      });
  }, [apiClient, currentAccount.id, showToast]);

  return (
    <Box
      p={0}
      borderBottom={1}
      borderStyle={"solid"}
      borderColor={useColorModeValue("gray.200", "gray.900")}
    >
      <Container maxW="8xl">
        <Flex
          bg={useColorModeValue("white", "gray.800")}
          color={useColorModeValue("gray.600", "white")}
          minH={"60px"}
          py={{ base: 2 }}
          px={{ base: 0 }}
          align={"center"}
          gap={2}
          justifyContent="space-between"
        >
          <Flex ml={{ base: -2 }} display={{ base: "flex", md: "none" }}>
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>

          <Flex
            justify={{ base: "start", md: "start" }}
            alignSelf={["center", null, "initial"]}
            justifySelf={["center", null, "initial"]}
          >
            <Box
              fontFamily={"heading"}
              color={useColorModeValue("gray.800", "white")}
            >
              <Logo />
            </Box>

            <Flex
              display={{ base: "none", md: "flex" }}
              verticalAlign="center"
              ml={[5, 5, 5, 5, 10, 10]}
            >
              <DesktopNav navItems={navItems} energyCallback={energyCallback} />
            </Flex>
          </Flex>
          <Flex flex="1" alignSelf="baseline">
            <QuickActions />
          </Flex>
          <Box ref={accountButtonRef} display="flex" alignContent="center">
            {currentAccount.logo ? (
              <Img
                src={currentAccount.logo}
                mr={2}
                alt="Logo"
                maxH="50px"
                maxW="100px"
                display={["none", "none", "none", "inline"]}
              />
            ) : null}
            <AccountMenu />
          </Box>
        </Flex>
      </Container>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav
          navItems={navItems}
          onClick={onToggle}
          energyCallback={energyCallback}
        />
      </Collapse>
    </Box>
  );
}

const DesktopNav = ({
  navItems,
  energyCallback,
}: {
  navItems: NavItem[];
  energyCallback: () => void;
}) => {
  const location = useLocation();
  const { userDashboards } = useAllowedDashboards();

  const linkHoverColor = useColorModeValue("gray.800", "white");
  const itemColor = useColorModeValue("gray.400", "whiteAlpha.700");
  const selectedItemColor = useColorModeValue("gray.600", "white");

  const selectedItem = useMemo(() => {
    const path = location.pathname.slice(1);
    if (path === "") return "Dashboard";
    return capitalizeFirstLetter(path);
  }, [location.pathname]);

  return (
    <Flex gap={[0, 0, 1, 1, 6]}>
      {userDashboards.length > 1 && (
        <Menu gutter={4}>
          <MenuButton
            color={itemColor}
            fontWeight="semibold"
            _hover={{ fontWeight: "semibold", color: linkHoverColor }}
          >
            <Flex alignItems="center" gap={1}>
              <Text as="span">Dashboards</Text>
              <MdKeyboardArrowDown />
            </Flex>
          </MenuButton>
          <MenuList>
            {userDashboards.map((item) => (
              <MenuItem key={`dashboardMenu::${item.label}`}>
                <Link
                  as={RouterLink}
                  to={item.path}
                  _hover={{
                    color: linkHoverColor,
                    textDecoration: "none",
                    fontWeight: "semibold",
                  }}
                >
                  {item.label}
                </Link>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}

      {userDashboards.length === 1 && (
        <Box pt={3}>
          <Link
            p={2}
            as={RouterLink}
            to={userDashboards[0].path}
            fontWeight="500"
            _hover={{
              textDecoration: "none",
              color: linkHoverColor,
            }}
            color={
              selectedItem === userDashboards[0].label
                ? selectedItemColor
                : itemColor
            }
          >
            Dashboard
          </Link>
        </Box>
      )}

      {navItems.map((navItem) => (
        <Box key={navItem.label} pt={3}>
          {navItem.Element ? (
            <navItem.Element onClick={() => {}} />
          ) : (
            <Link
              p={2}
              as={navItem.to ? RouterLink : undefined}
              href={navItem.href ?? undefined}
              to={navItem.to ? navItem.to : ""}
              fontWeight="500"
              _hover={{
                textDecoration: "none",
                color: linkHoverColor,
              }}
              color={
                selectedItem === navItem.label ? selectedItemColor : itemColor
              }
              onClick={navItem.label === "Energy" ? energyCallback : () => {}}
            >
              {navItem.label}
            </Link>
          )}
        </Box>
      ))}
    </Flex>
  );
};

const MobileNav = ({
  navItems,
  onClick,
  energyCallback,
}: {
  navItems: NavItem[];
  onClick: () => void;
  energyCallback: () => void;
}) => {
  const { userDashboards } = useAllowedDashboards();

  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {userDashboards.length > 0 &&
        userDashboards.map((item) => (
          <MobileNavItem
            to={item.path}
            onClick={onClick}
            label={`${item.label} Dashboard`}
            key={`DashboardMenu::${item.label}`}
          />
        ))}

      {navItems.map((navItem) => (
        <MobileNavItem
          key={navItem.label}
          {...navItem}
          onClick={navItem.label === "Energy" ? energyCallback : onClick}
        />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({
  label,
  children,
  href,
  to,
  Element,
  onClick,
}: NavItem & { onClick: () => void }) => {
  const { isOpen, onToggle } = useDisclosure();
  const selectedItemColor = useColorModeValue("gray.600", "gray.200");

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        {Element != null ? (
          <Element onClick={onClick} />
        ) : (
          <Link
            as={RouterLink}
            to={to ? to : ""}
            fontWeight="600"
            color={selectedItemColor}
            onClick={onClick}
          >
            {label}
          </Link>
        )}
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) =>
              child.Element ? (
                <child.Element onClick={() => {}} />
              ) : (
                <Link
                  key={child.label}
                  py={2}
                  as={child.to ? RouterLink : undefined}
                  href={child.href ?? undefined}
                  to={child.to ? child.to : ""}
                >
                  {child.label}
                </Link>
              )
            )}
        </Stack>
      </Collapse>
    </Stack>
  );
};
