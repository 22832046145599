import {
  ApiAccount,
  ApiAccountDetail,
  ApiAccountSettings,
  ApiAsset,
  ApiClient,
  ApiEvent,
  ApiRequest,
  ApiRequestAssignee,
  ApiRequestPriority,
  ApiRequestsQueryFilter,
  ApiRequestType,
  ApiScheduledRequest,
  ApiService,
  ApiUserSummary,
  FindRequestsOptions,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { WORKFLOW_FILTERS } from "../pages/landing-page/requests/LandingPage";
import { PersonFilterReferenceValue } from "../pages/requests/filters/PersonFilter";
import { SaveDataObject } from "../pages/requests/list-headers/RequestListActions";
import { QUICK_FILTERS } from "../pages/requests/list-headers/RequestListViewSelector";
import getRangeRelativeDate from "../utils/getRangeRelativeDate";
import { uniqueMap } from "../utils/uniqueMap";
import { projectRequestsHandlers } from "./planning-hq/requests/findRequests.thunk";
import { projectRequestsFiltersHandlers } from "./planning-hq/requests/updateFilters.thunk";
import { columnViewFiltersOverride } from "./request-column-view.slice";
import { RequestDisplayMode } from "./requests.slice";
import {
  DEFAULT_PAGE_SIZE,
  filtersInitialState,
  REQUEST_LIST_FILTERS,
  REQUEST_LIST_TITLE,
} from "./requests/defaults";
import { loadTransitionMap } from "./requests/request-transitions.thunk";
import { TransitionMap } from "./requests/types";

export interface InitRequestListThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  currentPage?: number;
}

export const initRequestList = createAsyncThunk(
  "request-list/init",
  async (
    { apiClient, account, currentPage }: InitRequestListThunkParams,
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const { displayMode } = state.requestsSlice;

    let quickFilter = ApiRequestsQueryFilter.MY_REQUESTS;

    if (state.localCache.isTechnician || state.localCache.isContractor) {
      quickFilter = ApiRequestsQueryFilter.ASSIGNED_TO_ME;
    }
    if (state.localCache.isReviewer) {
      quickFilter = ApiRequestsQueryFilter.NEEDS_REVIEW;
    }
    if (
      state.localCache.isAdmin ||
      state.auth.isProductAdmin ||
      state.localCache.isApprover
    ) {
      quickFilter = ApiRequestsQueryFilter.ROUTED_REQUESTS;
    }

    let filters = currentPage
      ? { ...filtersInitialState, quickFilter, currentPage }
      : { ...filtersInitialState, quickFilter };

    const workflowFilters = localStorage.getItem(WORKFLOW_FILTERS);
    if (workflowFilters) {
      const validWorkflowFilters = JSON.parse(workflowFilters);
      if (Array.isArray(validWorkflowFilters)) {
        filters.workflows = state.localCache.workflows
          .map((wf) => wf.id)
          .filter((vwf) => validWorkflowFilters.includes(vwf));
      }
    }

    const initTitle = QUICK_FILTERS[quickFilter];

    const [settingsRequestFilters, accountDetail] = await Promise.all([
      apiClient.getCurrentUserSettings(account.id, [REQUEST_LIST_FILTERS]),
      apiClient.getAccountDetail(account.id),
    ]);

    const userSavedFilters = settingsRequestFilters[REQUEST_LIST_FILTERS] || [];

    if (displayMode === "column") {
      filters = {
        ...filters,
        ...columnViewFiltersOverride,
        displayModeFilter: "column",
      };
    }

    return {
      userSavedFilters,
      filters,
      initTitle,
      accountDetail,
    };
  }
);

export const unassignee: ApiUserSummary = {
  id: "unassigned",
  firstName: "Unassigned",
  lastName: "",
  email: "",
  phone: "",
  profileImage: "",
  timeZone: null,
};
export const unassignedAssignee: ApiRequestAssignee = {
  type: "user",
  assignee: unassignee,
};
export const unassignedPerson: PersonFilterValue = {
  type: "assignee",
  groupOrUser: unassignedAssignee,
  key: "assignee::unassigned",
};
export interface InitPeopleFilterThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  selectedValues: PersonFilterReferenceValue[];
}
export interface InitFilterThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  selectedValues: string[];
}
export interface LoadFilterThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  search?: string;
}
export const initEventFilter = createAsyncThunk(
  "request-list/init-event-filter",
  async ({ apiClient, account, selectedValues }: InitFilterThunkParams) => {
    const options = { ids: selectedValues };
    const data = await apiClient.findEvents(account.id, options);
    return data.data;
  }
);
export const loadEventFilter = createAsyncThunk(
  "request-list/load-event-filter",
  async ({ apiClient, account, search }: LoadFilterThunkParams) => {
    const options = {
      page: search ? undefined : 1,
      pageSize: search ? undefined : 20,
      search: search ? search : undefined,
    };
    const data = await apiClient.findEvents(account.id, options);
    return data.data;
  }
);
export const initScheduledRequestFilter = createAsyncThunk(
  "request-list/init-scheduled-request-filter",
  async ({ apiClient, account, selectedValues }: InitFilterThunkParams) => {
    const options = { ids: selectedValues };
    const data = await apiClient.findScheduledRequests(account.id, options);
    return data.data;
  }
);
export const loadScheduledRequestFilter = createAsyncThunk(
  "request-list/load-scheduled-request-filter",
  async ({ apiClient, account, search }: LoadFilterThunkParams) => {
    const options = {
      page: search ? undefined : 1,
      pageSize: search ? undefined : 20,
      search: search ? search : undefined,
    };
    const data = await apiClient.findScheduledRequests(account.id, options);
    return data.data;
  }
);
export const initServiceFilter = createAsyncThunk(
  "request-list/init-service-filter",
  async ({ apiClient, account, selectedValues }: InitFilterThunkParams) => {
    const options = { ids: selectedValues };
    const data = await apiClient.findServices(account.id, options);
    return data.data;
  }
);
export const loadServiceFilter = createAsyncThunk(
  "request-list/load-service-filter",
  async ({ apiClient, account, search }: LoadFilterThunkParams) => {
    const options = {
      page: search ? undefined : 1,
      pageSize: search ? undefined : 20,
      search: search ? search : undefined,
    };
    const data = await apiClient.findServices(account.id, options);
    return data.data;
  }
);
export const initAssetFilter = createAsyncThunk(
  "request-list/init-asset-filter",
  async ({ apiClient, account, selectedValues }: InitFilterThunkParams) => {
    const options = { ids: selectedValues };
    const data = await apiClient.findAssets(account.id, options);
    return data.data;
  }
);
export const loadAssetFilter = createAsyncThunk(
  "request-list/load-asset-filter",
  async ({ apiClient, account, search }: LoadFilterThunkParams) => {
    const options = {
      page: search ? undefined : 1,
      pageSize: search ? undefined : 100,
      search: search ? search : undefined,
    };
    const data = await apiClient.findAssets(account.id, options);
    return data.data;
  }
);
export const initPeopleFilter = createAsyncThunk(
  "request-list/init-people-filter",
  async (
    { apiClient, account, selectedValues }: InitPeopleFilterThunkParams,
    thunkAPI
  ) => {
    const ids = selectedValues.map((x) =>
      typeof x.groupOrUser === "string"
        ? x.groupOrUser
        : x.groupOrUser.assignee.id
    );

    const options = {
      ids: ids.filter((x) => x !== unassignee.id),
    };

    const data = await apiClient.findUserAndGroupsRequestFilter(
      account.id,
      options
    );
    const map = data.reduce<Record<string, ApiRequestAssignee>>(
      (result, item) => {
        result[item.assignee.id] = item;
        return result;
      },
      { [unassignee.id]: unassignedAssignee }
    );

    const groupOrUsers = selectedValues.reduce<PersonFilterValue[]>(
      (result, item) => {
        const id =
          typeof item.groupOrUser === "string"
            ? item.groupOrUser
            : item.groupOrUser.assignee.id;
        const hydrated = map[id];

        // id not found
        if (!hydrated) {
          return result;
        }

        result.push({
          type: item.type,
          groupOrUser: hydrated,
          key: `${item.type}::${hydrated.assignee.id}`,
        });

        return result;
      },
      []
    );

    return groupOrUsers;
  }
);
export interface LoadPeopleFilterOptionsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  type: PersonFilterValueType;
  search?: string;
}

export const loadPeopleFilterOptions = createAsyncThunk(
  "request-list/people-filter-options",
  async (
    { apiClient, account, search, type }: LoadPeopleFilterOptionsThunkParams,
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const workflowIdsOrSlugs = state.requestList.filters.workflows;
    const options = {
      typeToSearch: type,
      workflowIdsOrSlugs,
      search,
    };
    const data = await apiClient.findUserAndGroupsRequestFilter(
      account.id,
      options
    );

    const uniqueMap = data.reduce<Record<string, PersonFilterValue>>(
      (result, item) => {
        const key = `${type}::${item.assignee.id}`;
        result[key] = {
          type,
          groupOrUser: item,
          key,
        };
        return result;
      },
      {}
    );

    const groupOrUser = Object.values(uniqueMap);
    if (type === "assignee") groupOrUser.unshift(unassignedPerson);
    return groupOrUser;
  }
);

export interface LoadRequestsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  page?: number;
  pageSize?: number;
}

export const loadRequests = createAsyncThunk(
  "request-list/load-list",
  async (
    { apiClient, account, pageSize }: LoadRequestsThunkParams,
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;

    const { sort, filters } = state.requestList;
    let newFilters = { ...filters };
    if (pageSize) {
      newFilters = { ...newFilters, pageSize: pageSize };
    }
    const options = getRequestFilter(sort, newFilters);

    const requests = await apiClient.findRequests(account.id, options);
    return {
      requests,
      pageSize,
    };
  }
);

export function getRequestFilter(
  sort: {
    direction: "asc" | "desc";
    field: string;
  },
  filters: RequestListFilterState
) {
  const options: FindRequestsOptions = {
    pageSize: filters.pageSize,
    sort: sort.field,
    direction: sort.direction,
    page: filters.currentPage,
    quickFilter: filters?.quickFilter,
    search: filters?.search,
    location: filters?.locations,
    workflow: filters?.workflows,
    status: filters?.statuses,
    priority: filters?.priorities as ApiRequestPriority[],
    assignee: filters?.persons
      .filter((x) => x.type === "assignee")
      .map((x) =>
        typeof x.groupOrUser === "string"
          ? x.groupOrUser
          : x.groupOrUser.assignee.id
      ),
    requester: filters?.persons
      .filter((x) => x.type === "requester")
      .map((x) =>
        typeof x.groupOrUser === "string"
          ? x.groupOrUser
          : x.groupOrUser.assignee.id
      ),
    reason: filters?.reasons,
    category: filters?.categories,
    type: filters?.types as ApiRequestType[],
    asset: filters.assets,
    events: filters.events,
    services: filters.services,
    scheduledRequest: filters.scheduledRequest,
    [filters.date ? filters.date.field : ""]: filters.date
      ? filters.date.type === "relative"
        ? getRangeRelativeDate(filters.date.value)
        : filters.date.value
      : null,
  };

  if (filters.date && filters.date.field) {
    // @ts-ignore
    options[filters.date.field] =
      filters.date.type === "relative"
        ? getRangeRelativeDate(filters.date.value)
        : filters.date.value;
  }

  // strip empty properties
  Object.entries(options).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length === 0) {
      // @ts-ignore
      delete options[key];
      return;
    }

    if (value == null) {
      // @ts-ignore
      delete options[key];
    }
  });

  return options;
}

export interface UpdatedSavedFilterProps {
  apiClient: ApiClient;
  accountId: string;
  savedFilters: ApiAccountSettings[];
}
export const updatedSavedFilters = createAsyncThunk(
  "request-list/update-saved-filters",
  async ({ apiClient, accountId, savedFilters }: UpdatedSavedFilterProps) => {
    return apiClient.updateCurrentUserSettings(accountId, {
      "request-list-filters": savedFilters,
    });
  }
);

export type DateFields =
  | "start"
  | "due"
  | "completed"
  | "statusUpdated"
  | "created"
  | "updated";

export type RelativeDateOptions =
  | "last7d"
  | "last14d"
  | "next7d"
  | "last30d"
  | "next30d"
  | "thisWeek"
  | "lastWeek"
  | "nextWeek"
  | "thisMonth"
  | "lastMonth"
  | "nextMonth";

export type AbsoluteDateFilter = {
  field: DateFields;
  type: "absolute";
  value: (string | null)[];
  isFiscalYear?: boolean;
  fiscalYear?: "thisfiscalyear" | "lastfiscalyear";
};

export type RelativeDateFilter = {
  field: DateFields;
  type: "relative";
  value: RelativeDateOptions;
};

export type DateFilter = {
  field: DateFields;
  type: "relative" | "absolute";
  value: (string | null)[];
};

export type PersonFilterValueType = "requester" | "assignee";

export interface PersonFilterValue {
  type: PersonFilterValueType;
  groupOrUser: ApiRequestAssignee;
  key: string;
}
export interface RequestListFilterState {
  workflows: string[];
  statuses: string[];
  priorities: string[];
  locations: string[];
  assignees: string[];
  requesters: string[];
  persons: PersonFilterReferenceValue[];
  categories: string[];
  reasons: string[];
  created: string[];
  updated: string[];
  statusUpdated: string[];
  start: string[];
  due: string[];
  completed: string[];
  date: RelativeDateFilter | AbsoluteDateFilter | null;
  dateRelative: RelativeDateFilter | undefined;
  currentPage: number;
  pageSize: number;
  search: string;
  quickFilter?: ApiRequestsQueryFilter;
  types: string[];
  assets: string[];
  events: string[];
  services: string[];
  scheduledRequest: string[];
  moreFilters: string[];
  displayModeFilter: RequestDisplayMode | undefined;
}

export interface RequestListSliceState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  requests: ApiRequest[];
  totalRequests: number;
  filters: RequestListFilterState;
  initCompleted: boolean;
  listTitle: string;
  sort: {
    direction: "asc" | "desc";
    field: string;
  };
  dateField: DateFields | undefined;
  isCustomDate: boolean;
  queryStringFilter: string;
  savedSearchFilters: SaveDataObject[];
  availablePersons: PersonFilterValue[];
  availablePersonsLoading: boolean;
  assets: ApiAsset[];
  events: ApiEvent[];
  scheduledRequest: ApiScheduledRequest[];
  services: ApiService[];
  accountDetail?: ApiAccountDetail;
  transitionMap: TransitionMap;
}

export interface SetFiscalYearValues {
  isFiscalYear: boolean;
  fiscalYear: "thisfiscalyear" | "lastfiscalyear";
}

const requestListSliceInitialState: RequestListSliceState = {
  loading: "idle",
  requests: [],
  totalRequests: 0,
  sort: {
    direction: "desc",
    field: "created",
  },
  filters: { ...filtersInitialState },
  initCompleted: false,
  queryStringFilter: "",
  savedSearchFilters: [],
  dateField: undefined,
  isCustomDate: false,
  availablePersons: [],
  availablePersonsLoading: false,
  listTitle: localStorage.getItem(REQUEST_LIST_TITLE) || "",
  assets: [],
  events: [],
  scheduledRequest: [],
  services: [],
  accountDetail: undefined,
  transitionMap: {},
};

export const requestListSlice = createSlice({
  name: "request-list",
  initialState: requestListSliceInitialState,
  reducers: {
    unloadRequestList: (state) => {
      state.loading = "idle";
      state.requests = [];
      state.totalRequests = 0;
      state.filters = { ...filtersInitialState };
      state.queryStringFilter = "";
      state.listTitle = "";
      state.initCompleted = false;
      state.transitionMap = {};
    },
    cleanAllFilters: (state) => {
      const quickFilter = state.filters.quickFilter;
      state.filters = { ...filtersInitialState, quickFilter };
      state.sort.field = "created";
      state.sort.direction = "desc";
    },
    updateRequestFilters: (
      state,
      action: PayloadAction<
        Partial<
          RequestListFilterState & {
            newTitle?: string | null;
            displayMode: RequestDisplayMode;
          }
        >
      >
    ) => {
      const { displayMode } = action.payload;
      let filters = {
        ...state.filters,
        ...{
          currentPage: 1,
        },
        ...action.payload,
      };

      if (displayMode === "column") {
        filters = { ...filters, ...columnViewFiltersOverride };
      }

      state.filters = filters;

      const queryStringFilter: RequestListFilterState = {
        ...filters,
        persons: filters.persons
          .filter((p) => !!p && !!p.groupOrUser)
          .map((x) => ({
            type: x.type,
            groupOrUser:
              typeof x.groupOrUser === "string"
                ? x.groupOrUser
                : x.groupOrUser.assignee.id,
          })),
      };
      state.queryStringFilter = btoa(
        encodeURIComponent(JSON.stringify(queryStringFilter))
      );
      if (action.payload.quickFilter) {
        state.listTitle = QUICK_FILTERS[action.payload.quickFilter];
      }
    },
    setSavedSearchFilters: (state, action: PayloadAction<SaveDataObject[]>) => {
      state.savedSearchFilters = action.payload;
    },
    setCurrentPage: (state, payload: PayloadAction<number>) => {
      state.filters.currentPage = payload.payload;
    },
    setSortDirection: (state, payload: PayloadAction<"asc" | "desc">) => {
      state.sort.direction = payload.payload;
      state.filters.currentPage = 1;
    },
    setSortField: (state, payload: PayloadAction<string>) => {
      state.sort.field = payload.payload;
      state.filters.currentPage = 1;
    },
    setDateField: (state, action: PayloadAction<DateFields>) => {
      state.dateField = action.payload;
    },
    setRelativeDate: (state, action: PayloadAction<RelativeDateFilter>) => {
      state.filters.date = action.payload;
    },
    setAbsoluteDate: (state, action: PayloadAction<AbsoluteDateFilter>) => {
      state.filters.date = action.payload;
    },
    setIsCustomDate: (state, action: PayloadAction<boolean>) => {
      state.isCustomDate = action.payload;
    },
    setRequestListTitle: (state, action: PayloadAction<string>) => {
      state.listTitle = action.payload;
    },
    setInitCompleted: (state, action: PayloadAction<boolean>) => {
      state.initCompleted = action.payload;
    },
    setIsFiscalYear: (state, action: PayloadAction<SetFiscalYearValues>) => {
      if (state.filters.date?.type === "absolute") {
        state.filters.date.isFiscalYear = action.payload.isFiscalYear;
        state.filters.date.fiscalYear = action.payload.fiscalYear;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initRequestList.fulfilled, (state, action) => {
      state.savedSearchFilters = action.payload.userSavedFilters as [];
      state.queryStringFilter = "";
      state.filters = {
        ...action.payload.filters,
      };
      state.dateField = undefined;
      state.listTitle = action.payload.initTitle;
      state.accountDetail = action.payload.accountDetail;
    });

    builder.addCase(loadRequests.fulfilled, (state, action) => {
      const { data, total, options } = action.payload.requests;
      state.loading = "succeeded";
      state.filters.currentPage =
        options.page || state.filters.currentPage || 1;
      state.filters.pageSize = action.payload.pageSize || DEFAULT_PAGE_SIZE;
      state.requests = data;
      state.totalRequests = total;
    });
    builder.addCase(loadRequests.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(loadRequests.rejected, (state) => {
      state.loading = "failed";
      state.requests = [];
    });

    builder.addCase(initPeopleFilter.fulfilled, (state, action) => {
      state.filters.persons = action.payload;
      state.availablePersons = action.payload;
    });
    builder.addCase(initAssetFilter.fulfilled, (state, action) => {
      state.assets = action.payload;
    });
    builder.addCase(loadAssetFilter.fulfilled, (state, action) => {
      const itemsToAdd = state.filters.assets;

      state.assets = uniqueMap<ApiAsset>(
        itemsToAdd,
        state.assets,
        action.payload
      );
    });
    builder.addCase(initEventFilter.fulfilled, (state, action) => {
      state.events = action.payload;
    });
    builder.addCase(loadEventFilter.fulfilled, (state, action) => {
      const itemsToAdd = state.filters.events;

      state.events = uniqueMap<ApiEvent>(
        itemsToAdd,
        state.events,
        action.payload
      );
    });
    builder.addCase(initScheduledRequestFilter.fulfilled, (state, action) => {
      state.scheduledRequest = action.payload;
    });
    builder.addCase(loadScheduledRequestFilter.fulfilled, (state, action) => {
      const itemsToAdd = state.filters.scheduledRequest;

      state.scheduledRequest = uniqueMap<ApiScheduledRequest>(
        itemsToAdd,
        state.scheduledRequest,
        action.payload
      );
    });
    builder.addCase(initServiceFilter.fulfilled, (state, action) => {
      state.services = action.payload;
    });
    builder.addCase(loadServiceFilter.fulfilled, (state, action) => {
      const itemsToAdd = state.filters.services;

      state.services = uniqueMap<ApiService>(
        itemsToAdd,
        state.services,
        action.payload
      );
    });
    builder.addCase(loadPeopleFilterOptions.fulfilled, (state, action) => {
      const isSearching = !!action.meta.arg.search;
      const selected = state.filters.persons;

      if (isSearching || !selected || selected.length === 0) {
        state.availablePersons = action.payload;
        return;
      }

      const itemsToAdd = selected.filter(
        (x) => x.type === action.meta.arg.type
      );

      // add selected items for the type at the top of the list
      // then add the first available page from the api call as options
      let uniqueMap: Record<string, PersonFilterValue> = {};
      uniqueMap = itemsToAdd.reduce<Record<string, PersonFilterValue>>(
        (result, item) => {
          const hydrated = item as PersonFilterReferenceValue;
          const id =
            typeof hydrated.groupOrUser === "string"
              ? hydrated.groupOrUser
              : hydrated.groupOrUser.assignee.id;
          result[id] = item as PersonFilterValue;
          return result;
        },
        {}
      );

      uniqueMap = action.payload.reduce<Record<string, PersonFilterValue>>(
        (result, item) => {
          result[item.groupOrUser.assignee.id] = item;
          return result;
        },
        uniqueMap
      );

      state.availablePersons = Object.values(uniqueMap);
    });

    builder.addCase(updatedSavedFilters.fulfilled, (state, action) => {
      state.savedSearchFilters = action.payload[REQUEST_LIST_FILTERS] as [];
    });

    builder.addCase(loadTransitionMap.fulfilled, (state, action) => {
      state.transitionMap = action.payload.transitionMap;
    });

    projectRequestsHandlers(builder);
    projectRequestsFiltersHandlers(builder);
  },
});

// Action creators are generated for each case reducer function
export const {
  cleanAllFilters,
  setAbsoluteDate,
  setCurrentPage,
  setDateField,
  setInitCompleted,
  setIsCustomDate,
  setIsFiscalYear,
  setRelativeDate,
  setRequestListTitle,
  setSavedSearchFilters,
  setSortDirection,
  setSortField,
  unloadRequestList,
  updateRequestFilters,
} = requestListSlice.actions;

export default requestListSlice.reducer;
