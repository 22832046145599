import {
  Box,
  Button,
  Collapse,
  Divider,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  ApiEvent,
  ApiEventBlock,
  ApiEventGroup,
  ApiRentableEquipment,
  ApiServiceSumary,
  ApiSpace,
  ApiUser,
  ApiUserSummary,
  ApiVenue,
  ApiVenueSummary,
  CreateApiEvent,
  CreateApiEventEquipment,
  CreateApiEventOccurrence,
} from "@operations-hero/lib-api-client";
import { CreateApiQuestionResponse } from "@operations-hero/lib-api-client/dist/models/QuestionResponse";
import axios from "axios";
import { Form, Formik } from "formik";
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Attachment,
  Attachments,
} from "../../../components/attachments/Attachments";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { CheckboxControl } from "../../../components/form-helpers/CheckboxControl";
import {
  CreateUserType,
  EventGroupUserAutocompleteControl,
} from "../../../components/form-helpers/EventGroupUserAutocompleteControl";
import { NumberInputControl } from "../../../components/form-helpers/NumberInputControl";
import { RadioButtonsControl } from "../../../components/form-helpers/RadioButtonsControl";
import { TextEditorControl } from "../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import { TextInputControl } from "../../../components/form-helpers/TextInputControl";
import { FormikObserver } from "../../../hooks/formikObserver";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import { initEventList } from "../../../store/events/event-list.slice";
import { setIsOpenEventModal } from "../../../store/events/event-modal.slice";
import {
  setFormikModal,
  setIsSubmitting,
} from "../../../store/formik-modal.slice";
import { formatCurrency } from "../../../utils/formatCurrency";
import {
  EventEquipmentSection,
  SpaceBasicData,
} from "../event-form/form-components/EventEquipmentSection";
import { EventFormSchema } from "./eventSchema";
import { BlockedVenueOrSpacesAlert } from "./form-components/BlockedVenuesOrSpacesAlert";
import { ConflictAlert } from "./form-components/ConflictsAlert";
import { EventGroupSelector } from "./form-components/EventGroupSelector";
import { ServiceQuestionResponseReducerValues } from "./form-components/questions/ServiceQuestionsResponsesReducer";
import { ServiceQuestionsSection } from "./form-components/questions/ServiceQuestionsSection";
import { SpaceQuestionResponseReducerValues } from "./form-components/questions/SpaceQuestionsResponsesReducer";
import { SpaceQuestionsSection } from "./form-components/questions/SpaceQuestionsSection";
import { VenueQuestionResponseReducerValues } from "./form-components/questions/VenueQuestionsResponsesReducer";
import { VenueQuestionsSection } from "./form-components/questions/VenueQuestionsSection";
import ScheduledEvents from "./form-components/ScheduleEvent";
import {
  EventServiceData,
  ServicesMulticheck,
} from "./form-components/ServicesMulticheck";
import { VenueSpacesSection } from "./form-components/VenueSpacesSection";
import { NewEventToast } from "./NewEventToast";

export interface RentableEquipmentProps
  extends Omit<CreateApiEventEquipment, "rentableEquipment"> {
  error?: boolean;
  errorMessage?: string;
  rentableEquipment: ApiRentableEquipment & { space?: SpaceBasicData };
}

export interface EventFormValues {
  name: string;
  timeZone: string;
  attendees: number;
  isPublic: boolean;
  description: string | null;
  venue: ApiVenueSummary | null;
  spaces: ApiSpace[];
  services: Record<string, EventServiceData>;
  eventGroup: ApiEventGroup | null;
  isInternal: "internal" | "external";
  rentableEquipment: RentableEquipmentProps[];
  eventContact: ApiUserSummary | null;
}
export interface EventFormProps {
  event?: ApiEvent | null;
  venue?: ApiVenue | null;
  showSubmitButton?: boolean;
  onClose?: () => void;
}
export const EventForm: FC<EventFormProps> = ({
  event,
  venue,
  onClose,
  showSubmitButton = false,
}) => {
  const [venueDetail, setVenueDetail] = useState<ApiVenue | undefined>(
    venue || undefined
  );
  const [eventGroup, setEventGroup] = useState<ApiEventGroup>();
  const [attachemnts, setAttachments] = useState<Attachment[]>([]);
  const [occurrences, setOcurrences] = useState<CreateApiEventOccurrence[]>([]);
  const [hasConflicts, setHasConflicts] = useState(false);
  const [equipmentCost, setEquipmentCost] = useState(0);
  const [servicesCost, setServicesCost] = useState(0);
  const [spacesCost, setSpacesCost] = useState(0);
  const [eventCost, setEventCost] = useState(0);
  const [blockedVenueOrSpace, setBlockedVenueOrSpace] = useState<
    ApiEventBlock[]
  >([]);

  const [newVenueQuestionResponses, setNewVenueQuestionResponses] = useState<
    VenueQuestionResponseReducerValues[]
  >([]);

  const [newSpaceQuestionResponses, setNewSpaceQuestionResponses] = useState<
    SpaceQuestionResponseReducerValues[]
  >([]);

  const [newServiceQuestionResponses, setNewServiceQuestionResponses] =
    useState<ServiceQuestionResponseReducerValues[]>([]);

  const [venueFieldsValid, setVenueFieldsValid] = useState(true);
  const [spacesFieldsValid, setSpacesFieldsValid] = useState(true);
  const [servicesFieldsValid, setServicesFieldsValid] = useState(true);

  const [selectedServices, setSelectedServices] = useState<ApiServiceSumary[]>(
    []
  );

  const showToast = useShowToast();
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const { apiClient, currentAccount } = useAuthentication();

  const { isEventListOpen } = useSelector(
    (state: RootState) => state.newEventSlice
  );
  const { timeZone } = useSelector((state: RootState) => state.localCache);
  const { enableInvoicesForEvents } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );

  const getEventGroup = useCallback(async () => {
    if (event?.eventGroup) {
      const group = await apiClient.getEventGroup(
        currentAccount.id,
        event.eventGroup.id
      );
      setEventGroup(group);
      return;
    }
  }, [apiClient, currentAccount.id, event?.eventGroup]);

  useEffect(() => {
    getEventGroup();
  }, [getEventGroup]);

  const initialValues: EventFormValues = useMemo(() => {
    let spacesHash: Record<string, ApiSpace> = {};
    let servicesHash: Record<string, EventServiceData> = {};

    if (event && venue) {
      if (event.spaces.length > 0) {
        spacesHash = event.spaces.reduce(
          (hash, space) => {
            const completeSpace = venue.spaces.find(
              (vs) => vs.location.id === space.location.id
            );
            if (completeSpace) {
              hash[completeSpace.id] = completeSpace;
            }
            return hash;
          },
          {} as Record<string, ApiSpace>
        );
      }

      if (event.services.length > 0) {
        servicesHash = event.services.reduce(
          (hash, service) => {
            const key = `${service.id}${
              service.spaceId ? `:${service.spaceId}` : ""
            }`;
            hash[key] = {
              assignees: [],
              service: service,
              isRequired: false,
              spaceId: service.spaceId || undefined,
              additionalNotes: service.additionalNotes || "",
              spaceName: service.spaceId
                ? spacesHash[service.id].location.name
                : undefined,
            };
            return hash;
          },
          {} as Record<string, EventServiceData>
        );
      }
    }

    const rentableEquipment = event?.rentableEquipment
      .filter((eq) =>
        venue?.rentableEquipment?.some((veq) => veq?.id === eq?.id)
      )
      .map((eq) => ({
        rentableEquipment: eq,
        quantity: eq?.quantity,
        additionalNotes: eq?.additionalNotes,
      }));
    return {
      name: event ? event.name : "",
      spaces: [...Object.values(spacesHash)],
      venue: venue ? venue : null,
      services: servicesHash,
      attendees: event ? event.attendees : 0,
      description: event ? event.description : "",
      isPublic: event
        ? event.isPublic
        : eventGroup?.rateGroup
          ? eventGroup.rateGroup.showInPublicCalendar
          : false,
      eventGroup: eventGroup ? eventGroup : null,
      isInternal: event
        ? event.isInternal
          ? "internal"
          : "external"
        : "internal",
      timeZone: timeZone,
      rentableEquipment: rentableEquipment
        ? rentableEquipment
        : ([] as RentableEquipmentProps[]),
      eventContact: event ? event.eventContact : null,
    };
  }, [event, venue, eventGroup, timeZone]);

  const mapNewEventValues = useCallback(
    (values: EventFormValues): CreateApiEvent => {
      const newVenue = values.venue ? values.venue.id : "";
      const eventEquipment = values.rentableEquipment.map((item) => {
        let rentableEquipment = item.rentableEquipment as ApiRentableEquipment;

        if (item.rentableEquipment.space !== undefined) {
          rentableEquipment = {
            ...rentableEquipment,
            id: item.rentableEquipment.space.rentableEquipmentId,
          };
        }

        return {
          quantity: item.quantity,
          additionalNotes: item.additionalNotes,
          rentableEquipment: rentableEquipment,
          spaceId: item.rentableEquipment.space?.id,
        };
      });

      const newEvent: CreateApiEvent = {
        ...values,
        spaces: values.spaces.map((s) => s.id),
        isInternal: values.isInternal === "internal",
        eventGroup: values.eventGroup ? values.eventGroup.id : "",
        venue: newVenue,
        services: Object.values(values.services).map((s) => ({
          service: s.service.id,
          additionalNotes: s.additionalNotes,
          spaceId: s.spaceId,
        })),
        occurrences: occurrences,
        rentableEquipment: eventEquipment,
        eventContact: values.eventContact as ApiUserSummary,
      };

      return newEvent;
    },
    [occurrences]
  );

  const checkConflicts = useCallback(
    async (occurrences: CreateApiEventOccurrence[]) => {
      const response = await apiClient.checkEventConflicts(
        currentAccount.id,
        occurrences.map((x) => {
          return {
            start: x.start,
            end: x.end,
            venue: x.venue,
            spaces: x.spaces.map((s) => (typeof s === "string" ? s : s.id)),
          };
        })
      );
      const result = response.some((item) => item.hasConflict);
      setHasConflicts(result);
    },
    [apiClient, currentAccount.id]
  );

  const createEventAttachments = useCallback(
    (eventId: string) => {
      const attachmentsToCreate = attachemnts.filter(
        (attachment) => attachment.isNew === true
      );
      if (attachmentsToCreate.length > 0) {
        Promise.all(
          attachemnts.map(({ uploadId, name }) =>
            apiClient.createEventAttachment(currentAccount.id, eventId, {
              uploadId: uploadId || "",
              name,
            })
          )
        );
      }
    },
    [apiClient, currentAccount.id, attachemnts]
  );

  const createVenueEventResponses = useCallback(
    async (eventId: string) => {
      try {
        await Promise.all(
          newVenueQuestionResponses.map(async (response) => {
            if (!response.value) return false;

            const newResponse: CreateApiQuestionResponse = {
              questionId: response.question.id,
              eventId,
              venueId: response.venue.id,
              spaceId: null,
              serviceId: null,
              type: response.question.type,
              value: response.value,
            };
            const result = await apiClient.createQuestionResponse(
              currentAccount.id,
              newResponse
            );
            return result;
          })
        );
      } catch (e) {
        showToast("error", "There was an error creating the Venue Responses");
      }
    },
    [apiClient, currentAccount.id, newVenueQuestionResponses, showToast]
  );

  const createServiceEventResponses = useCallback(
    async (eventId: string) => {
      try {
        await Promise.all(
          newServiceQuestionResponses.map(async (response) => {
            if (!response.value) return false;

            const newResponse: CreateApiQuestionResponse = {
              questionId: response.question.id,
              eventId,
              venueId: null,
              spaceId: null,
              serviceId: response.service.id,
              type: response.question.type,
              value: response.value,
            };
            const result = await apiClient.createQuestionResponse(
              currentAccount.id,
              newResponse
            );
            return result;
          })
        );
      } catch (e) {
        showToast("error", "There was an error creating the Service Responses");
      }
    },
    [apiClient, currentAccount.id, newServiceQuestionResponses, showToast]
  );

  const createSpaceEventResponses = useCallback(
    async (eventId: string) => {
      try {
        await Promise.all(
          newSpaceQuestionResponses.map(async (response) => {
            if (!response.value) return false;

            const newResponse: CreateApiQuestionResponse = {
              questionId: response.question.id,
              eventId,
              spaceId: response.space.id,
              venueId: null,
              serviceId: null,
              type: response.question.type,
              value: response.value,
            };
            const result = await apiClient.createQuestionResponse(
              currentAccount.id,
              newResponse
            );
            return result;
          })
        );
      } catch (e) {
        showToast("error", "There was an error creating the Space Responses");
      }
    },
    [apiClient, currentAccount.id, newSpaceQuestionResponses, showToast]
  );

  const handleOnSubmit = useCallback(
    async (values: EventFormValues) => {
      const newEvent = mapNewEventValues(values);

      if (
        (!venueFieldsValid || !spacesFieldsValid || !servicesFieldsValid) &&
        ((values.venue && values.venue.questions.length > 0) ||
          values.spaces.some((s) => s.questions.length > 0) ||
          selectedServices.some((s) => s.questions.length > 0))
      ) {
        showToast("error", "Please, complete all the question required fields");
        return;
      }

      try {
        dispatch(setIsSubmitting(true));
        const res = await apiClient.createEvent(currentAccount.id, newEvent);
        createEventAttachments(res.id);
        await createVenueEventResponses(res.id);
        await createSpaceEventResponses(res.id);
        await createServiceEventResponses(res.id);

        if (isEventListOpen && res) {
          thunkDispatch(
            initEventList({
              accountId: currentAccount.id,
              apiClient,
            })
          );
        }
        const handleToastClick = () => {
          navigate(`/events/${res.key}`);
          toast.close("create-event");
        };

        toast({
          duration: 5000,
          id: "create-event",
          isClosable: true,
          position: "top",
          render: ({ onClose }: any) => {
            return (
              <NewEventToast
                event={res}
                onClose={onClose}
                onClick={handleToastClick}
              />
            );
          },
        });
        dispatch(setIsSubmitting(false));
        dispatch(
          setIsOpenEventModal({
            isOpen: false,
            workingEventId: "",
            modalContent: null,
          })
        );
        if (onClose) {
          onClose();
        }
      } catch (error) {
        showToast("error", "Something went wrong, please try again");
        if (axios.isAxiosError(error) && error.response?.status === 409) {
          const data = error.response.data.data as ApiEventBlock[];
          setBlockedVenueOrSpace(data);
          return;
        }
        dispatch(setIsSubmitting(false));
      }
    },
    [
      mapNewEventValues,
      venueFieldsValid,
      spacesFieldsValid,
      servicesFieldsValid,
      selectedServices,
      showToast,
      dispatch,
      apiClient,
      currentAccount.id,
      createEventAttachments,
      createVenueEventResponses,
      createSpaceEventResponses,
      createServiceEventResponses,
      isEventListOpen,
      toast,
      onClose,
      thunkDispatch,
      navigate,
    ]
  );

  const handleNewAttachments = useCallback(
    async (attachments: Attachment[]) => {
      const formatedAttachments = await Promise.all(
        attachments.map(async (item) => {
          const uploadFile = await apiClient.createUploadAndMoveAttachment(
            currentAccount.id,
            item
          );

          return { ...item, uploadId: uploadFile.id };
        })
      );
      setAttachments([...attachemnts, ...formatedAttachments]);
    },
    [apiClient, currentAccount.id, attachemnts]
  );

  const handleOnRemoveAttachment = useCallback(
    async (attachment: Attachment) => {
      const index = attachemnts.findIndex(
        (item) => item.uploadId === attachment.uploadId
      );
      if (index !== -1) {
        const insurancesCopy = attachemnts;
        await insurancesCopy.splice(index, 1);
        setAttachments([...insurancesCopy]);
      }
    },
    [attachemnts]
  );

  const handleOnChangeForm = useCallback(
    async (
      values: EventFormValues,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void,
      isSubmitting: boolean,
      handleSubmit: () => void
    ) => {
      dispatch(setFormikModal({ values, isSubmitting, handleSubmit }));

      // need to load venue details if not already loaded
      // Services and equipment are not loaded on venue summary
      // so we must fetch the full object
      if (values.venue === null) {
        setVenueDetail(undefined);
        return;
      }

      if (venueDetail && values.venue.id === venueDetail.id) {
        return;
      }

      setVenueDetail(undefined);
      const venue = await apiClient.getVenue(
        currentAccount.id,
        values.venue.id,
        { includeQuestions: true, excludeInactiveQuestions: true }
      );
      setVenueDetail(venue);
    },
    [dispatch, apiClient, currentAccount.id, venueDetail]
  );

  const handleEventGroupCreate = useCallback(
    (user: ApiUser) => {
      if (eventGroup) {
        apiClient
          .createEventGroupUsers(currentAccount.id, eventGroup.id, [user])
          .then(() => {
            showToast("success", "User saved succesfully in group");
          })
          .catch(() =>
            showToast(
              "error",
              "Something went wrong saving new User, please try again"
            )
          );
      }
    },
    [eventGroup, apiClient, currentAccount, showToast]
  );

  const handleOnClose = useCallback(() => {
    if (onClose) {
      onClose();
      return;
    }
    dispatch(
      setIsOpenEventModal({
        isOpen: false,
        workingEventId: "",
        modalContent: "form",
      })
    );
  }, [onClose, dispatch]);

  const totalEstimatedCost = useMemo(
    () => equipmentCost + servicesCost + spacesCost + eventCost,
    [equipmentCost, servicesCost, spacesCost, eventCost]
  );

  return (
    <Stack w="100%">
      {(event ? eventGroup : true) && (
        <Formik
          w="100%"
          onSubmit={handleOnSubmit}
          initialValues={initialValues}
          validationSchema={EventFormSchema}
        >
          {({ values, isSubmitting }) => {
            return (
              <Form>
                <FormikObserver cb={handleOnChangeForm} />
                <Stack gap={5} divider={<Divider />}>
                  <Stack gap={4}>
                    <Heading fontSize="lg">
                      Who is requesting this event?
                    </Heading>
                    <Box>
                      <RadioButtonsControl
                        name="isInternal"
                        value={values.isInternal}
                        cleanValues={[["eventGroup", null]]}
                        radioOptions={[
                          {
                            label: "Internal",
                            schemeColor: "blue",
                            value: "internal",
                          },
                          {
                            label: "External",
                            schemeColor: "blue",
                            value: "external",
                          },
                        ]}
                      />
                    </Box>
                    <EventGroupSelector
                      name="eventGroup"
                      value={values.eventGroup}
                      label=""
                      isEventInternal={values.isInternal === "internal"}
                      showExpiredInsurance
                      setEventCost={setEventCost}
                      fieldsToClean={[["eventContact", null]]}
                    />
                    <EventGroupUserAutocompleteControl
                      name="eventContact"
                      label="Event Contact"
                      value={values.eventContact}
                      eventGroupId={
                        values.eventGroup ? values.eventGroup.id : ""
                      }
                      showCreate
                      createOptions={{
                        isDisabled: !values.eventGroup,
                        userType: CreateUserType.contact,
                        formType: "inlineForm",
                        onCreate: (user) => handleEventGroupCreate(user),
                      }}
                      isDisabled={!values.eventGroup}
                    />
                  </Stack>
                  <Stack gap={4}>
                    <Heading fontSize="lg">Tell us about your event</Heading>
                    <TextInputControl
                      name="name"
                      label="Name this event"
                      value={values.name}
                      onlyChangeOnBlur
                      defaultValue={event ? event.name : undefined}
                    />
                    <TextEditorControl
                      name="description"
                      value={values.description}
                      label="Summary or Description (Optional)"
                    />
                  </Stack>
                  <Stack gap={4}>
                    <VenueSpacesSection
                      firstOccurrence={occurrences[0]}
                      setSpacesCost={setSpacesCost}
                      occurrences={occurrences}
                    />
                  </Stack>
                  <Stack
                    w={[
                      "100%",
                      "100%",
                      `${attachemnts.length ? "100%" : "80%"}`,
                    ]}
                  >
                    <Attachments
                      title="Upload Attachments"
                      attachments={attachemnts}
                      addButtonLabel="Upload Files"
                      onNewAttachments={handleNewAttachments}
                      onDeleteAttachment={handleOnRemoveAttachment}
                    />
                  </Stack>
                  <Stack gap={4}>
                    <Heading fontSize="lg">When</Heading>
                    <CheckboxControl
                      name="isPublic"
                      value={values.isPublic}
                      labelFontWeight="500"
                      label="Show in the public calendar?"
                    />
                    <ScheduledEvents
                      occurrences={occurrences}
                      setOcurrences={setOcurrences}
                      selectedVenue={values.venue}
                      selectedSpaces={values.spaces}
                      checkConflicts={checkConflicts}
                    />
                    {hasConflicts && <ConflictAlert boxProps={{ mt: 6 }} />}
                  </Stack>

                  <Stack gap={4}>
                    <Heading fontSize="lg">
                      How many people will attend?
                    </Heading>
                    <Box maxW={["50%", null, "25%"]}>
                      <NumberInputControl
                        min={0}
                        showStepper
                        name="attendees"
                        value={values.attendees}
                      />
                    </Box>
                  </Stack>
                  {values.venue && venueDetail && occurrences.length && (
                    <Stack gap={4}>
                      <Heading fontSize="lg">
                        Will you need any services?
                      </Heading>
                      <ServicesMulticheck
                        venue={venueDetail}
                        setServicesCost={setServicesCost}
                        firstOccurrence={occurrences[0]}
                        occurrences={occurrences}
                        setServicesSummary={setSelectedServices}
                      />
                    </Stack>
                  )}

                  {(values.venue?.questions ||
                    values.spaces.some((s) => s.questions) ||
                    selectedServices.some((s) => s.questions)) && (
                    <Stack gap={4} width="100%">
                      <Heading
                        fontSize="lg"
                        display="flex"
                        w="max-content"
                        cursor="pointer"
                        onClick={onToggle}
                        alignItems="center"
                      >
                        Questions
                        <Icon as={isOpen ? MdArrowDropUp : MdArrowDropDown} />
                      </Heading>
                      {values.venue &&
                        values.venue.questions &&
                        values.venue.questions.length > 0 && (
                          <Collapse
                            in={isOpen}
                            unmountOnExit
                            key={`venueQuestion::${values.venue.id}`}
                          >
                            <Heading fontSize="lg" marginBottom={4}>
                              {values.venue?.name}
                            </Heading>
                            <VenueQuestionsSection
                              venue={values.venue}
                              setResponses={setNewVenueQuestionResponses}
                              setQuestionsFieldsValid={setVenueFieldsValid}
                            />
                          </Collapse>
                        )}
                      {values.spaces && (
                        <Collapse in={isOpen} unmountOnExit>
                          {values.spaces.map((space) => {
                            if (space.questions && space.questions.length > 0) {
                              return (
                                <Box key={`spacesSection::${space.id}`}>
                                  <Heading fontSize="lg" marginBottom={4}>
                                    {space.location.name}
                                  </Heading>
                                  <SpaceQuestionsSection
                                    space={space}
                                    setResponses={setNewSpaceQuestionResponses}
                                    setQuestionsFieldsValid={
                                      setSpacesFieldsValid
                                    }
                                  />
                                </Box>
                              );
                            }
                            return <></>;
                          })}
                        </Collapse>
                      )}

                      <Collapse in={isOpen} unmountOnExit>
                        {selectedServices.map((service) => {
                          return (
                            <Fragment key={`questionservice::${service.id}`}>
                              {service.questions &&
                                service.questions.length > 0 && (
                                  <>
                                    <Heading fontSize="lg" marginBottom={4}>
                                      {service.name}
                                    </Heading>
                                    <ServiceQuestionsSection
                                      service={service}
                                      setResponses={
                                        setNewServiceQuestionResponses
                                      }
                                      setQuestionsFieldsValid={
                                        setServicesFieldsValid
                                      }
                                    />
                                  </>
                                )}
                            </Fragment>
                          );
                        })}
                      </Collapse>
                    </Stack>
                  )}

                  {values.venue && venueDetail && occurrences.length && (
                    <Stack gap={4}>
                      <EventEquipmentSection
                        venue={venueDetail}
                        setEquipmentCost={setEquipmentCost}
                        firstOccurrence={occurrences[0]}
                        occurrences={occurrences}
                      />
                    </Stack>
                  )}
                  {totalEstimatedCost &&
                    enableInvoicesForEvents &&
                    values.eventGroup?.rateGroup?.generateInvoices && (
                      <Stack>
                        {eventCost && (
                          <Text textAlign="right">
                            Event Fee: {formatCurrency(eventCost)}
                          </Text>
                        )}
                        <Heading fontSize="xl" textAlign="right">
                          {`Estimated Total Cost: ${formatCurrency(
                            totalEstimatedCost
                          )}`}
                        </Heading>
                      </Stack>
                    )}

                  {blockedVenueOrSpace && blockedVenueOrSpace.length > 0 && (
                    <BlockedVenueOrSpacesAlert data={blockedVenueOrSpace} />
                  )}
                  {showSubmitButton && (
                    <HStack justifyContent="space-between">
                      <Button
                        mr={10}
                        variant="ghost"
                        colorScheme="blue"
                        onClick={handleOnClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        minW="200px"
                        type="submit"
                        colorScheme="blue"
                        isLoading={isSubmitting}
                        disabled={values.rentableEquipment.some(
                          (item) => item.error
                        )}
                      >
                        Submit
                      </Button>
                    </HStack>
                  )}
                </Stack>
              </Form>
            );
          }}
        </Formik>
      )}
    </Stack>
  );
};
