import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiLocation } from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import React, { LegacyRef, useCallback, useMemo, useState } from "react";
import Select, {
  SelectMethods,
  SelectProps,
  SelectRenderer,
  SelectState,
} from "react-dropdown-select";
import type { ListRowProps } from "react-virtualized";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import { Products } from "../../pages/account-settings/location-list/LocationList";
import { useLocationUtils } from "../../utils/locationUtils";
import { AdditionalNumberBadge } from "../badges/AdditionalNumberBadge";
import { LocationTwoLine } from "../badges/LocationTwoLine";

export interface LocationFilterValue {
  value: ApiLocation[];
  lastChecked?: string;
  clearSelectAll: boolean;
}

export interface LocationFilterProps {
  value: (ApiLocation | string)[];
  onChange: (values: LocationFilterValue) => void;
  allowedLocations?: ApiLocation[];
  productName?: Products;
}

interface VirtualizedListProps {
  props: SelectProps<ApiLocation>;
  methods: SelectMethods<ApiLocation>;
  setLastChecked: React.Dispatch<React.SetStateAction<string | undefined>>;
  state: SelectState<ApiLocation>;
}

const VirtualizedList = ({
  props,
  methods,
  setLastChecked,
  state,
}: VirtualizedListProps) => {
  const rows = state.search
    ? //@ts-ignore
      state.searchResults
    : props.options;

  const cellCache = useMemo(() => {
    return new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 30,
    });
  }, []);

  const rowRenderer = ({ key, parent, index, style }: ListRowProps) => {
    const child = rows[index];
    const uniqueKey = `${key}::${index}`;
    const adjustedStyle = {
      ...style,
      display: "flex",
      justifyContent: "center",
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingLeft: "0",
      paddingRight: "0",
    };
    return (
      <CellMeasurer
        cache={cellCache}
        key={uniqueKey}
        columnIndex={0}
        rowIndex={index}
        parent={parent}
      >
        {({ registerChild }) => (
          //https://github.com/bvaughn/react-virtualized/issues/1572
          <Box
            key={uniqueKey}
            ref={registerChild as LegacyRef<HTMLDivElement> | undefined}
            style={adjustedStyle}
          >
            <Checkbox
              key={child.id}
              isChecked={props.values
                .flatMap((location: ApiLocation) => location.id)
                .includes(child.id)}
              onChange={() => {
                methods.addItem(child);
                setLastChecked(child.id);
              }}
              w="100%"
              p={2}
            >
              <LocationTwoLine value={child} />
            </Checkbox>
          </Box>
        )}
      </CellMeasurer>
    );
  };

  return (
    <Box style={{ height: "250px" }}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            deferredMeasurementCache={cellCache}
            rowHeight={cellCache.rowHeight}
            rowCount={rows.length}
            rowRenderer={rowRenderer}
            containerStyle={{
              overflow: "visible",
            }}
          />
        )}
      </AutoSizer>
    </Box>
  );
};
const ContentRenderer = ({ props }: SelectRenderer<ApiLocation>) => {
  const borderColor = useColorModeValue(undefined, "gray.700");
  return (
    <Box p={1} borderColor={borderColor}>
      {props.values.length === 0 && "Location"}
      {props.values.length === 1 && (
        <Box maxW={"200px"}>
          <LocationTwoLine value={props.values[0]} displayAncestors={false} />
        </Box>
      )}
      {props.values.length > 1 && (
        <>
          <Text mr={1} as="span">
            {props.values[0].name}
          </Text>
          <AdditionalNumberBadge number={props.values.length - 1} />
        </>
      )}
    </Box>
  );
};

const DropdownRenderer = ({
  props,
  methods,
  state,
  setLastChecked,
  setClearSelectAll,
}: SelectRenderer<ApiLocation> & {
  setLastChecked: React.Dispatch<React.SetStateAction<string | undefined>>;
  setClearSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const handleClearAll = useCallback(() => {
    setClearSelectAll(true);
    methods.clearAll();
  }, [methods, setClearSelectAll]);
  const handleSelectAll = useCallback(() => {
    setClearSelectAll(true);
    methods.selectAll();
  }, [methods, setClearSelectAll]);

  return (
    <Box p={2}>
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <Text>Search and select:</Text>
          {methods.areAllSelected() ? (
            <Button size="sm" variant="outline" onClick={handleClearAll}>
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={handleSelectAll}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search Locations"
        />
      </Box>
      <Box>
        <VirtualizedList
          props={props}
          methods={methods}
          setLastChecked={setLastChecked}
          state={state}
        />
      </Box>
    </Box>
  );
};

export const LocationFilter = ({
  value,
  onChange,
  allowedLocations,
  productName,
}: LocationFilterProps) => {
  const [lastChecked, setLastChecked] = useState<string | undefined>();
  const [clearSelectAll, setClearSelectAll] = useState<boolean>(false);
  const { findAllChildrenForNodes, locationHash } =
    useLocationUtils(allowedLocations);

  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const locations = useMemo(() => {
    return allowedLocations?.filter((loc) => {
      if (loc.active === true) {
        if (productName && loc.hiddenProducts.some((p) => p === productName)) {
          return null;
        }
        return loc;
      }
      return null;
    });
  }, [allowedLocations, productName]);

  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(locations!, ["name", "address"], {
        sort: true,
      }),
    [locations]
  );

  const searchFn = useCallback(
    ({ props, state, methods }: SelectRenderer<ApiLocation>) => {
      if (!state.search) {
        return locations!;
      }

      const found = fuzzySearch.search(state.search);
      return findAllChildrenForNodes(found);
    },
    [locations, fuzzySearch, findAllChildrenForNodes]
  );
  const handleOnChange = useCallback(
    (value: ApiLocation[]) => {
      onChange({
        value,
        lastChecked,
        clearSelectAll: value.length === 0 ? true : clearSelectAll,
      });
      setClearSelectAll(false);
    },
    [clearSelectAll, lastChecked, onChange]
  );

  return (
    <Select
      multi
      options={locations!}
      className={themeClass}
      values={value.map((v) => (typeof v === "string" ? locationHash[v] : v))}
      onChange={(value) => {
        handleOnChange(value);
      }}
      searchable={true}
      searchBy="name"
      valueField="id"
      labelField="name"
      dropdownGap={0}
      keepSelectedInList
      //clearable
      contentRenderer={ContentRenderer}
      dropdownRenderer={(props) => (
        <DropdownRenderer
          {...props}
          setLastChecked={setLastChecked}
          setClearSelectAll={setClearSelectAll}
        />
      )}
      dropdownHeight="350px"
      searchFn={searchFn}
    />
  );
};
