import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  ApiRequestsQueryFilter,
  ApiScheduledRequest,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useState } from "react";
import { GiAlarmClock } from "react-icons/gi";
import { MdAutoDelete, MdModeEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { DateBadge } from "../../../components/badges/DateBadge";
import { LocationTwoLine } from "../../../components/badges/LocationTwoLine";
import { Pager } from "../../../components/pager/Pager";
import { RootState, useThunkDispatch } from "../../../store";
import {
  deactivateScheduledRequest,
  reactivateScheduledRequest,
} from "../../../store/schedule-request-list.slice";
import { RequestInfo } from "../../calendar/requests/RequestInfo";
import { RequestModalHeader } from "../../calendar/requests/RequestInfoHeader";
import { UserColumn } from "../../requests/request-row/UserColumn";
import { AccountModal } from "../account-modal/AccountModal";
import { ModalScheduleDate } from "./ModalScheduleDates";

interface ScheduleDataProps {
  handleOnChangePage: (page: number) => void;
  openScheduledRequestForm: (scheduledRequestId: string) => void;
}

export const SchedulesData: FC<ScheduleDataProps> = ({
  handleOnChangePage,
  openScheduledRequestForm,
}) => {
  const { scheduleRequestList } = useSelector((state: RootState) => state);
  const { requestsMap, total, data, filters } = scheduleRequestList;
  const isDesktop = useBreakpointValue({
    base: false,
    sm: false,
    md: false,
    lg: true,
  });

  const stackItemBgColor = useColorModeValue("gray.50", "whiteAlpha.200");
  const removeIconColor = useColorModeValue("red.600", "red.400");
  const editButtonBgColor = useColorModeValue("blue.500", "whiteAlpha.300");
  const deactivateButtonColor = useColorModeValue("red.500", "white");
  const dateColor = useColorModeValue("blue.500", "blue.300");
  const toast = useToast();

  const { currentAccount, apiClient } = useAuthentication();
  const thunkDispatch = useThunkDispatch();

  const [workingScheduledRequest, setWorkingScheduledRequest] =
    useState<ApiScheduledRequest | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenRequest,
    onOpen: onOpenRequest,
    onClose: onCloseRequest,
  } = useDisclosure();
  const [scheduledRequestToRun, setScheduledRequestToRun] =
    useState<ApiScheduledRequest | null>(null);
  const [reqKey, setReqKey] = useState<string>("");

  const filterCallback = useCallback((scheduledRequestId: string) => {
    const filter = {
      moreFilters: ["schedules"],
      scheduledRequest: [scheduledRequestId],
      quickFilter: ApiRequestsQueryFilter.ALL,
      sort: "created",
      direction: "desc",
    };
    const queryStringFilter = btoa(JSON.stringify(filter));
    const url = `/requests?filter=${queryStringFilter}`;
    return url;
  }, []);

  const Deactivate = useCallback(
    (scheduledRequestId: string) => {
      thunkDispatch(
        deactivateScheduledRequest({
          accountId: currentAccount.id,
          apiClient: apiClient,
          scheduledRequestId: scheduledRequestId,
        })
      );
    },
    [apiClient, currentAccount.id, thunkDispatch]
  );

  const Reactivate = useCallback(
    (scheduledRequestId: string) => {
      thunkDispatch(
        reactivateScheduledRequest({
          accountId: currentAccount.id,
          apiClient: apiClient,
          scheduledRequestId: scheduledRequestId,
        })
      );
    },
    [apiClient, currentAccount.id, thunkDispatch]
  );

  const handleClickActivateReactivate = useCallback(
    (scheduledRequestId: string, isActive: boolean) => {
      isActive
        ? Deactivate(scheduledRequestId)
        : Reactivate(scheduledRequestId);
    },
    [Deactivate, Reactivate]
  );
  const handleRequestOnDemand = useCallback(
    (scheduledRequest: ApiScheduledRequest) => {
      const scheduledRequestId = scheduledRequest.id;
      setScheduledRequestToRun(null);
      apiClient
        .createRequestOnDemand(currentAccount.id, scheduledRequestId)
        .then((res) => {
          toast({
            duration: 1500,
            position: "top",
            isClosable: true,
            status: "success",
            title: "Schedule submitted, it will run momentarily",
          });
        })
        .catch(() => {
          toast({
            duration: 1500,
            isClosable: true,
            position: "top",
            status: "error",
            title: "Error running schedule.",
          });
        });
    },
    [apiClient, currentAccount.id, toast]
  );
  const handleNextRunClick = useCallback(
    (scheduledRequest: ApiScheduledRequest) => {
      setWorkingScheduledRequest(scheduledRequest);
      onOpen();
    },
    [onOpen]
  );

  const handleOnOpenRequestModal = useCallback(
    (reqKey: string | null) => {
      if (!reqKey) return;
      setReqKey(reqKey);
      onOpenRequest();
    },
    [setReqKey, onOpenRequest]
  );

  return (
    <>
      {isDesktop && <ScheduleListHeader />}
      <ModalScheduleDate
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        scheduledRequest={workingScheduledRequest}
      />
      {data.map((scheduledRequest, index) => {
        return (
          <Stack
            spacing={0}
            flexWrap="wrap"
            flexDir="row"
            key={scheduledRequest.id}
            mt={index === 0 ? 4 : 0}
            p={isDesktop ? 4 : 2}
            bgColor={index % 2 !== 0 ? stackItemBgColor : "transparent"}
          >
            <StackItem
              p={2}
              maxW={["90%", "90%", "90%", "20%"]}
              minW={["90%", "90%", "90%", "20%"]}
            >
              <Text fontWeight="bold">{scheduledRequest.name}</Text>

              <>
                {scheduledRequest.lastRequestKey && (
                  <Link
                    onClick={() =>
                      handleOnOpenRequestModal(scheduledRequest.lastRequestKey)
                    }
                    justifySelf="flex-start"
                  >
                    <Text>{scheduledRequest.lastRequestKey}</Text>
                  </Link>
                )}
                <Flex>
                  {scheduledRequest.requestsGenerated > 0 && (
                    <Link
                      as={RouterLink}
                      to={filterCallback(scheduledRequest.id)}
                      onClick={(e) => e.stopPropagation()}
                      textColor="blue.600"
                      fontWeight="bold"
                      justifySelf="flex-start"
                    >
                      See All {scheduledRequest.requestsGenerated} Requests
                    </Link>
                  )}
                  {!scheduledRequest.active && (
                    <Badge colorScheme="gray" p={1}>
                      Inactive
                    </Badge>
                  )}
                </Flex>
              </>

              {!isDesktop && (
                <Box w="100%" fontWeight="500" mt={4}>
                  <Text isTruncated={true}>
                    {scheduledRequest.reason ? (
                      scheduledRequest.reason.name
                    ) : (
                      <i>no reason</i>
                    )}
                    {", "}
                    {scheduledRequest.reportingCategory ? (
                      scheduledRequest.reportingCategory.name
                    ) : (
                      <i>no category</i>
                    )}
                  </Text>
                  <UserColumn assignees={scheduledRequest.assignees} />
                </Box>
              )}
            </StackItem>

            {isDesktop && (
              <>
                <StackItem w="15%" p={2}>
                  <UserColumn assignees={scheduledRequest.assignees} />
                </StackItem>

                <StackItem w="15%" p={2}>
                  {scheduledRequest.reportingCategory ? (
                    <Text>{scheduledRequest.reportingCategory.name} / </Text>
                  ) : (
                    <i>no category / </i>
                  )}
                  {scheduledRequest.reason ? (
                    scheduledRequest.reason.name
                  ) : (
                    <i>no reason</i>
                  )}
                </StackItem>

                <StackItem w="20%" p={2}>
                  <Flex>
                    <LocationTwoLine value={scheduledRequest.location} />
                    {/* <Icon
                      as={LocationTypeIconMap[scheduledRequest.location.type]}
                      mr={1}
                      mt={0.25}
                    />
                    <Text>{scheduledRequest.location.name}</Text> */}
                  </Flex>
                </StackItem>
                <StackItem w="10%" p={2}>
                  <Box
                    cursor="pointer"
                    onClick={() => handleNextRunClick(scheduledRequest)}
                  >
                    <DateBadge
                      rest={{ as: "u", textColor: dateColor }}
                      date={scheduledRequest.schedules[0]?.nextRuns.find(
                        (nextRun) => new Date(nextRun) > new Date()
                      )}
                    />
                  </Box>
                </StackItem>
              </>
            )}

            <StackItem
              p={2}
              display="flex"
              justifyContent="flex-end"
              w={["10%", "10%", "10%", "20%"]}
              flexDir={isDesktop ? "row" : "column"}
            >
              <Box
                display="flex"
                flex={1}
                flexDir="row"
                flexWrap="wrap"
                mt={[0, 0, 2, 2, 0]}
                order={[4, 4, 4, 1]}
                w={["100%", "100%", "20%", "100%"]}
                justifyContent={[
                  "space-between",
                  "space-between",
                  "flex-start",
                ]}
              >
                {isDesktop ? (
                  <>
                    {
                      <Button
                        mr={2}
                        size="xs"
                        borderColor={editButtonBgColor}
                        variant={scheduledRequest.active ? "outline" : "solid"}
                        _hover={{ opacity: 1 }}
                        color={
                          scheduledRequest.active
                            ? deactivateButtonColor
                            : "white"
                        }
                        bgColor={
                          scheduledRequest.active
                            ? "transparent"
                            : editButtonBgColor
                        }
                        onClick={() =>
                          handleClickActivateReactivate(
                            scheduledRequest.id,
                            scheduledRequest.active
                          )
                        }
                      >
                        {scheduledRequest.active ? "Deactivate" : "Reactivate"}
                      </Button>
                    }
                    <Button
                      mr={2}
                      size="xs"
                      minW="40px"
                      color="white"
                      _hover={{ opacity: 1 }}
                      bgColor={editButtonBgColor}
                      onClick={() =>
                        openScheduledRequestForm(scheduledRequest.id)
                      }
                    >
                      Edit
                    </Button>
                    {scheduledRequest.workflow.active && (
                      <Button
                        size="xs"
                        minW="40px"
                        color="white"
                        _hover={{ opacity: 1 }}
                        bgColor={editButtonBgColor}
                        onClick={() =>
                          setScheduledRequestToRun(scheduledRequest)
                        }
                      >
                        Run Now
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <StackItem
                      minH="30%"
                      alignSelf="flex-end"
                      display="flex"
                      alignItems="flex-end"
                    >
                      <Icon
                        ml={1}
                        boxSize="6"
                        cursor="pointer"
                        color={removeIconColor}
                        as={MdAutoDelete}
                        onClick={() =>
                          handleClickActivateReactivate(
                            scheduledRequest.id,
                            scheduledRequest.active
                          )
                        }
                      />
                    </StackItem>
                    <StackItem
                      minH="35%"
                      alignSelf="flex-end"
                      display="flex"
                      alignItems="flex-end"
                    >
                      <Icon
                        ml={1}
                        boxSize="6"
                        cursor="pointer"
                        color="blue.500"
                        as={MdModeEdit}
                        onClick={() =>
                          openScheduledRequestForm(scheduledRequest.id)
                        }
                      />
                    </StackItem>
                    {scheduledRequest.workflow.active && (
                      <StackItem
                        minH="35%"
                        alignSelf="flex-end"
                        display="flex"
                        alignItems="flex-end"
                      >
                        <Icon
                          ml={1}
                          boxSize="6"
                          cursor="pointer"
                          color="blue.500"
                          as={GiAlarmClock}
                          onClick={() =>
                            setScheduledRequestToRun(scheduledRequest)
                          }
                        />
                      </StackItem>
                    )}
                  </>
                )}
              </Box>
            </StackItem>
          </Stack>
        );
      })}

      {scheduledRequestToRun && (
        <Modal
          isOpen={Boolean(scheduledRequestToRun)}
          onClose={() => setScheduledRequestToRun(null)}
          closeOnEsc={true}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Generate Schedule Now</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Are you sure you want to run now?</Text>
            </ModalBody>
            <ModalFooter>
              <Flex justifyContent="space-between" w="100%">
                <Button
                  size="sm"
                  onClick={() => setScheduledRequestToRun(null)}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  colorScheme="green"
                  onClick={() => handleRequestOnDemand(scheduledRequestToRun)}
                  px={8}
                >
                  Yes
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      <Divider mt={8} mb={6} />

      <Pager
        onPageChange={handleOnChangePage}
        total={total}
        currentPage={filters.page}
        pageSize={filters.pageSize}
        justifyContent={isDesktop ? "flex-end" : "center"}
        showDetails={isDesktop ? true : false}
      />
      {isOpenRequest && requestsMap[reqKey] && (
        <AccountModal
          content={
            <RequestInfo
              request={requestsMap[reqKey]}
              onCloseModal={onCloseRequest}
            />
          }
          title={<RequestModalHeader request={requestsMap[reqKey]} />}
          contentProps={{ maxW: "xl" }}
          onClose={onCloseRequest}
          isOpen={isOpenRequest}
          closeButton={false}
        />
      )}
    </>
  );
};

const ScheduleListHeader: FC = () => {
  const headerBackground = useColorModeValue("gray.100", "whiteAlpha.200");
  return (
    <Stack
      p={4}
      mt={4}
      h="60px"
      align="center"
      direction="row"
      color="gray.500"
      fontWeight={700}
      borderTopRadius={8}
      bgColor={headerBackground}
    >
      <Box w="20%" p={2}>
        Name
      </Box>
      <Box w="15%" p={2}>
        Assignees
      </Box>
      <Box w="15%" p={2}>
        Category / Reason
      </Box>
      <Box w="20%" p={2}>
        Location
      </Box>
      <Box w="10%" p={2}>
        Next Run
      </Box>
      <Box w="20%" textAlign="right" p={2}>
        Status/Update
      </Box>
    </Stack>
  );
};
