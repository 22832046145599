import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
} from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { MdQrCode2 } from "react-icons/md";
import { QrQuickScanModal } from "../qr-quick-scan/QrQuickScanModal";

export interface QrTextInputControlProps {
  label?: string;
  name: string;
  value: string | null;
  placeholder?: string;
  helperText?: string;
  prefix?: string;
  autoFocus?: boolean;
  autoComplete?: InputProps["autoComplete"];
  labelFontWeight?: string;
  isDisabled?: boolean;
}

export const QrTextInputControl = ({
  name,
  value,
  placeholder,
  label,
  helperText,
  autoFocus,
  autoComplete = "off",
  isDisabled,
}: QrTextInputControlProps) => {
  const { submitCount } = useFormikContext();
  const [field, meta, helpers] = useField({
    name,
    value: value === null ? undefined : value,
    placeholder,
  });
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleQrData = useCallback(
    (data: string) => {
      helpers.setValue(data);
      onClose();
    },
    [helpers, onClose]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InputGroup>
        <Input
          {...field}
          value={field.value || ""}
          name={name}
          placeholder={placeholder}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          isDisabled={isDisabled}
        />
        <InputRightElement zIndex={0} width="4.5rem">
          <Button h="1.75rem" p={1} size="sm" onClick={onOpen}>
            <Icon h={7} w={7} as={MdQrCode2} />
          </Button>
        </InputRightElement>
      </InputGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
      {isOpen && (
        <QrQuickScanModal
          isOpen={isOpen}
          onClose={onClose}
          onData={handleQrData}
        ></QrQuickScanModal>
      )}
    </FormControl>
  );
};
