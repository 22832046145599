import {
  Box,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { ApiRequestsQueryFilter } from "@operations-hero/lib-api-client";
import { useCallback, useMemo } from "react";
import { BiCheck } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  setRequestListTitle,
  updateRequestFilters,
} from "../../../store/request-list.slice";
import { styleClicked, styleNotClicked } from "../../../utils/buttonsStyle";

type QuickFiltersType = `${ApiRequestsQueryFilter}`;

export const QUICK_FILTERS: Record<QuickFiltersType, string> = {
  all: "All Requests",
  "my-requests": "My Requests",
  "assigned-to-me": "Assigned to Me",
  "routed-requests": "Routing Requests",
  overdue: "Overdue",
  "recently-completed-closed": "Recently Completed",
  "needs-review": "Needs Review",
  "overdue-upcoming": "Overdue-Upcoming",
  "recently-created": "Recently Created",
  "recently-closed": "Recently Closed",
};

export type QuickFilterKeys = keyof typeof QUICK_FILTERS;

export const RequestListViewSelector = () => {
  const dispatch = useDispatch();

  const isMobileMode = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  const isDesktop = useMemo(
    () => (!isMobileMode && !isTablet ? true : false),
    [isMobileMode, isTablet]
  );

  const quickFilter = useSelector(
    (state: RootState) => state.requestList.filters.quickFilter
  );

  const { isApprover, isContractor } = useSelector(
    (state: RootState) => state.localCache
  );

  const handleQuickFilterChange = useCallback(
    (value: ApiRequestsQueryFilter) => {
      dispatch(updateRequestFilters({ quickFilter: value }));
      dispatch(setRequestListTitle(QUICK_FILTERS[value]));
    },
    [dispatch]
  );

  const buttonBgColor = useColorModeValue("white", "whiteAlpha.300");
  const buttonColor = useColorModeValue("blue.500", "white");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isClicked = useCallback(
    (value: string) => {
      return value === quickFilter ? true : false;
    },
    [quickFilter]
  );

  const button = useCallback(
    (value: ApiRequestsQueryFilter, label: string) => (
      <Button
        onClick={() => handleQuickFilterChange(value)}
        sx={
          quickFilter === value
            ? styleClicked()
            : styleNotClicked(buttonColor, buttonBgColor)
        }
        size={"sm"}
      >
        {isClicked(value) ? <Icon as={BiCheck} mr={2} /> : null}
        {label}
      </Button>
    ),
    [
      buttonBgColor,
      buttonColor,
      quickFilter,
      handleQuickFilterChange,
      isClicked,
    ]
  );
  const filtersToExclude = [
    ApiRequestsQueryFilter.RECENTLY_CREATED,
    ApiRequestsQueryFilter.OVERDUE_UPCOMING,
    ApiRequestsQueryFilter.RECENTLY_CLOSED,
  ];
  return (
    <Box>
      <Wrap>
        {(Object.entries(QUICK_FILTERS) as [ApiRequestsQueryFilter, string][])
          .filter((filter) => !filtersToExclude.includes(filter[0]))
          .map(([value, label], index) => (
            <WrapItem key={value}>
              {isApprover
                ? value === ApiRequestsQueryFilter.ALL ||
                  value === ApiRequestsQueryFilter.MY_REQUESTS ||
                  value === ApiRequestsQueryFilter.ROUTED_REQUESTS
                  ? button(value, label)
                  : null
                : isContractor
                ? value === ApiRequestsQueryFilter.MY_REQUESTS ||
                  value === ApiRequestsQueryFilter.ASSIGNED_TO_ME ||
                  value === ApiRequestsQueryFilter.RECENTLY_COMPLETED_CLOSED
                  ? button(value, label)
                  : null
                : isDesktop
                ? index <= 3
                  ? button(value, label)
                  : null
                : button(value, label)}
            </WrapItem>
          ))}
        {!isApprover && !isContractor && isDesktop && (
          <WrapItem>
            <Button
              sx={styleNotClicked(buttonColor, buttonBgColor)}
              onClick={onOpen}
              size={"sm"}
            >
              See all views
            </Button>
          </WrapItem>
        )}
      </Wrap>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select view</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Wrap>
              {(
                Object.entries(QUICK_FILTERS) as [
                  ApiRequestsQueryFilter,
                  string
                ][]
              )
                .filter((filter) => !filtersToExclude.includes(filter[0]))
                .map(([value, label], index) => (
                  <WrapItem key={value}>{button(value, label)}</WrapItem>
                ))}
            </Wrap>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
