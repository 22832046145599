import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { useCallback } from "react";
import {
  StyledDatePicker,
  StyledDatePickerProps,
} from "../inputs/StyledDatePicker";

type DatePickerControlProps = Omit<StyledDatePickerProps, "onChange"> & {
  label: string;
  name: string;
  labelFontWeight?: string;
  showTime?: boolean;
  showTimeOnly?: boolean;
  format?: string;
  className?: string;
  hasError?: boolean;
  labelProps?: FormLabelProps;
  showCustomLocaleInput?: boolean;
  customLocaleInputOpts?: Intl.DateTimeFormatOptions;
};

export const DatePickerControl = ({
  name,
  label,
  value,
  placeholder,
  showTime,
  isDisabled,
  showTimeOnly,
  format,
  className,
  hasError,
  labelProps,
  showCustomLocaleInput,
  customLocaleInputOpts,
}: DatePickerControlProps) => {
  const { submitCount } = useFormikContext();
  const [field, meta, helpers] = useField({
    name,
    value: value ? new Date(value).toISOString() : undefined,
    placeholder,
  });

  const handleOnChange = useCallback(
    (date: Date | null) => {
      helpers.setTouched(true);
      helpers.setValue(date ? date.toISOString() : null);
    },
    [helpers]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name} {...labelProps}>
        {label}
      </FormLabel>
      <StyledDatePicker
        placeholder={placeholder}
        showTime={showTime}
        showTimeOnly={showTimeOnly}
        value={field.value}
        onChange={handleOnChange}
        name={name}
        isDisabled={isDisabled}
        format={format}
        hasError={hasError}
        className={className}
        showCustomLocaleInput={showCustomLocaleInput}
        customLocaleInputOpts={customLocaleInputOpts}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
