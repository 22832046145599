import { ApiAsset, ApiWorkflowField } from "@operations-hero/lib-api-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Attachment } from "../components/attachments/Attachments";

export interface NewRequestSliceState {
  attachments: Attachment[];
  assets: ApiAsset[];
  reportingCategoryId: string | null;
  locationId: string | null;
  newRequestDialogIsOpen: boolean;
  source: string | null;
  visibleSchemaFields: ApiWorkflowField[];
}

export const newRequestSlice = createSlice({
  name: "newRequest",
  initialState: {
    attachments: [],
    assets: [],
    newRequestDialogIsOpen: false,
    source: null,
    reportingCategoryId: null,
    locationId: null,
    visibleSchemaFields: [],
  } as NewRequestSliceState,
  reducers: {
    setNewRequestDialogIsOpen: (
      state,
      action: PayloadAction<boolean | { isOpen: boolean; source: string }>
    ) => {
      const { payload } = action;
      if (typeof payload === "boolean") state.newRequestDialogIsOpen = payload;
      else {
        const { isOpen, source } = payload;
        state.newRequestDialogIsOpen = isOpen;
        state.source = source;
      }
    },
    addAsset: (state, action: PayloadAction<ApiAsset>) => {
      if (state.assets.some((x) => x.id === action.payload.id)) {
        return;
      }
      state.assets.push(action.payload);
    },
    removeAsset: (state, action: PayloadAction<ApiAsset>) => {
      const index = state.assets.findIndex((x) => x.id === action.payload.id);
      state.assets.splice(index, 1);
    },
    addAttachment: (state, action: PayloadAction<Attachment>) => {
      if (state.attachments.some((x) => x.name === action.payload.name)) {
        return;
      }
      state.attachments.push(action.payload);
    },
    removeAttachment: (state, action: PayloadAction<Attachment>) => {
      const index = state.attachments.findIndex(
        (x) => x.isNew === true && x.name === action.payload.name
      );

      state.attachments.splice(index, 1);
    },
    updateAttachment: (state, action: PayloadAction<Attachment>) => {
      const index = state.attachments.findIndex(
        (x) => x.isNew === true && x.name === action.payload.name
      );

      state.attachments[index] = action.payload;
    },
    setReportingCategoryId: (state, action: PayloadAction<string>) => {
      state.reportingCategoryId = action.payload;
    },
    setLocationId: (state, action: PayloadAction<string>) => {
      state.locationId = action.payload;
    },
    unloadForm: (state) => {
      state.attachments = [];
      state.assets = [];
      state.reportingCategoryId = null;
      state.locationId = null;
      state.visibleSchemaFields = [];
      state.source = null;
    },
    unloadAssets: (state) => {
      state.assets = [];
    },
    setVisibleSchemaFields: (
      state,
      action: PayloadAction<ApiWorkflowField[]>
    ) => {
      state.visibleSchemaFields = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  addAsset,
  addAttachment,
  setNewRequestDialogIsOpen,
  unloadForm,
  updateAttachment,
  removeAsset,
  removeAttachment,
  unloadAssets,
  setReportingCategoryId,
  setLocationId,
} = newRequestSlice.actions;

export default newRequestSlice.reducer;
