import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  MenuItem,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC, useCallback, useMemo } from "react";
import { BsArrowDownUp, BsClock } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { RiFlashlightLine } from "react-icons/ri";
import { VscArrowSwap } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { RootState } from "../../../store";
import {
  MenuSection,
  setCurrentMenuSection,
} from "../../../store/local-cache.slice";
import { useAuthentication } from "../../auth/AuthProvider";
import { useProductSubscriptions } from "../AppShell";
import {
  accountsMenu,
  eventsHQMenu,
  inventoryHQMenu,
  requestHQMenu,
} from "./menuOptions";

interface MegaMenuProps {
  onOpenSwitchModal: () => void;
}

const MARGIN = 1.5;

export const MegaMenu: FC<MegaMenuProps> = ({ onOpenSwitchModal }) => {
  const { accounts } = useAuthentication();
  const schoolNameColor = useColorModeValue("gray.500", "whiteAlpha.800");
  const iconsColor = useColorModeValue("blue.500", "blue.300");
  const isLightMode = useColorModeValue(true, false);
  const { toggleColorMode } = useColorMode();
  const dispatch = useDispatch();

  const { hasEvents, hasRequests, hasInventory } = useProductSubscriptions();
  const { currentAccount, isProductAdmin, isEventAdmin, isInventoryAdmin } =
    useAuthentication();
  const {
    isAdmin,
    isReviewer,
    isTechnician,
    isContractor,
    policyAllowsDashboard,
  } = useSelector((state: RootState) => state.localCache);
  const { enableInvoicesForEvents, isEnablePublicPortal } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );

  const handleColorModeChange = useCallback(() => {
    toggleColorMode();
  }, [toggleColorMode]);

  const handleSetCurrentSection = useCallback(
    (section: MenuSection) => {
      dispatch(setCurrentMenuSection({ section }));
    },
    [dispatch]
  );

  const showTransactions = useMemo(
    () => policyAllowsDashboard || isProductAdmin || isAdmin || isReviewer,
    [policyAllowsDashboard, isProductAdmin, isAdmin, isReviewer]
  );

  const showTimesheet = useMemo(
    () =>
      isProductAdmin || isAdmin || isReviewer || isContractor || isTechnician,
    [isProductAdmin, isAdmin, isReviewer, isContractor, isTechnician]
  );

  const memoizedEventsMenu = useMemo(() => {
    const eventsCopy = [...eventsHQMenu];
    if (enableInvoicesForEvents) {
      eventsCopy.push(
        {
          route: "/account/rate-sheet",
          text: "Rate Sheet",
        },
        {
          route: "/account/invoice-list",
          text: "Invoices",
        },
        {
          route: "/account/payments-list",
          text: "Payments",
        }
      );
    }
    if (isEnablePublicPortal) {
      eventsCopy.push({
        route: "/account/portal-users",
        text: "Portal Users",
      });
    }

    return eventsCopy;
  }, [enableInvoicesForEvents, isEnablePublicPortal]);

  const MenuGroupWitdh = useMemo(() => {
    let productsCount = 1;
    if (isProductAdmin && hasRequests) {
      productsCount++;
    }

    if (isEventAdmin && hasEvents) {
      productsCount++;
    }

    if (isInventoryAdmin && hasInventory) {
      productsCount++;
    }

    return 100 / productsCount - MARGIN;
  }, [
    hasEvents,
    hasInventory,
    hasRequests,
    isEventAdmin,
    isInventoryAdmin,
    isProductAdmin,
  ]);

  return (
    <>
      <Box px={4} py={2}>
        <Text color={schoolNameColor} fontWeight="bold">
          {currentAccount.name}
        </Text>
        <Divider border="1px" mt={2} />
      </Box>

      <Flex display="flex" justifyContent="space-between">
        <HStack gap={2} flex={1} minW="60%">
          <MenuItem
            onClick={handleColorModeChange}
            _hover={{ background: "none" }}
            w="unset"
          >
            <Icon as={RiFlashlightLine} mr={1} color={iconsColor} />
            Switch to {isLightMode ? "dark" : "light"} mode
          </MenuItem>
          {accounts.length > 1 && (
            <MenuItem
              w="unset"
              onClick={onOpenSwitchModal}
              _hover={{ background: "none" }}
            >
              <Icon as={VscArrowSwap} mr={1} color={iconsColor} />
              Switch Account
            </MenuItem>
          )}
          <MenuItem
            w="unset"
            as={RouterLink}
            to="/user-settings"
            _hover={{ background: "none" }}
          >
            <Icon as={IoSettingsOutline} mr={1} color={iconsColor} />
            User Profile
          </MenuItem>

          {showTransactions && (
            <MenuItem
              w="unset"
              as={RouterLink}
              to="/transactions"
              _hover={{ background: "none" }}
            >
              <Icon as={BsArrowDownUp} mr={1} color={iconsColor} />
              Transactions
            </MenuItem>
          )}

          {showTimesheet && (
            <MenuItem
              w="unset"
              as={RouterLink}
              to="/timesheet"
              _hover={{ background: "none" }}
            >
              <Icon as={BsClock} mr={1} color={iconsColor} />
              Timesheet
            </MenuItem>
          )}
        </HStack>

        <Flex display="flex" pl={2}>
          <MenuItem
            as="a"
            _hover={{ background: "none" }}
            href={`${process.env.REACT_APP_AUTH_SERVER}/logout?redirectUrl=/login?accountId=${currentAccount.id}`}
          >
            <Icon as={FiLogOut} mr={1} color={iconsColor} />
            Logout
          </MenuItem>
        </Flex>
      </Flex>

      <Grid
        gap={4}
        px={4}
        py={4}
        templateColumns={`repeat(auto-fill, minmax(${MenuGroupWitdh}%, 1fr))`}
        w="100%"
      >
        <GridItem>
          <Heading size="sm" w="100%">
            Account
          </Heading>
          <Divider w="100%" />
          {accountsMenu.map((item) => (
            <MenuItem
              pl={0}
              to={item.route}
              as={RouterLink}
              key={item.route}
              _hover={{ background: "none" }}
              onClick={() => handleSetCurrentSection("account")}
            >
              {item.text}
            </MenuItem>
          ))}
        </GridItem>

        {hasRequests && isProductAdmin && (
          <GridItem display="flex" flexDir="column">
            <Heading size="sm" w="100%">
              HeroHQ
            </Heading>
            <Divider w="100%" />
            {requestHQMenu.map((item) => (
              <MenuItem
                pl={0}
                to={item.route}
                as={RouterLink}
                key={item.route}
                _hover={{ background: "none" }}
                onClick={() => handleSetCurrentSection("requests")}
              >
                {item.text}
              </MenuItem>
            ))}
          </GridItem>
        )}

        {hasEvents && isEventAdmin && (
          <GridItem display="flex" flexDir="column">
            <Heading size="sm" w="100%">
              EventsHQ
            </Heading>
            <Divider w="100%" />
            {memoizedEventsMenu.map((item) => (
              <MenuItem
                pl={0}
                to={item.route}
                as={RouterLink}
                key={item.route}
                _hover={{ background: "none" }}
                onClick={() => handleSetCurrentSection("events")}
              >
                {item.text}
              </MenuItem>
            ))}
          </GridItem>
        )}

        {hasInventory && isInventoryAdmin && (
          <GridItem display="flex" flexDir="column">
            <Heading size="sm" w="100%">
              InventoryHQ
            </Heading>
            <Divider w="100%" />
            {inventoryHQMenu.map((item) => (
              <MenuItem
                pl={0}
                to={item.route}
                as={RouterLink}
                key={item.route}
                _hover={{ background: "none" }}
                onClick={() => handleSetCurrentSection("inventory")}
              >
                {item.text}
              </MenuItem>
            ))}
          </GridItem>
        )}
      </Grid>
    </>
  );
};
