import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ApiProject } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useRef } from "react";
import { RiAddLine, RiArrowDownSLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../../../components/auth/AuthProvider";
import { RootState, useThunkDispatch } from "../../../../../store";
import { setNewRequestDialogIsOpen } from "../../../../../store/new-request-form.slice";
import { findProjectRequests } from "../../../../../store/planning-hq/requests/findRequests.thunk";
import { RequestItem } from "./RequestItem";
import { RequestList } from "./RequestList";
import { FilterTags } from "./filters/FilterTags";
import { RequestFilters } from "./filters/RequestFilters";

type RequestsProps = { project: ApiProject };

export const Requests: FC<RequestsProps> = ({ project }) => {
  const { apiClient } = useAuthentication();
  const { sort, filters, requests } = useSelector(
    (state: RootState) => state.requestList
  );

  const { loadingStatus, total } = useSelector(
    (state: RootState) => state.projectRequests
  );
  const { isSubmitting } = useSelector(
    (state: RootState) => state.formikModalSlice
  );

  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const init = useRef<boolean>();

  const handleNewRequestClick = useCallback(() => {
    dispatch(
      setNewRequestDialogIsOpen({ isOpen: true, source: "planning.scheduling" })
    );
  }, [dispatch]);

  useEffect(() => {
    if (init.current) return;

    thunkDispatch(
      findProjectRequests({
        apiClient,
        projectId: project.id,
      })
    ).then(() => {
      init.current = true;
    });
  }, [apiClient, project, thunkDispatch]);

  useEffect(() => {
    if (!init.current) return;
    thunkDispatch(findProjectRequests({ apiClient, projectId: project.id }));
  }, [
    thunkDispatch,
    project.id,
    apiClient,
    sort,
    filters.date,
    filters.search,
  ]);

  useEffect(() => {
    if (!init.current) return;
    if (typeof isSubmitting === "boolean" && isSubmitting === false) {
      thunkDispatch(findProjectRequests({ apiClient, projectId: project.id }));
    }
  }, [isSubmitting, apiClient, project.id, thunkDispatch]);

  return (
    <>
      <HStack gap={4} justifyContent="space-between">
        <Heading as="h3" fontSize="larger">
          Requests
        </Heading>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<RiArrowDownSLine />}
            colorScheme="blue"
            leftIcon={<RiAddLine />}
            size="sm"
          >
            Add request
          </MenuButton>
          <MenuList>
            <MenuItem onClick={handleNewRequestClick}>
              <HStack>
                <AddIcon w={3} h={3} />
                <Text>Create new request</Text>
              </HStack>
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
      <RequestFilters project={project} />
      <HStack w="full" justifyContent="space-between">
        <Text fontSize="sm">{`Showing ${total}`}</Text>
        <FilterTags project={project} />
      </HStack>
      <Box
        sx={{
          flex: "1 1 auto",
          overflowY: "auto",
          minH: "0px",
        }}
      >
        <RequestList>
          {loadingStatus === "fulfilled" && (
            <>
              {requests.length === 0 ? (
                <Text color="gray.500">
                  There are no requests to show in this project
                </Text>
              ) : (
                requests.map((r) => {
                  return (
                    <RequestItem request={r} key={`prj-request::${r.id}`} />
                  );
                })
              )}
            </>
          )}

          {loadingStatus === "pending" && (
            <Text color="gray.500">Loading...</Text>
          )}

          {loadingStatus === "rejected" && (
            <Text color="gray.500">
              An error ocurred while loading requests
            </Text>
          )}
        </RequestList>
      </Box>
    </>
  );
};
