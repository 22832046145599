import {
  Box,
  Button,
  Center,
  Flex,
  FlexProps,
  HStack,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import {
  ApiAsset,
  ApiAttachment,
  ApiRequest,
} from "@operations-hero/lib-api-client";
import axios from "axios";
import {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IoIosCamera } from "react-icons/io";
import { MdEmail, MdPhone } from "react-icons/md";
import { useDispatch } from "react-redux";
import { AttachmentFileCard } from "../../../components/attachments/AttachmentFileCard";
import {
  Attachment,
  mapApiAttachments,
} from "../../../components/attachments/Attachments";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AssetConditionBadge } from "../../../components/badges/AssetConditionBadge";
import { AssetWarrantyBadge } from "../../../components/badges/AssetWarrantyBadge";
import { DateBadge } from "../../../components/badges/DateBadge";
import { LocationTwoLine } from "../../../components/badges/LocationTwoLine";
import { ReadOnlyTextEditor } from "../../../components/form-helpers/rich-text-editor/RichTextEditorReadOnly";
import { updateRequestAsset } from "../../../store/request-form/request-form.slice";
import { formatCurrency } from "../../../utils/formatCurrency";

export interface AssetDetailModalProps {
  asset: ApiAsset;
  request?: ApiRequest;
  onClose: () => void;
}

export const AssetDetailModal = ({
  asset,
  request,
  onClose,
}: AssetDetailModalProps) => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const toast = useToast();
  const dispatch = useDispatch();

  const { apiClient, currentAccount: account } = useAuthentication();
  const isOpen = useMemo(() => !!asset, [asset]);
  const [isUploading, setIsUploading] = useState(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [defaultImage, setDefaultImage] = useState<ApiAttachment | null>(
    asset.defaultImage
  );

  const linkColor = useColorModeValue("blue.500", "blue.300");
  const bannerBgColor = useColorModeValue("gray.200", "gray.800");
  const bannerFontColor = useColorModeValue("gray.500", "gray.400");

  const browseFiles = useCallback(() => {
    if (!fileRef.current) return;
    fileRef.current.click();
  }, [fileRef]);

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }
      const rawFile = event.target.files[0];
      setIsUploading(true);

      apiClient
        .createUpload(account.id)
        .then(async (upload) => {
          await axios.put(upload.url, rawFile, {
            headers: { "Content-Type": rawFile.type },
          });

          const updated = await apiClient.createAssetAttachment(
            account.id,
            asset.id,
            {
              uploadId: upload.id,
            }
          );

          const updatedAsset = await apiClient.updateAsset(
            account.id,
            asset.id,
            { defaultImage: updated }
          );

          setDefaultImage(updated);

          if (request) {
            dispatch(updateRequestAsset(updatedAsset));
          }

          if (fileRef.current) {
            fileRef.current.value = "";
          }
        })
        .catch(() => {
          toast({
            duration: 1500,
            isClosable: true,
            position: "top",
            status: "error",
            title: "Error updating logo",
          });
        })
        .finally(() => {
          setIsUploading(false);
        });
    },
    [apiClient, asset, account, toast, request, dispatch]
  );

  useEffect(() => {
    apiClient
      .findAssetAttachments(account.id, asset.id, { pageSize: 20 })
      .then((result) => {
        setAttachments(mapApiAttachments(result.data));
      });
  }, [apiClient, account, asset]);

  return asset && isOpen ? (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent maxW="2xl">
        <ModalBody p={0}>
          <Stack align="flex-start" spacing={0}>
            <Box
              height={["200px", "250px"]}
              width="100%"
              position={"relative"}
              backgroundColor={bannerBgColor}
              overflow="hidden"
              borderTopRadius={"lg"}
              cursor={defaultImage ? undefined : "pointer"}
              onClick={defaultImage ? undefined : browseFiles}
            >
              {defaultImage ? (
                <Image
                  src={defaultImage.url}
                  alt={defaultImage.name}
                  transform="translate(-50%, -50%)"
                  minH="100%"
                  minW="100%"
                  position="absolute"
                  boxSizing="border-box"
                  left="50%"
                  top="50%"
                  objectFit="cover"
                />
              ) : (
                <Center height={"100%"} color={bannerFontColor}>
                  {isUploading ? (
                    <Spinner />
                  ) : (
                    <>
                      <Icon as={IoIosCamera} w={8} h={8} />
                      <Text ml={2}>Add asset image</Text>
                    </>
                  )}
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={fileRef}
                    onChange={handleFileChange}
                    accept="image/png, image/jpeg"
                  />
                </Center>
              )}
            </Box>

            <Stack width={"100%"} spacing={0}>
              <Property
                label="Name"
                value={<Text fontSize="lg">{asset.name}</Text>}
              />
              {asset.externalId && (
                <Property
                  label="Tag / Alternate ID"
                  value={asset.externalId || ""}
                />
              )}
              <Property
                label="Location"
                value={
                  asset.location ? (
                    <LocationTwoLine value={asset.location} />
                  ) : (
                    "No Location / Mobile Asset"
                  )
                }
              />
              {asset.notes && (
                <Property
                  label="Notes"
                  value={<ReadOnlyTextEditor value={asset.notes} />}
                />
              )}
              {asset.condition && (
                <Property
                  label="Condition"
                  value={<AssetConditionBadge value={asset.condition} />}
                />
              )}

              {asset.vendor && (
                <Property
                  label="Supplier"
                  value={
                    <Flex flexDir="column">
                      <Text>{asset.vendor.name}</Text>
                      <Flex justifyContent="space-between" w="100%" wrap="wrap">
                        <Link
                          href={`mailto::${asset.vendor.primaryContact?.email}`}
                          color={linkColor}
                          display="flex"
                          alignItems="center"
                        >
                          <Icon as={MdEmail} mr={1} />
                          {asset.vendor.primaryContact?.email}
                        </Link>

                        <Flex gap={2}>
                          <Link
                            href={`tel::${asset.vendor.primaryContact?.phone}`}
                            color={linkColor}
                            display="flex"
                            alignItems="center"
                          >
                            <Icon as={MdPhone} mr={1} />
                            {asset.vendor.primaryContact?.phone}
                          </Link>

                          {asset.vendor.primaryContact?.altPhone && (
                            <Link
                              href={`tel::${asset.vendor.primaryContact.altPhone}`}
                              color={linkColor}
                              display="flex"
                              alignItems="center"
                            >
                              <Icon as={MdPhone} mr={1} />
                              {asset.vendor.primaryContact.altPhone}
                            </Link>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  }
                />
              )}

              {asset.manufacturer && (
                <Property
                  label="Manufacturer"
                  value={
                    <Flex flexDir="column">
                      <Text>{asset.manufacturer.name}</Text>
                      {asset.manufacturer.primaryContact?.email && (
                        <Link
                          href={`mailto:${asset.manufacturer.primaryContact?.email}`}
                          color={linkColor}
                          display="flex"
                          alignItems="center"
                        >
                          <Icon as={MdEmail} mr={1} />
                          {asset.manufacturer.primaryContact?.email}
                        </Link>
                      )}
                      {asset.manufacturer.primaryContact?.altPhone && (
                        <Link
                          href={`tel::${asset.manufacturer.primaryContact.altPhone}`}
                          color={linkColor}
                          display="flex"
                          alignItems="center"
                        >
                          <Icon as={MdPhone} mr={1} />
                          {asset.manufacturer.primaryContact.altPhone}
                        </Link>
                      )}
                    </Flex>
                  }
                />
              )}
              {asset.model && (
                <Property label="Model" value={asset.model || ""} />
              )}
              {asset.serial && (
                <Property label="Serial" value={asset.serial || ""} />
              )}
              {asset.serviceProvider && (
                <Property
                  label="Service Provider"
                  value={
                    <Flex flexDir="column">
                      <Text>{asset.serviceProvider.name}</Text>
                      {asset.serviceProvider.primaryContact?.email && (
                        <Link
                          href={`mailto:${asset.serviceProvider.primaryContact?.email}`}
                          color={linkColor}
                          display="flex"
                          alignItems="center"
                        >
                          <Icon as={MdEmail} mr={1} />
                          {asset.serviceProvider.primaryContact?.email}
                        </Link>
                      )}
                      {asset.serviceProvider.primaryContact?.altPhone && (
                        <Link
                          href={`tel::${asset.serviceProvider.primaryContact.altPhone}`}
                          color={linkColor}
                          display="flex"
                          alignItems="center"
                        >
                          <Icon as={MdPhone} mr={1} />
                          {asset.serviceProvider.primaryContact.altPhone}
                        </Link>
                      )}
                    </Flex>
                  }
                />
              )}

              {asset.inServiceDate && (
                <Property
                  label="In Service Date"
                  value={<DateBadge date={asset.inServiceDate} />}
                />
              )}
              {asset.warrantyExpiration && (
                <Property
                  label="Warranty Expires"
                  value={
                    <HStack spacing={4}>
                      <Box>
                        <DateBadge date={asset.warrantyExpiration} />
                      </Box>
                      <Box>
                        <AssetWarrantyBadge asset={asset} />
                      </Box>
                    </HStack>
                  }
                />
              )}
              {asset.targetRenewalDate && (
                <Property
                  label="Estimated Renewal"
                  value={<DateBadge date={asset.targetRenewalDate} />}
                />
              )}
              {asset.purchaseDate && (
                <Property
                  label="Purchase Date"
                  value={<DateBadge date={asset.purchaseDate} />}
                />
              )}
              {asset.purchaseCost && (
                <Property
                  label="Purchase Cost"
                  value={formatCurrency(asset.purchaseCost)}
                />
              )}
              {asset.purchaseOrder && (
                <Property label="Purchase Order" value={asset.purchaseOrder} />
              )}
              {asset.invoiceNumber && (
                <Property label="Invoice Number" value={asset.invoiceNumber} />
              )}
              {asset.labels && asset.labels.length > 0 && (
                <Property
                  label="Labels"
                  value={
                    <Fragment>
                      {asset.labels.map((label, index) => {
                        return (
                          <Flex
                            justifyContent="space-between"
                            w="70%"
                            wrap="wrap"
                            key={`labels::${index}${label.key}${label.value}`}
                          >
                            <Text fontWeight="bold">{label.key}</Text>
                            <Text fontWeight="bold">{label.value}</Text>
                          </Flex>
                        );
                      })}
                    </Fragment>
                  }
                />
              )}
            </Stack>
            {attachments && attachments.length && (
              <>
                <Wrap p={4}>
                  {attachments.map((attachment) => (
                    <WrapItem key={`asset-attachment-${attachment.uploadId}`}>
                      <AttachmentFileCard attachment={attachment} />
                    </WrapItem>
                  ))}
                </Wrap>
              </>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter textAlign={"right"}>
          <Button
            autoFocus={false}
            colorScheme="blue"
            onClick={onClose}
            size="sm"
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : null;
};

interface Props extends FlexProps {
  label: string;
  value: string | JSX.Element;
}

export const Property = (props: Props) => {
  const { label, value, ...flexProps } = props;
  return (
    <Flex
      direction={{ base: "column", sm: "row" }}
      px="4"
      py="4"
      mt={0}
      _even={{ bg: useColorModeValue("gray.50", "gray.600") }}
      {...flexProps}
    >
      <Box minWidth="180px">{label}</Box>
      <Box flex="1">{value}</Box>
    </Flex>
  );
};
