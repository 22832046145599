import { Box, Text } from "@chakra-ui/react";
import { ApiRateGroup } from "@operations-hero/lib-api-client";
import { Select } from "chakra-react-select";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useShowToast } from "../../hooks/showToast";
import { useAuthentication } from "../auth/AuthProvider";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomOptionsProps,
  CustomSelectComponentProp,
  SingleValueSelect,
} from "./select-overrides-types";

const FIND_RATE_GROUPS_OPTIONS = {
  pageSize: 100,
  page: 1,
};

export interface RateGroupAutocompleteProps {
  name?: string;
  isInvalid?: boolean;
  allowEmpty?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  value: string | null;
  options?: ApiRateGroup[];
  isClearable?: boolean;
  onChange: (rateGroup: string | null) => void;
}

const RateGroupBadge = ({ value }: { value: ApiRateGroup }) => {
  return <Text>{value.name}</Text>;
};

const CustomOptionComponent = createOptionComponent(RateGroupBadge);

const CustomSingleValueComponent = (props: SingleValueSelect<ApiRateGroup>) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <RateGroupBadge value={data} />
    </Box>
  );
};

export const RateGroupAutocomplete = ({
  value,
  name,
  onChange,
  allowEmpty = true,
  isInvalid,
  isDisabled,
  placeholder,
  options,
  isClearable,
}: RateGroupAutocompleteProps) => {
  const { currentAccount, apiClient } = useAuthentication();
  const [rateGroups, setRateGroups] = useState<ApiRateGroup[]>([]);

  const showToast = useShowToast();

  const findRateGroups = useCallback(async () => {
    try {
      const response = await apiClient.findRateGroups(currentAccount.id, {
        ...FIND_RATE_GROUPS_OPTIONS,
      });
      setRateGroups(response.data);
    } catch (error) {
      showToast("error", "Something went wrong loading group rates");
    }
  }, [apiClient, currentAccount.id, showToast]);

  useEffect(() => {
    if (rateGroups.length === 0) {
      findRateGroups();
    }
  }, [findRateGroups, rateGroups.length]);

  const handleChange = useCallback(
    (newValue: ApiRateGroup | null) => {
      if (allowEmpty !== true && newValue == null) {
        return;
      }
      onChange(newValue ? newValue.id : null);
    },
    [onChange, allowEmpty],
  );

  const getOptionValue = useCallback(
    (rateGroup: ApiRateGroup) => rateGroup.id,
    [],
  );

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: CustomOptionsProps<ApiRateGroup>) =>
        CustomOptionComponent(props),
      SingleValue: (props: SingleValueSelect<ApiRateGroup>) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  return (
    <Select
      name={name || "rate-group-select"}
      value={rateGroups?.find((item) => item.id === value)}
      options={rateGroups}
      components={components}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      onChange={handleChange}
      placeholder={placeholder}
      chakraStyles={commonStyles}
      getOptionValue={getOptionValue}
      isClearable={isClearable || false}
      isMulti={false}
    />
  );
};
