import { Avatar, Tag, TagLabel, Tooltip } from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import React, { CSSProperties, useMemo } from "react";

export interface UserBadgeProps {
  value: ApiUserSummary;
  size?: string;
  tagSize?: string;
  hideName?: boolean;
  includeAvatar?: boolean;
  backgroundColor?: string;
  textFontWeight?: string;
  textStyle?: CSSProperties;
}

export const UserBadge: React.FC<UserBadgeProps> = ({
  value,
  size = "xs",
  tagSize = "lg",
  hideName,
  includeAvatar = true,
  textFontWeight,
  textStyle,
}) => {
  const fullName = useMemo(
    () => `${value.firstName} ${value.lastName}`,
    [value]
  );

  return (
    <Tooltip label={`${fullName} - ${value.email}`} placement="auto-start">
      <Tag size={tagSize} background="inherit" pl={0} pr={1}>
        {includeAvatar && (
          <Avatar
            size={size}
            name={`${value.firstName} ${value.lastName}`}
            src={value.profileImage}
            visibility={value.id === "null-user" ? "hidden" : "initial"}
            // not sure why this is here but it was messing up isTruncated for wrappers around this component
            // ml={-1}
            mr={hideName ? 0 : 2}
          />
        )}
        {value.id === "null-user" || !hideName ? (
          <TagLabel
            fontWeight={textStyle?.fontWeight || textFontWeight || "normal"}
            fontStyle={
              value.id === "null-user"
                ? "italic"
                : textStyle?.fontStyle || "normal"
            }
            fontSize={textStyle?.fontSize ? textStyle.fontSize : [16, 16, 14]}
            style={textStyle}
          >
            {fullName}
          </TagLabel>
        ) : null}
      </Tag>
    </Tooltip>
  );
};
