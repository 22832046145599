import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Stack,
} from "@chakra-ui/react";
import {
  ApiEventGroup,
  ApiUserSummary,
  CreateApiEventGroup,
} from "@operations-hero/lib-api-client";
import { Formik, FormikErrors } from "formik";
import { FC, useCallback } from "react";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import {
  CreateUserType,
  EventGroupUserAutocompleteControl,
} from "../../../../components/form-helpers/EventGroupUserAutocompleteControl";
import { RadioButtonsControl } from "../../../../components/form-helpers/RadioButtonsControl";
import { RateGroupAutocompleteControl } from "../../../../components/form-helpers/RateGroupAutocompleteControl";
import { TextInputControl } from "../../../../components/form-helpers/TextInputControl";
import { isObjectEmpty } from "../../../../utils/compareObjects";
import { EventGroupFormSchema } from "../../../account-settings/event-group-form/GroupFormSchema";

interface FormValuesProps {
  isInternalGroup: "external" | "internal";
  name: string;
  rateGroup?: string | null;
  owner: ApiUserSummary | null;
  contactInfo: {
    phoneNumber: string;
  };
}

const getInitialValues = (
  defaultOwner: ApiUserSummary | null,
): FormValuesProps => {
  return {
    isInternalGroup: "internal",
    name: "",
    rateGroup: null,
    owner: defaultOwner,
    contactInfo: {
      phoneNumber: "",
    },
  };
};

interface QuickEventGroupFormProps {
  onCreate: (response: ApiEventGroup) => void;
  onCancel?: () => void;
}

export const QuickEventGroupForm: FC<QuickEventGroupFormProps> = ({
  onCreate,
  onCancel,
}) => {
  const { apiClient, currentAccount, currentUser, isEventAdmin } =
    useAuthentication();

  const handleOnCreate = useCallback(
    async (
      values: FormValuesProps,
      validateForm: (values?: any) => Promise<FormikErrors<FormValuesProps>>,
      submitForm: () => Promise<void>,
    ) => {
      const validationResult = await validateForm();
      if (!isObjectEmpty(validationResult)) {
        submitForm();
        return;
      }
      const newGroup: CreateApiEventGroup = {
        ...values,
        isInternalGroup: values.isInternalGroup === "internal" ? true : false,
      };
      const response = await apiClient.createEventGroup(
        currentAccount.id,
        newGroup,
      );
      onCreate(response);
    },
    [apiClient, currentAccount.id, onCreate],
  );

  return (
    <Stack>
      <Formik
        initialValues={getInitialValues(currentUser)}
        validationSchema={EventGroupFormSchema}
        onSubmit={() => {}}
      >
        {({ values, validateForm, submitForm }) => (
          <Grid gap={4}>
            <GridItem colSpan={12}>
              <FormControl>
                <FormLabel>Is this group internal or external?</FormLabel>
                <RadioButtonsControl
                  name="isInternalGroup"
                  value={values.isInternalGroup}
                  radioOptions={[
                    {
                      label: "Internal",
                      schemeColor: "blue",
                      value: "internal",
                    },
                    {
                      label: "External",
                      schemeColor: "blue",
                      value: "external",
                    },
                  ]}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={12}>
              <TextInputControl
                name="name"
                value={values.name}
                label="Name this group"
                placeholder="Ex. Science Club"
              />
            </GridItem>

            {isEventAdmin && (
              <GridItem colSpan={12}>
                <EventGroupUserAutocompleteControl
                  label="Owner"
                  name="owner"
                  value={values.owner}
                  showCreate={true}
                  createOptions={{
                    isDisabled: false,
                    userType: CreateUserType.owner,
                    formType: "modal",
                  }}
                />
              </GridItem>
            )}

            <GridItem colSpan={[12, 12, 6]}>
              <RateGroupAutocompleteControl
                name="rateGroup"
                label="Select a rate group"
                value={values.rateGroup || null}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 6]}>
              <TextInputControl
                name="contactInfo.phoneNumber"
                label="Phone"
                value={values.contactInfo.phoneNumber || null}
              />
            </GridItem>

            <GridItem colSpan={12} display="flex" justifyContent="flex-end">
              <Button
                variant="outline"
                colorScheme="blue"
                size="sm"
                mr={2}
                onClick={() => {
                  onCancel && onCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                variant="outline"
                colorScheme="blue"
                onClick={() => handleOnCreate(values, validateForm, submitForm)}
              >
                Create
              </Button>
            </GridItem>
          </Grid>
        )}
      </Formik>
    </Stack>
  );
};
