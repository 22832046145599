import {
  ApiAccount,
  ApiAttachment,
  ApiClient,
  ApiComment,
  ApiEvent,
  ApiEventApprover,
  ApiEventOccurrence,
  ApiEventOccurrenceConflict,
  ApiEventStatus,
  ApiGroupStatus,
  ApiRequest,
  ApiServiceSumary,
  ApiSpaceSummary,
  ApiTimelineEntry,
  ApiUser,
  ApiVenue,
  ApiVenueSummary,
  CreateApiEventOccurrence,
  ScheduledJob,
  UpdateApiEvent,
  UpdateApiEventOccurrence,
  UpdateApiEventOccurrenceForBulk,
} from "@operations-hero/lib-api-client";
import { ApiQuestionResponse } from "@operations-hero/lib-api-client/dist/models/QuestionResponse";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import {
  Attachment,
  mapApiAttachments,
} from "../../components/attachments/Attachments";
import {
  EventNotificationStatus,
  LoadingStatus,
} from "../../types/redux-slices-types";
import { updateTimes } from "../../utils/updateTimes";
import { canReOpen, generateTransition } from "./event-details.helpers";
import { eventCommentsHandlers } from "./thunks/eventComments.thunk";
import {
  DEFAULT_EVENT_NOTIFICATION,
  eventNotificationHandler,
} from "./thunks/eventNotifications.thunk";
import { loadEventDatesScheduleModalHandler } from "./thunks/eventOccurrenceModal.thunk";
import {
  DEFAULT_EVENT_TIMELINE,
  eventTimelineHandler,
} from "./thunks/eventTimeline.thunk";

export interface EventOccurrenceUpdateProps extends ApiEventOccurrence {
  mustUpdate: boolean;
  conflicts: ApiEventOccurrenceConflict;
}
export interface EventOccurrenceUpdateCheckAllProps {
  start?: string;
  end?: string;
  spaces?: ApiSpaceSummary[];
  venue?: ApiVenueSummary;
}
export interface EventTransition {
  next: ApiEventStatus;
  backwards: ApiEventStatus[];
  forwards: ApiEventStatus[];
  canCancel: boolean;
}
type IsInitilizedType = "view" | "modal";

export interface LocalEventDetailsSliceState {
  event: ApiEvent | null;
  transition: EventTransition | null;
  canReOpen: boolean;
  comments: ApiComment[];
  commentsTotal: number;
  commentsCurrentPage?: number;
  eventDates: EventOccurrenceUpdateProps[];
  eventDatesFilters: {
    includeInactive: boolean;
    pageSize: number;
    eventDatesTotalPages: number;
    eventDatesCurrentPage: number;
  };
  venueAttachments: ApiAttachment[];
  requestEventServices: Record<string, ApiRequest[]>;
  isInitialized?: IsInitilizedType;
  isUserAvailableToEditEvent?: boolean;
  isVenueManager: boolean;
  workingVenue: ApiVenue | null;
  eventAttachments: Attachment[];
  //modal properties
  loadingModal: "idle" | "pending" | "succeeded" | "failed";
  eventDatesModal: EventOccurrenceUpdateProps[];
  eventDateCheckAll: EventOccurrenceUpdateCheckAllProps;
  eventDatesEditedModal: Record<string, EventOccurrenceUpdateProps>;
  eventDatesSelectedModal: Record<string, EventOccurrenceUpdateProps>;
  eventDatesScheduleModal: ScheduledJob[];
  eventDatesCheckedModal: Record<string, boolean>;
  allOccurrencesCheck: boolean;
  eventDatesFiltersModal: {
    includeInactive: boolean;
    pageSize: number;
    total: number;
    page: number;
  };
  timeline: {
    loading: LoadingStatus;
    page: number;
    pageSize: number;
    total: number;
    data: ApiTimelineEntry[];
  };
  notifications: EventNotificationStatus;
  userVenueServicesHash: Record<string, ApiServiceSumary>;
  questionResponses: ApiQuestionResponse[];
}
export interface InitLocalReasonThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  eventId: string;
  search?: string;
  initFrom: IsInitilizedType;
}
export interface ReloadEventRequestsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
}
const mapEventDates = (
  occurrences: ApiEventOccurrence[],
  conflicts: ApiEventOccurrenceConflict[]
) => {
  const newOccurrences: EventOccurrenceUpdateProps[] = occurrences.map(
    (occurrence, index) => ({
      ...occurrence,
      mustUpdate: false,
      conflicts: conflicts[index],
    })
  );
  return newOccurrences;
};

export const initLoadEventDetails = createAsyncThunk(
  "event-details/load-event",
  async (
    { apiClient, account, eventId, initFrom }: InitLocalReasonThunkParams,
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const { eventDatesCurrentPage, pageSize, includeInactive } =
      state.eventDetails.eventDatesFilters;
    const { isEventAdmin, currentUser: user } = state.auth;

    const event = await apiClient.getEvent(account.id, eventId);
    const occurrences = await apiClient.findEventOccurrences(
      account.id,
      event.id,
      {
        page: eventDatesCurrentPage,
        pageSize: pageSize,
        includeInactive,
      }
    );

    const [venueAttachments, eventConflicts, eventAttachments, venue] =
      await Promise.all([
        apiClient.findVenueAttachments(account.id, event.venue.id),
        apiClient.checkEventConflicts(
          account.id,
          occurrences.data.map((occ) => ({
            id: occ.id,
            start: occ.start,
            end: occ.end,
            spaces: occ.spaces.map((s) => s?.id).filter(Boolean),
            venue: occ.venue.id,
          }))
        ),
        apiClient.findEventAttachments(account.id, event.id),
        apiClient.getVenue(account.id, event.venue.id),
      ]);

    const mapOccurrences: EventOccurrenceUpdateProps[] = mapEventDates(
      occurrences.data,
      eventConflicts
    );
    const eventDates = { ...occurrences, data: mapOccurrences };

    //load request with eventId Filter
    const result = await apiClient.findRequests(account.id, {
      events: [event.id],
      services: event.services.map((ser) => ser.id),
    });

    const requestsServices = result === null ? null : result;
    const isVenueManagerForEvent = venue.facilityManagers.some(
      (fm) => fm.id === user.id
    );

    // Load event questions responses
    const questionResponses = await apiClient.findQuestionResponse(account.id, {
      eventId: event.id,
    });

    return {
      event,
      eventDates,
      requestsServices,
      initFrom,
      isEventAdmin,
      user,
      venueAttachments,
      eventAttachments,
      venue,
      isVenueManager: isVenueManagerForEvent,
      questionResponses,
    };
  }
);
export const reloadEventRequests = createAsyncThunk(
  "event-details/reload-event-requests",
  async ({ apiClient, account }: ReloadEventRequestsThunkParams, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { event } = state.eventDetails;

    if (!event) {
      return {
        event,
        requestsServices: null,
      };
    }

    //load request with eventId Filter
    const result = await apiClient.findRequests(account.id, {
      events: [event.id],
      services: event.services.map((ser) => ser.id),
    });
    const requestsServices = result === null ? null : result;
    return {
      event,
      requestsServices,
    };
  }
);
interface LoadEventDatesProps
  extends Omit<InitLocalReasonThunkParams, "eventId" | "initFrom"> {}

export const loadEventDates = createAsyncThunk(
  "event-details/load-event-dates",
  async ({ apiClient, account }: LoadEventDatesProps, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { eventDatesCurrentPage, pageSize, includeInactive } =
      state.eventDetails.eventDatesFilters;
    const occurrences = await apiClient.findEventOccurrences(
      account.id,
      state.eventDetails.event!.id,
      {
        page: eventDatesCurrentPage,
        pageSize: pageSize,
        includeInactive: includeInactive,
      }
    );

    const eventConflicts = await apiClient.checkEventConflicts(
      account.id,
      occurrences.data.map((occ) => ({
        id: occ.id,
        start: occ.start,
        end: occ.end,
        spaces: occ.spaces.map((s) => s.id),
        venue: occ.venue.id,
      }))
    );

    const mapOccurrences: EventOccurrenceUpdateProps[] = mapEventDates(
      occurrences.data,
      eventConflicts
    );

    const eventDates = { ...occurrences, data: mapOccurrences };
    return eventDates;
  }
);

interface UpdateEventParams {
  eventId: string;
  accountId: string;
  apiClient: ApiClient;
  event: UpdateApiEvent;
}

export const updateEvent = createAsyncThunk(
  "event-details/update",
  async (params: UpdateEventParams, thunkAPI) => {
    const { apiClient, accountId, event, eventId } = params;

    const response = await apiClient.updateEvent(accountId, eventId, event);

    return response;
  }
);

interface toggleActivateEventOccurrenceProps {
  eventId: string;
  accountId: string;
  apiClient: ApiClient;
  occurrencesIds: string[];
  type: "deactivate" | "reactivate";
}

export const toggleActivateEventOccurrence = createAsyncThunk(
  "evet-details/toggle-activate",
  async (params: toggleActivateEventOccurrenceProps, thunkAPI) => {
    const { type, apiClient, accountId, eventId, occurrencesIds } = params;

    type === "deactivate" &&
      (await Promise.all(
        occurrencesIds.map((id) =>
          apiClient.deactivateEventOccurrence(accountId, eventId, id)
        )
      ));

    type === "reactivate" &&
      (await Promise.all(
        occurrencesIds.map((id) =>
          apiClient.reactivateEventOccurrence(accountId, eventId, id)
        )
      ));

    return { occurrencesIds, type };
  }
);

interface UpdateSingleEventOccurrenceParams {
  apiClient: ApiClient;
  accountId: string;
  eventId: string;
  occurrenceId: string;
  occurrence: UpdateApiEventOccurrence;
  conflicts: ApiEventOccurrenceConflict;
}

export const updateSingleEventOccurrence = createAsyncThunk(
  "update/event-occurrence",
  async (params: UpdateSingleEventOccurrenceParams, thunkAPI) => {
    const {
      apiClient,
      accountId,
      eventId,
      occurrenceId,
      occurrence,
      conflicts,
    } = params;

    const response = await apiClient.updateEventOccurrence(
      accountId,
      eventId,
      occurrenceId,
      occurrence
    );

    // Load event questions responses
    const questionResponses = await apiClient.findQuestionResponse(accountId, {
      eventId: eventId,
    });

    return { response, conflicts, questionResponses };
  }
);

interface UpdateMultipleOccurrences
  extends Omit<ApiEventOccurrence, "spaces" | "venue"> {
  spaces: string[];
  venue: string;
  conflicts: ApiEventOccurrenceConflict;
}
interface updateMultipleEventOccurrencesParams {
  apiClient: ApiClient;
  accountId: string;
  eventId: string;
  occurrences: UpdateMultipleOccurrences[];
}

export const updateMultipleEventOccurrences = createAsyncThunk(
  "update/event-occurrences",
  async (params: updateMultipleEventOccurrencesParams, thunkAPI) => {
    const { apiClient, accountId, eventId, occurrences } = params;

    const result = await Promise.all(
      occurrences.map((occurrence) =>
        apiClient.updateEventOccurrence(accountId, eventId, occurrence.id, {
          start: occurrence.start,
          end: occurrence.end,
          venue: occurrence.venue,
          spaces: occurrence.spaces,
        })
      )
    );

    const updatedOccurrences = result.map((item, idx) => ({
      ...item,
      conflicts: occurrences[idx].conflicts,
    }));

    return updatedOccurrences;
  }
);

interface UpdateAllEventOccurrencesParams {
  apiClient: ApiClient;
  accountId: string;
  eventId: string;
  occurrence: UpdateApiEventOccurrence;
  selectedOccurrences: EventOccurrenceUpdateProps[];
}

export const updateAllEventOccurrences = createAsyncThunk(
  "update/all-event-occurrences",
  async (params: UpdateAllEventOccurrencesParams, thunkAPI) => {
    const { apiClient, accountId, eventId, occurrence, selectedOccurrences } =
      params;

    let page = 1;
    let total = 0;
    const allOccurrences = [] as ApiEventOccurrence[];

    do {
      const result = await apiClient.findEventOccurrences(accountId, eventId, {
        page,
        pageSize: 100,
        includeInactive: false,
      });
      if (total === 0) {
        total = result.total;
      }
      allOccurrences.push(...result.data);
      page++;
    } while (allOccurrences.length < total);

    const localStart = occurrence.start
      ? new Date(occurrence.start)
      : undefined;
    const localEnd = occurrence.end ? new Date(occurrence.end) : undefined;

    const bulkUpdates = allOccurrences.map<UpdateApiEventOccurrenceForBulk>(
      (occ) => {
        const update: UpdateApiEventOccurrenceForBulk = {
          id: occ.id,
        };
        if (occurrence.venue) {
          update.venue = occurrence.venue;
        }

        if (occurrence.spaces) {
          update.spaces = occurrence.spaces;
        }

        // calculate the new start time based on the local hour and minute.
        if (localStart) {
          const start = new Date(occ.start);
          start.setHours(localStart.getHours());
          start.setMinutes(localStart.getMinutes());
          update.start = start.toISOString();
        }

        if (localEnd) {
          const end = new Date(occ.end);
          end.setHours(localEnd.getHours());
          end.setMinutes(localEnd.getMinutes());
          update.end = end.toISOString();
        }

        return update;
      }
    );

    await apiClient.updateEventOccurrences(accountId, eventId, bulkUpdates);

    return selectedOccurrences;
  }
);

interface CreateEventOccurrenceParams {
  apiClient: ApiClient;
  accountId: string;
  eventId: string;
  occurrences: CreateApiEventOccurrence[];
}

export const createEventOccurrences = createAsyncThunk(
  "new/event-ocurrences",
  async (params: CreateEventOccurrenceParams) => {
    const { apiClient, accountId, eventId, occurrences } = params;
    const response = await apiClient.createEventOccurrences(
      accountId,
      eventId,
      occurrences.map((occ) => ({
        start: occ.start,
        end: occ.end,
        spaces: occ.spaces.map((s) => (typeof s === "string" ? s : s.id)),
        venue: typeof occ.venue === "string" ? occ.venue : occ.venue.id,
      }))
    );

    const conflicts = await apiClient.checkEventConflicts(accountId, response);

    const responseIncludeConflicts = response.map((item, idx) => ({
      ...item,
      conflicts: conflicts[idx],
      mustUpdate: false,
    }));

    return responseIncludeConflicts;
  }
);

interface LoadVenueParams {
  apiClient: ApiClient;
  accountId: string;
  venueId: string;
}

export const loadVenue = createAsyncThunk(
  "load/event/venue",
  async (params: LoadVenueParams) => {
    const { apiClient, accountId, venueId } = params;
    const response = await apiClient.getVenue(accountId, venueId);
    return response;
  }
);

export const localEventDetailsSlice = createSlice({
  name: "eventDetails",
  initialState: {
    event: null,
    transition: null,
    canReOpen: false,
    comments: [],
    commentsTotal: 0,
    commentsCurrentPage: 1,
    eventDates: [],
    eventDatesModal: [],
    eventDateCheckAll: {} as EventOccurrenceUpdateCheckAllProps,
    eventDatesEditedModal: {},
    eventDatesSelectedModal: {},
    eventDatesScheduleModal: [],
    eventDatesCheckedModal: {},
    allOccurrencesCheck: false,
    eventAttachments: [],
    eventDatesFiltersModal: {
      pageSize: 10,
      total: 1,
      includeInactive: false,
      page: 1,
    },
    loadingModal: "idle",
    eventDatesFilters: {
      pageSize: 10,
      eventDatesTotalPages: 1,
      includeInactive: false,
      eventDatesCurrentPage: 1,
    },
    isInitialized: undefined,
    isUserAvailableToEditEvent: undefined,
    isVenueManager: false,
    venueAttachments: [] as ApiAttachment[],
    requestEventServices: {} as Record<string, ApiRequest[]>,
    workingVenue: null,
    timeline: DEFAULT_EVENT_TIMELINE,
    notifications: DEFAULT_EVENT_NOTIFICATION,
    userVenueServicesHash: {} as Record<string, ApiServiceSumary>,
    questionResponses: [],
  } as LocalEventDetailsSliceState,
  reducers: {
    setCurrentPage: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<number>
    ) => {
      state.eventDatesFilters.eventDatesCurrentPage = action.payload;
    },
    setIncludeInactive: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.eventDatesFilters.includeInactive = action.payload;
    },
    setApproverEventDetails: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<{ approver: ApiEventApprover }>
    ) => {
      if (!state.event) return;
      const { approver } = action.payload;
      const index = state.event.eventApprovers.findIndex(
        (item) => item.id === approver.id
      );
      if (index === -1) {
        state.event?.eventApprovers.unshift(approver);
        return;
      }
      state.event.eventApprovers[index] = approver;
    },
    setCommentsCurrentPage: (
      state: LocalEventDetailsSliceState,
      payload: PayloadAction<number>
    ) => {
      state.commentsCurrentPage = payload.payload;
    },
    addCheckedDate: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<{
        occurrenceId: string;
        isChecked: boolean;
      }>
    ) => {
      const { occurrenceId, isChecked } = action.payload;
      state.eventDatesCheckedModal[occurrenceId] = isChecked;
      if (!isChecked) delete state.eventDatesCheckedModal[occurrenceId];
    },
    addSelectedDate: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<{
        occurrence: EventOccurrenceUpdateProps;
        isChecked: boolean;
      }>
    ) => {
      const { occurrence, isChecked } = action.payload;
      state.eventDatesSelectedModal[occurrence.id] = occurrence;
      if (!isChecked) delete state.eventDatesSelectedModal[occurrence.id];
    },
    addEditedDate: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<EventOccurrenceUpdateProps>
    ) => {
      state.eventDatesEditedModal[action.payload.id] = action.payload;
    },
    addEditedSpaceForAllSelected: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<{
        venue: ApiVenueSummary;
        spaces: ApiSpaceSummary[];
      }>
    ) => {
      const { venue, spaces } = action.payload;
      Object.keys(state.eventDatesSelectedModal).forEach((id) => {
        const occurrence = state.eventDatesSelectedModal[id];
        state.eventDatesEditedModal[id] = {
          ...occurrence,
          venue,
          spaces,
          mustUpdate: true,
        };
      });
      if (state.allOccurrencesCheck) {
        const occurrence = state.eventDateCheckAll;
        state.eventDateCheckAll = {
          ...occurrence,
          venue,
          spaces,
        };
        return;
      }
      state.eventDateCheckAll = {} as EventOccurrenceUpdateCheckAllProps;
    },
    addEditedDateForAllSelected: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<{
        start: string;
        end: string;
      }>
    ) => {
      const { start, end } = action.payload;
      Object.keys(state.eventDatesSelectedModal).forEach((id) => {
        const occurrence = state.eventDatesSelectedModal[id];
        const updateStartDate = updateTimes(occurrence.start, start);
        const updateEndDate = updateTimes(occurrence.end, end);
        state.eventDatesEditedModal[id] = {
          ...occurrence,
          start: updateStartDate.toISOString(),
          end: updateEndDate.toISOString(),
          mustUpdate: true,
        };
      });
      const occurrence = state.eventDateCheckAll;
      if (state.allOccurrencesCheck) {
        state.eventDateCheckAll = {
          ...occurrence,
          start,
          end,
        };
        return;
      }
      state.eventDateCheckAll = {} as EventOccurrenceUpdateCheckAllProps;
    },
    setAllOccurencesCheck: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.allOccurrencesCheck = action.payload;
    },
    setCurrentPageSelectedDate: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<number>
    ) => {
      state.eventDatesFiltersModal.page = action.payload;
    },
    setOnlyUpdateEvent: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<ApiEvent>
    ) => {
      if (!state.event) return;
      state.event = action.payload;
    },
    setEventStatus: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<ApiEventStatus>
    ) => {
      if (!state.event) return;
      state.event.status = action.payload;
    },
    unloadDatesModal: (state: LocalEventDetailsSliceState) => {
      state.loadingModal = "idle";
      state.eventDatesScheduleModal = [];
      state.eventDatesCheckedModal = {};
      state.eventDatesSelectedModal = {};
      state.eventDatesEditedModal = {};
      state.allOccurrencesCheck = false;
      state.eventDatesFiltersModal = {
        pageSize: 10,
        total: 1,
        includeInactive: false,
        page: 1,
      };
      state.eventDateCheckAll = {};
    },
    unloadEventDetails: (state: LocalEventDetailsSliceState) => {
      state.event = null;
      state.comments = [];
      state.commentsTotal = 0;
      state.commentsCurrentPage = 1;
      state.eventDates = [];
      state.isInitialized = undefined;
      state.isVenueManager = false;
      state.eventDatesFilters = {
        eventDatesCurrentPage: 1,
        eventDatesTotalPages: 1,
        includeInactive: false,
        pageSize: 10,
      };
      state.requestEventServices = {} as Record<string, ApiRequest[]>;
      state.workingVenue = null;
      state.eventDatesSelectedModal = {};
      state.eventDatesEditedModal = {};
      state.questionResponses = [];
    },

    clearUpdatedOccurrences: (state: LocalEventDetailsSliceState) => {
      const newEventDates = state.eventDates.map((occurrence) => ({
        ...occurrence,
        mustUpdate: false,
      }));
      state.eventDates = [...newEventDates];
    },
    updateEventGroupStatus: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<{
        eventGroupId: string;
        eventGroupStatus: ApiGroupStatus;
      }>
    ) => {
      if (
        state.event &&
        state.event.eventGroup.id === action.payload.eventGroupId
      )
        state.event.eventGroup.status = action.payload.eventGroupStatus;
    },
    updateEventDetailStatus: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<{
        status: ApiEventStatus;
        isEventAdmin: boolean;
        statusUpdated: string;
        statusUpdatedBy: ApiUser;
        isVenueManager: boolean;
      }>
    ) => {
      if (!state.event) {
        return;
      }
      const {
        status,
        isEventAdmin,
        statusUpdated,
        statusUpdatedBy,
        isVenueManager,
      } = action.payload;

      state.event.status = status;
      state.transition = generateTransition(
        state.event,
        isEventAdmin,
        isVenueManager
      );
      state.canReOpen = canReOpen(state.event, isEventAdmin, isVenueManager);
      state.event.statusUpdated = statusUpdated;
      state.event.statusChangedBy = statusUpdatedBy;

      if (
        state.workingVenue?.approvalRequiredBy.find(
          (ap) => ap.id === statusUpdatedBy.id
        )
      ) {
        //if approver exists but approve is declined
        if (status === ApiEventStatus.confirmed) {
          const index = state.event.eventApprovers.findIndex(
            (ap) => ap.id === statusUpdatedBy.id
          );
          if (index !== -1) {
            state.event.eventApprovers[index] = {
              ...state.event.eventApprovers[index],
              status: ApiEventStatus.confirmed,
            };
            return;
          }
          //if approver does not exist in eventApprovers
          const approver = {
            ...statusUpdatedBy,
            status: ApiEventStatus.confirmed,
          } as ApiEventApprover;
          state.event.eventApprovers.unshift(approver);
          return;
        }
      }
    },
    updateEventDetailLastUpdated: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<{ lastUpdated: string; updatedBy: ApiUser }>
    ) => {
      if (state.event) {
        state.event.updated = action.payload.lastUpdated;
        state.event.updatedBy = action.payload.updatedBy;
      }
    },
    setEventTimelinePage: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<number>
    ) => {
      state.timeline.page = action.payload;
    },
    addEventAttachments: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<Attachment[]>
    ) => {
      state.eventAttachments = [...state.eventAttachments, ...action.payload];
    },
    deleteEventAttachment: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<{ uploadId: string }>
    ) => {
      const index = state.eventAttachments.findIndex(
        (attch) => attch.uploadId === action.payload.uploadId
      );

      if (index !== -1) {
        state.eventAttachments.splice(index, 1);
      }
    },
    setEventNotificationCurrentPage: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<number>
    ) => {
      state.notifications.page = action.payload;
    },
    addQuestionResponse: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<ApiQuestionResponse>
    ) => {
      state.questionResponses = [...state.questionResponses, action.payload];
    },
    removeQuestionResponse: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<string[]>
    ) => {
      const newQuestionResponses = state.questionResponses.filter((response) =>
        action.payload.every((id) => id !== response.id)
      );
      state.questionResponses = newQuestionResponses;
    },
    updateQuestionResponse: (
      state: LocalEventDetailsSliceState,
      action: PayloadAction<ApiQuestionResponse>
    ) => {
      const index = state.questionResponses.findIndex(
        (response) => response.id === action.payload.id
      );
      if (index !== -1) {
        state.questionResponses[index] = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initLoadEventDetails.fulfilled, (state, action) => {
      const {
        event,
        eventDates,
        requestsServices,
        initFrom,
        isEventAdmin,
        user,
        venueAttachments,
        eventAttachments,
        venue,
        isVenueManager,
        questionResponses,
      } = action.payload;

      state.event = event;
      state.isVenueManager = isVenueManager;
      state.isInitialized = initFrom;
      state.isUserAvailableToEditEvent =
        isEventAdmin ||
        isVenueManager ||
        (event &&
          event.status === ApiEventStatus.pending &&
          user &&
          user.id === event.createdBy.id);

      state.eventDates = eventDates.data;
      state.eventDatesFilters.eventDatesTotalPages = eventDates.total;

      state.workingVenue = venue;
      state.transition = generateTransition(
        action.payload.event,
        isEventAdmin,
        isVenueManager
      );
      state.canReOpen = state.event
        ? canReOpen(event, isEventAdmin, isVenueManager)
        : false;
      state.venueAttachments = venueAttachments.data.filter((item) =>
        item.contentType.includes("image")
      );
      state.eventAttachments = mapApiAttachments(eventAttachments.data);
      //should add and map request to each service in store
      requestsServices !== null &&
        event.services.map((eventService) => {
          const id = eventService.id;
          if (!requestsServices) {
            return null;
          }
          return (state.requestEventServices[id] = requestsServices.data.filter(
            (req) => req.serviceId === id
          ));
        });

      const userServicesHash = venue.services
        .filter((venueService) => venueService.requiresApproval)
        .reduce(
          (hash, venueService) => {
            venueService.assignees.forEach((assignee) => {
              if (assignee.type === "user") {
                hash[assignee.assignee.id] = venueService.service;
              }
            });
            return hash;
          },
          {} as Record<string, ApiServiceSumary>
        );

      state.userVenueServicesHash = userServicesHash;
      state.questionResponses = questionResponses.data;
    });
    builder.addCase(reloadEventRequests.fulfilled, (state, action) => {
      const { event, requestsServices } = action.payload;
      //should add and map request to each service in store
      requestsServices !== null &&
        event?.services.map((eventService) => {
          const id = eventService.id;
          if (!requestsServices) {
            return null;
          }
          return (state.requestEventServices[id] = requestsServices.data.filter(
            (req) => req.serviceId === id
          ));
        });
    });
    builder.addCase(loadEventDates.fulfilled, (state, action) => {
      state.eventDates = action.payload.data;
      state.eventDatesFilters.eventDatesTotalPages = action.payload.total;
    });

    builder.addCase(updateEvent.fulfilled, (state, action) => {
      state.event = action.payload;
    });

    builder.addCase(
      toggleActivateEventOccurrence.fulfilled,
      (state, action) => {
        const { occurrencesIds, type } = action.payload;
        const { includeInactive } = state.eventDatesFilters;

        if (!includeInactive && type === "deactivate") {
          const occurrencesCopy = state.eventDates.filter(
            (occ) => !occurrencesIds.includes(occ.id)
          );
          state.eventDates = occurrencesCopy;
          state.eventDatesFilters.eventDatesTotalPages =
            state.eventDatesFilters.eventDatesTotalPages -
            occurrencesIds.length;
          return;
        }

        const occurrencesCopy = state.eventDates.map((item) => {
          if (occurrencesIds.includes(item.id)) {
            return { ...item, active: type === "deactivate" ? false : true };
          }
          return item;
        });
        state.eventDates = occurrencesCopy;
      }
    );

    builder.addCase(updateSingleEventOccurrence.fulfilled, (state, action) => {
      const index = state.eventDates.findIndex(
        (item) => item.id === action.payload.response.id
      );
      if (index !== -1) {
        state.eventDates[index] = {
          ...action.payload.response,
          mustUpdate: false,
          conflicts: action.payload.conflicts,
        };
      }
      if (state.event) {
        // @ts-ignore :fingers_crossed:
        state.event.spaces = action.payload.response.spaces;
        state.event.venue = action.payload.response.venue;
      }
    });

    builder.addCase(
      updateMultipleEventOccurrences.fulfilled,
      (state, action) => {
        const reccurrences = action.payload;
        reccurrences.forEach((item) => {
          const index = state.eventDates.findIndex(
            (eventDate) => eventDate.id === item.id
          );
          if (index !== -1) {
            state.eventDates[index] = {
              ...item,
              mustUpdate: false,
            };
          }
        });
        if (state.event) {
          // @ts-ignore :fingers_crossed:
          state.event.spaces = action.payload[0].spaces;
          state.event.venue = action.payload[0].venue;
        }
      }
    );

    builder.addCase(updateAllEventOccurrences.fulfilled, (state, action) => {
      state.eventDates = action.payload;

      if (state.event) {
        // @ts-ignore :fingers_crossed:
        state.event.spaces = action.payload[0].spaces;
        state.event.venue = action.payload[0].venue;
      }
    });

    builder.addCase(createEventOccurrences.fulfilled, (state, action) => {
      const { eventDatesCurrentPage, pageSize } = state.eventDatesFilters;
      if (eventDatesCurrentPage !== 1) {
        state.eventDatesFilters.eventDatesCurrentPage = 1;
      }
      const addedItemsNumber = action.payload.length;
      let newEventDates = [...action.payload, ...state.eventDates];

      if (newEventDates.length > pageSize) {
        newEventDates = newEventDates.slice(0, pageSize);
      }
      state.eventDates = [...newEventDates];
      state.eventDatesFilters.eventDatesTotalPages =
        state.eventDatesFilters.eventDatesTotalPages + addedItemsNumber;
      if (state.event) {
        // @ts-ignore :fingers_crossed:
        state.event.spaces = action.payload[0].spaces;
        state.event.venue = action.payload[0].venue;
      }
    });

    builder.addCase(loadVenue.fulfilled, (state, action) => {
      state.workingVenue = action.payload;
    });

    eventCommentsHandlers(builder);
    loadEventDatesScheduleModalHandler(builder);

    /*Event Timeline  */
    eventTimelineHandler(builder);

    /*Event Notifications  */
    eventNotificationHandler(builder);
  },
});

export const {
  unloadEventDetails,
  unloadDatesModal,
  clearUpdatedOccurrences,
  addCheckedDate,
  addEditedDate,
  addSelectedDate,
  addEditedSpaceForAllSelected,
  addEditedDateForAllSelected,
  setCurrentPage,
  setEventStatus,
  setAllOccurencesCheck,
  setIncludeInactive,
  setCurrentPageSelectedDate,
  setCommentsCurrentPage,
  setApproverEventDetails,
  setEventNotificationCurrentPage,
  updateEventGroupStatus,
  updateEventDetailStatus,
  updateEventDetailLastUpdated,
  setEventTimelinePage,
  addEventAttachments,
  deleteEventAttachment,
  setOnlyUpdateEvent,
  addQuestionResponse,
  removeQuestionResponse,
  updateQuestionResponse,
} = localEventDetailsSlice.actions;

export default localEventDetailsSlice.reducer;
