import {
  Button,
  HStack,
  Icon,
  StackItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import {
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiChevronLeft,
  HiChevronRight,
} from "react-icons/hi";
import { getPaginationDetails } from "../../utils/getPaginationDetails";

const BLOCK_SIZE = 3;

export interface PagerProps {
  total: number;
  pageSize: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  showDetails?: boolean;
  justifyContent?: "flex-start" | "flex-end" | "center";
}

export const Pager = ({
  total,
  pageSize,
  currentPage,
  showDetails,
  onPageChange,
  justifyContent = "flex-end",
}: PagerProps) => {
  const showFirstLastPageArrows = useBreakpointValue({ base: false, md: true });
  const textColor = useColorModeValue("gray.700", "white");

  const numberOfPages = useMemo(() => {
    if (total === 0) return 0;

    return Math.ceil(total / pageSize);
  }, [total, pageSize]);

  const pages = useMemo(() => {
    const currBlock = Math.ceil(currentPage / BLOCK_SIZE);

    const result: number[] = [];

    const startingPageIndex = (currBlock - 1) * BLOCK_SIZE;
    for (let i = 1; i <= BLOCK_SIZE; i++) {
      if (startingPageIndex + i > numberOfPages) {
        return result;
      }

      result.push(startingPageIndex + i);
    }

    return result;
  }, [numberOfPages, currentPage]);

  const gotoPage = useCallback(
    (targetPage: number) => {
      const page =
        targetPage > numberOfPages
          ? numberOfPages
          : targetPage < 1
            ? 1
            : targetPage;

      onPageChange(page);
    },
    [onPageChange, numberOfPages]
  );

  const prevPage = useCallback(() => {
    gotoPage(currentPage - 1);
  }, [gotoPage, currentPage]);

  const nextPage = useCallback(() => {
    gotoPage(currentPage + 1);
  }, [gotoPage, currentPage]);

  const showDetailsStyles = {
    w: "50%",
    d: "flex",
    justifyContent: "flex-end",
  };

  const hideDetailsStyle = {
    w: "auto",
  };

  return numberOfPages > 0 ? (
    <HStack spacing={1} justifyContent={justifyContent}>
      {showDetails && (
        <StackItem w="50%">
          <Text as="span" py={4} color={textColor} fontSize="14px">
            {getPaginationDetails(total, pageSize, currentPage)}
          </Text>
        </StackItem>
      )}
      <StackItem
        sx={showDetails ? showDetailsStyles : hideDetailsStyle}
        display="flex"
        justifyContent="flex-end"
      >
        {showFirstLastPageArrows && (
          <Button
            isDisabled={numberOfPages === 1 || currentPage === 1}
            onClick={() => gotoPage(1)}
          >
            <Icon as={HiChevronDoubleLeft} />
          </Button>
        )}
        <Button
          isDisabled={numberOfPages === 1 || currentPage === 1}
          onClick={prevPage}
        >
          <Icon as={HiChevronLeft} />
        </Button>
        {pages[0] > 1 && (
          <Button onClick={() => gotoPage(currentPage - BLOCK_SIZE)}>
            ...
          </Button>
        )}
        {pages.map((page) => (
          <Button
            key={`pager-${page}`}
            colorScheme={page === currentPage ? "blue" : undefined}
            onClick={() => gotoPage(page)}
          >
            {page}
          </Button>
        ))}
        {pages[pages.length - 1] < numberOfPages && (
          <Button onClick={() => gotoPage(currentPage + BLOCK_SIZE)}>
            ...
          </Button>
        )}
        <Button
          isDisabled={numberOfPages === 1 || currentPage === numberOfPages}
          onClick={nextPage}
        >
          <Icon as={HiChevronRight} />
        </Button>
        {showFirstLastPageArrows && (
          <Button
            isDisabled={numberOfPages === 1 || currentPage === numberOfPages}
            onClick={() => gotoPage(numberOfPages)}
          >
            <Icon as={HiChevronDoubleRight} />
          </Button>
        )}
      </StackItem>
    </HStack>
  ) : null;
};
