import { ApiRequest } from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../components/auth/AuthProvider";
import { RootState, useThunkDispatch } from "../store";
import {
  loadBulkModeDataAsync,
  loadRequestsTotalsAsync,
  setBulkRequests,
} from "./../store/request-form/request-bulk-actions.slice";

export const useMultiSelect = () => {
  const {
    requests: bulkRequests,
    totalSelected,
    workflowRef,
  } = useSelector((store: RootState) => store.requestsBulkActionsSlice);

  const { apiClient, currentAccount } = useAuthentication();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();

  const handleSelectAll = useCallback(() => {
    if (workflowRef) {
      const allowedRequests = bulkRequests.filter(
        (bulkRequest) => bulkRequest.request.workflow.id === workflowRef.id
      );
      if (totalSelected === allowedRequests.length) {
        dispatch(
          setBulkRequests(
            bulkRequests.map((bulkItem) => {
              return { ...bulkItem, selected: false, canBeSelected: true };
            })
          )
        );
      } else {
        dispatch(
          setBulkRequests(
            bulkRequests.map((bulkItem) => {
              if (bulkItem.canBeSelected)
                return { ...bulkItem, selected: true };
              return bulkItem;
            })
          )
        );
      }
    }
  }, [totalSelected, bulkRequests, dispatch, workflowRef]);

  const handleSingleSelectStateChange = useCallback(
    (index: number, request: ApiRequest) => {
      if (totalSelected === 0) {
        thunkDispatch(
          loadBulkModeDataAsync({
            workflowRef: request.workflow,
            apiClient,
            accountId: currentAccount.id,
            firstSelectedItemIndex: index,
          })
        )
          .then(unwrapResult)
          .then(() => {
            thunkDispatch(
              loadRequestsTotalsAsync({
                apiClient,
                accountId: currentAccount.id,
              })
            );
          });
      } else {
        const updatedChildStates = [...bulkRequests];
        updatedChildStates[index] = {
          ...updatedChildStates[index],
          selected: !updatedChildStates[index].selected,
        };
        dispatch(setBulkRequests(updatedChildStates));
      }
    },
    [
      dispatch,
      totalSelected,
      bulkRequests,
      apiClient,
      currentAccount.id,
      thunkDispatch,
    ]
  );
  return {
    handleSelectAll,
    handleSingleSelectStateChange,
  };
};
