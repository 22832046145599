import {
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  StackItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiTaskBook,
  ApiTaskBookType,
  ApiWorkflowReportingCategorySummary,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { BiCheck } from "react-icons/bi";
import { FaPlus, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { ReportingCategoryAutocomplete } from "../../../components/selects/ReportingCategoryAutocomplete";
import { RootState, useThunkDispatch } from "../../../store";
import {
  addScheduledRequestTaskBooks,
  removeNewScheduledRequestTaskBook,
} from "../../../store/scheduled-request-form/schedule-request-form.slice";
import { removeScheduledRequestProp } from "../../../store/scheduled-request-form/thunks";
import { styleClicked, styleNotClicked } from "../../../utils/buttonsStyle";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";

interface ScheduledRequestTaskBooksProps {
  isEdit: boolean;
  activeStep: number;
  totalSteps: number;
}

export const ScheduledTaskBooksForm: FC<ScheduledRequestTaskBooksProps> = ({
  isEdit,
  activeStep,
  totalSteps,
}) => {
  const firstStackColor = useColorModeValue("gray.50", "whiteAlpha.200");
  const buttonBgColor = useColorModeValue("blue.500", "whiteAlpha.300");
  const filterButtonBgColor = useColorModeValue("white", "gray.700");
  const filterButtonColor = useColorModeValue("blue.500", "white");
  const { scheduledRequest, scheduledRequestTaskBooks } = useSelector(
    (state: RootState) => state.scheduleRequestForm,
  );

  const styleClickedMemorized = useMemo(() => styleClicked(), []);

  const styleNotClickedMemorized = useMemo(
    () => styleNotClicked(filterButtonColor, filterButtonBgColor),
    [filterButtonColor, filterButtonBgColor],
  );

  const [category, setCategory] = useState(scheduledRequest.reportingCategory);
  const [filteredTaskBooks, setFilteredTaskBooks] = useState<ApiTaskBook[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [taskBookType, setTaskBookType] = useState<ApiTaskBookType>();

  const { apiClient, currentAccount } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const checkIcon = useMemo(() => <Icon as={BiCheck} mr={1} />, []);

  const handleOnChangeCategory = useCallback(
    (value: ApiWorkflowReportingCategorySummary | null) => {
      setCategory(value);
    },
    [],
  );

  const handleOnChangeSearch = useCallback((event: any) => {
    const { value } = event.target;
    setSearchValue(value as string);
  }, []);

  const findTaskbooks = useCallback(async () => {
    const response = await apiClient.findTaskbooks(currentAccount.id, {
      search: searchValue,
      category: category?.id,
      type: taskBookType,
    });

    const taskbooksMap = new Map(
      response.data.map((taskbook) => [taskbook.id, taskbook]),
    );
    scheduledRequestTaskBooks.forEach((item) => {
      taskbooksMap.has(item.id) && taskbooksMap.delete(item.id);
    });

    setFilteredTaskBooks(Array.from(taskbooksMap.values()));
  }, [
    taskBookType,
    searchValue,
    apiClient,
    category,
    currentAccount.id,
    scheduledRequestTaskBooks,
  ]);

  const handleOnClickRemoveTaskBook = useCallback(
    async (taskbookId: string) => {
      if (scheduledRequest.id) {
        await thunkDispatch(
          removeScheduledRequestProp({
            accountId: currentAccount.id,
            apiClient,
            prop: "taskbook",
            scheduledRequestId: scheduledRequest.id,
            id: taskbookId,
          }),
        );
      }
      dispatch(removeNewScheduledRequestTaskBook(taskbookId));
    },
    [
      apiClient,
      currentAccount.id,
      dispatch,
      scheduledRequest.id,
      thunkDispatch,
    ],
  );

  const handleChangeFilter = useCallback((type?: ApiTaskBookType) => {
    setTaskBookType(type);
  }, []);

  useEffect(() => {
    findTaskbooks();
  }, [findTaskbooks, category, searchValue, taskBookType]);

  const handleAddTaskbook = useCallback(
    (taskbook: ApiTaskBook) => {
      dispatch(addScheduledRequestTaskBooks(taskbook));
    },
    [dispatch],
  );

  return (
    <>
      <Heading my={4} fontSize={["2xl", "3xl"]}>
        {isEdit ? "Update" : "Create"}{" "}
        {`Schedule Request (${activeStep} of ${totalSteps})`}
      </Heading>
      <Text fontSize="lg">
        <strong>Add Taskbooks</strong> (Optional)
      </Text>

      <Stack
        p={4}
        my={4}
        spacing={4}
        flexDir="row"
        flexWrap="wrap"
        borderRadius={6}
        alignItems="center"
        bgColor={firstStackColor}
      >
        <StackItem
          mt={2}
          minH="40px"
          display="flex"
          flexWrap="wrap"
          gap={2}
          alignItems="flex-end"
          width={["100%", "100%", "100%", "56%"]}
        >
          <Button
            onClick={() => handleChangeFilter(undefined)}
            sx={
              taskBookType === undefined
                ? styleClickedMemorized
                : styleNotClickedMemorized
            }
          >
            {taskBookType === undefined && checkIcon} All
          </Button>
          <Button
            onClick={() => handleChangeFilter(ApiTaskBookType.passfail)}
            sx={
              taskBookType === ApiTaskBookType.passfail
                ? styleClickedMemorized
                : styleNotClickedMemorized
            }
          >
            {taskBookType === ApiTaskBookType.passfail && checkIcon} Pass/Fail
          </Button>
          <Button
            onClick={() => handleChangeFilter(ApiTaskBookType.checklist)}
            sx={
              taskBookType === ApiTaskBookType.checklist
                ? styleClickedMemorized
                : styleNotClickedMemorized
            }
          >
            {taskBookType === ApiTaskBookType.checklist && checkIcon} Checklist
          </Button>
          <Button
            onClick={() => handleChangeFilter(ApiTaskBookType.scoring)}
            sx={
              taskBookType === ApiTaskBookType.scoring
                ? styleClickedMemorized
                : styleNotClickedMemorized
            }
          >
            {taskBookType === ApiTaskBookType.scoring && checkIcon} Scoring
          </Button>
        </StackItem>

        <StackItem
          mx={[0, 0, 4]}
          flexDir="column"
          width={["100%", "100%", "50%", "44%"]}
        >
          <ReportingCategoryAutocomplete
            value={category}
            onChange={handleOnChangeCategory}
          />
        </StackItem>

        <StackItem
          display="inline-flex"
          flexDir="column"
          width={["100%", "100%", "50%", "100%"]}
        >
          <InputGroup>
            <Input
              placeholder="Search task books"
              onChange={handleOnChangeSearch}
            />
            <InputLeftElement
              zIndex={0}
              pointerEvents="none"
              children={<Icon as={FaSearch} color="gray.300" />}
            />
          </InputGroup>
        </StackItem>

        <Divider />

        {filteredTaskBooks?.length && (
          <>
            {filteredTaskBooks.map((taskbook) => (
              <StackItem
                py={1}
                display="flex"
                w="100%"
                fontSize="sm"
                flexWrap="wrap"
                key={taskbook.id}
                alignItems="flex-end"
              >
                <Text
                  as="span"
                  mb={["8px", "8px", 0]}
                  minW={["100%", "100%", "calc(100% - 128px)"]}
                  wordBreak="break-word"
                >
                  {taskbook.name + " "} {capitalizeFirstLetter(taskbook.type)}
                </Text>
                <Button
                  ml={[0, 0, "8px"]}
                  w="120px"
                  size="xs"
                  color="white"
                  bgColor={buttonBgColor}
                  justifyContent="flex-end"
                  _hover={{ backgroundColor: "none" }}
                  onClick={() => handleAddTaskbook(taskbook)}
                >
                  <Icon as={FaPlus} mr={2} />
                  Add Task Book
                </Button>
              </StackItem>
            ))}
          </>
        )}
      </Stack>
      <Divider />

      <Stack
        my={4}
        spacing={6}
        flexDir="row"
        flexWrap="wrap"
        alignItems="flex-end"
      >
        <Text color="gray.500">
          {scheduledRequestTaskBooks.length} task books added...
        </Text>
        {scheduledRequestTaskBooks.length > 0 &&
          scheduledRequestTaskBooks.map((taskbook, index) => (
            <Flex
              w="100%"
              key={index + taskbook.id}
              justifyContent="space-between"
            >
              <StackItem w="calc(100% - 82px)">
                {taskbook.name}
                <Text as="span" color="blue.500" ml={2} isTruncated>
                  {capitalizeFirstLetter(taskbook.type)}
                </Text>
              </StackItem>
              <Button
                w="70px"
                size="xs"
                variant="outline"
                borderColor={buttonBgColor}
                onClick={() => handleOnClickRemoveTaskBook(taskbook.id)}
              >
                Remove
              </Button>
            </Flex>
          ))}
      </Stack>
    </>
  );
};
