import { LockIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useScreenBreakpoints } from "../../../../hooks/useScreenBreakpoints";
import { RootState } from "../../../../store";
import { Header } from "./Header";
import { Requests } from "./request-list/Requests";
import { DesktopScheduling } from "./Scheduling.desktop";
import { TabletScheduling } from "./Scheduling.tablet";

type SchedulingProps = {};
export const Scheduling: FC<SchedulingProps> = () => {
  const { project } = useSelector((state: RootState) => state.projectPageSlice);
  const { isDesktop } = useScreenBreakpoints();
  const { colorMode } = useColorMode();

  return (
    <>
      {project && (
        <>
          <Header project={project} />
          <Divider my={3} />
          {isDesktop ? (
            <DesktopScheduling>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  gap: 4,
                  w: "100%",
                }}
              >
                <Requests project={project} />
              </Box>
              <Flex
                gap={2}
                pt={20}
                color="gray"
                fontStyle="italic"
                alignContent="center"
                alignItems="center"
                h="100%"
                flexDirection="column"
                bg={colorMode === "light" ? "gray.50" : "gray.900"}
                opacity={0.5}
              >
                <LockIcon />
                Timeline
              </Flex>
            </DesktopScheduling>
          ) : (
            <TabletScheduling>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  gap: 4,
                  w: "100%",
                }}
              >
                <Requests project={project} />
              </Box>
              <Box
                gap={2}
                fontStyle="italic"
                bg={colorMode === "light" ? "gray.50" : "gray.900"}
                h="100%"
                pt="10"
              >
                <VStack color="gray">
                  <LockIcon />
                  <Text>Timeline</Text>
                </VStack>
              </Box>
            </TabletScheduling>
          )}
        </>
      )}
    </>
  );
};
