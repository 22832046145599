import { Flex, Tag, TagLabel, TagLeftIcon, Tooltip } from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { BiBuildings, BiTask } from "react-icons/bi";
import { MdOutlineAttachFile } from "react-icons/md";
import { createPath, useNavigate } from "react-router";

export const RequestRowIcons = ({ request }: { request: ApiRequest }) => {
  const navigate = useNavigate();
  const assetsLink = createPath({
    pathname: `/requests/${request.key}`,
    hash: "#assets",
  });
  const taskbooksLink = createPath({
    pathname: `/requests/${request.key}`,
    hash: "#taskbooks",
  });

  return (
    <Flex gap={2} w="100%" justifyContent="right">
      {request.totalAttachments > 0 && (
        <Tooltip label={`${request.totalAttachments} attachments`}>
          <Tag variant="subtle">
            <TagLeftIcon as={MdOutlineAttachFile} />
            <TagLabel>{request.totalAttachments}</TagLabel>
          </Tag>
        </Tooltip>
      )}
      {request.totalAssets > 0 && (
        <Tooltip label={`${request.totalAssets} assets`}>
          <Tag
            variant="subtle"
            onClick={(e) => {
              e.preventDefault();
              navigate(assetsLink);
            }}
          >
            <TagLeftIcon as={BiBuildings} />
            <TagLabel>{request.totalAssets}</TagLabel>
          </Tag>
        </Tooltip>
      )}
      {request.totalTaskbooks > 0 && (
        <Tooltip label={`${request.totalTaskbooks} taskbooks`}>
          <Tag
            variant="subtle"
            onClick={(e) => {
              e.preventDefault();
              navigate(taskbooksLink);
            }}
          >
            <TagLeftIcon as={BiTask} />
            <TagLabel>{request.totalTaskbooks}</TagLabel>
          </Tag>
        </Tooltip>
      )}
    </Flex>
  );
};
