import { ApiLocation } from "@operations-hero/lib-api-client";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../components/auth/AuthProvider";
import { RootState } from "../store";
import { useLocationUtils } from "../utils/locationUtils";

export const useAllowedLocations = () => {
  const {
    policyMap,
    workflowMap,
    locationMap,
    locations: locationsLocalCache,
  } = useSelector((state: RootState) => state.localCache);
  const { isProductAdmin } = useAuthentication();
  const { getChildrenId } = useLocationUtils();

  const allowedLocations = useMemo(() => {
    const workflows: string[] = [];
    const locations: ApiLocation[] = [];
    let locationsIds: string[] = [];

    Object.entries(workflowMap).map(([value]) => workflows.push(value));

    let isWorkflowAdmin: boolean = false;
    let allLocationsAllowed: boolean = false;
    workflows.forEach((workflow) => {
      const policy = policyMap[workflow];
      const { admin, reviewer, technician, approver } = policy;
      const locationRestrictedFields = [reviewer, technician, approver];

      locationRestrictedFields.forEach((x) => {
        if (typeof x !== "boolean")
          locationsIds = [...locationsIds, ...x.locations];
        else {
          if (x === true) allLocationsAllowed = true;
        }
      });

      if (admin) isWorkflowAdmin = true;
      locationsIds = Array.from(new Set(locationsIds));
    });

    if (locationsIds !== undefined) {
      for (let key of locationsIds) {
        const childAndFatherLocationIds = getChildrenId([locationMap[key]]);
        childAndFatherLocationIds.map((id) =>
          locationMap[id].active ? locations.push(locationMap[id]) : null
        );
      }
    }

    if (isProductAdmin || isWorkflowAdmin || allLocationsAllowed)
      return locationsLocalCache;
    else return locations;
  }, [
    isProductAdmin,
    locationMap,
    locationsLocalCache,
    policyMap,
    workflowMap,
    getChildrenId,
  ]);

  return { allowedLocations };
};
