import { Flex, LinkBoxProps } from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { SelectableRequest } from "./containers/SelectableRequest";
import { SelectableRequestMobile } from "./containers/SelectableRequestMobile";

export interface RequestRowContainerProps extends LinkBoxProps {
  selected?: boolean;
  setSelectedState?: () => void;
  linkTo: string;
  canBeSelected: boolean;
  currentPage?: number;
  screenMode: {
    isMobile?: boolean;
    isTablet?: boolean;
    isDesktop?: boolean;
  };
  onLoad?: () => void;
}
export const RequestRowContainer: FC<RequestRowContainerProps> = ({
  selected,
  setSelectedState,
  linkTo,
  children,
  canBeSelected = true,
  currentPage,
  screenMode: { isMobile },
  onLoad,
}) => {
  useEffect(() => {
    onLoad && onLoad();
  }, [onLoad]);

  return (
    <Flex w="100%">
      {!isMobile && (
        <Flex display="flex" minW="100%">
          <SelectableRequest
            canBeSelected={canBeSelected}
            linkTo={linkTo}
            setSelectedState={setSelectedState}
            selected={selected}
            currentPage={currentPage}
          >
            {children}
          </SelectableRequest>
        </Flex>
      )}

      {isMobile && (
        <Flex display="flex" minW="100%">
          <SelectableRequestMobile
            canBeSelected={canBeSelected}
            linkTo={linkTo}
            setSelectedState={setSelectedState}
            selected={selected}
          >
            {children}
          </SelectableRequestMobile>
        </Flex>
      )}
    </Flex>
  );
};
