import {
  Box,
  Button,
  Center,
  Container,
  CSSObject,
  Flex,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  ApiComment,
  ApiLaborTransaction,
  ApiPagedResult,
  ApiRequest,
  ApiRequestStatus,
  ApiRequestTaskBook,
  ApiWorkflow,
  WorkflowPolicy,
} from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { useShowToast } from "../../hooks/showToast";
import { useMultiSelect } from "../../hooks/useMultiSelect";
import { useQueryString } from "../../hooks/useQueryString";
import { useScreenBreakpoints } from "../../hooks/useScreenBreakpoints";
import { RootState, useThunkDispatch } from "../../store";
import { setNewRequestDialogIsOpen } from "../../store/new-request-form.slice";
import {
  loadRequestsByStatus,
  unloadColumnView,
} from "../../store/request-column-view.slice";
import {
  setVisibleSchemaFields,
  unloadForm,
  updateTaskBooksCompleted,
} from "../../store/request-form/request-form.slice";
import { createTransaction } from "../../store/request-form/thunks/createTransaction.thunk";
import { initRequestFormFromList } from "../../store/request-form/thunks/initRequestFormFromList.thunk";
import { saveComment } from "../../store/request-form/thunks/saveComment.thunk";
import { updateRequest } from "../../store/request-form/thunks/updateRequest.thunk";
import {
  initRequestList,
  loadRequests,
  setInitCompleted,
  unloadRequestList,
  updateRequestFilters,
} from "../../store/request-list.slice";
import {
  initDisplayModes,
  unloadRequestsSlice,
} from "../../store/requests.slice";
import { loadTransitionMap } from "../../store/requests/request-transitions.thunk";
import { ChangeModal } from "../request-form/ChangeModal";
import { ChangeModalSkeleton } from "../request-form/ChangeModalSkeleton";
import { TransactionLaborerWithGroups } from "../request-form/transactions/labors/LaborTransactionForm";
import { ColumnView } from "./column-view/ColumnView";
import { StandardRequest } from "./containers/StandardRequest";
import { RequestListHeader } from "./list-headers/RequestListHeader";
import { RequestDisplayCount } from "./RequestDisplayCount";
import { RequestPager } from "./RequestPager";
import { RequestRow } from "./RequestRow";
import { RequestRowContainer } from "./RequestRowContainer";
import { SkeletonRequests } from "./SkeletonRequests";

export interface RequestFiltersProps {
  showFilterAndSort: boolean;
}
export type ViewMode = "bulk" | "normal";

export enum DateFilter {
  created = "Created",
  due = "Due Date",
  start = "Start Date",
  updated = "Updated",
  completed = "Completed",
  statusUpdated = "Status Updated",

  last7d = "Last 7 days",
  next7d = "Next 7 days",
  last30d = "Last 30 days",
  next30d = "Next 30 days",
  thisWeek = "This week (Sun - Sat)",
  lastWeek = "Last Week(Sun - Sat)",
  nextWeek = "Next Week (Sun - Sat)",
  thisMonth = "This Month (1st- EOM)",
  lastMonth = "Last Month (1st- EOM)",
  nextMonth = "Next month (1st- EOM)",
  thisfiscalyear = "This fiscal year",
  lastfiscalyear = "Last fiscal year",
  custom = "Custom",
}

const rowViewScrollStyles: CSSObject = {
  display: "flex",
  flexDir: "column",
  gap: "4",
  minH: "6xl",
};

const columnViewScrollStyles: CSSObject = {
  display: "flex",
  flexDir: "column",
  gap: "4",
  flex: "1",
  height: "100%",
  overflow: "hidden",
};

export const Requests = () => {
  const { currentAccount, currentUser, apiClient, isProductAdmin } =
    useAuthentication();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const toast = useToast();
  const { query } = useQueryString({});

  const { loading, requests, queryStringFilter, filters, initCompleted } =
    useSelector((state: RootState) => state.requestList);

  const { displayMode, initDisplayConfig } = useSelector(
    (state: RootState) => state.requestsSlice
  );

  const { isRequesterOnly, policyMap, userSettings } = useSelector(
    (state: RootState) => state.localCache
  );
  const { changeModal, request, taskBooksCompleted } = useSelector(
    (state: RootState) => state.requestForm
  );

  const { requests: requestsWihBulkStatus, selectMode } = useSelector(
    (store: RootState) => store.requestsBulkActionsSlice
  );

  const [isChangeModalSaving, setIsChangeModalSaving] = useState(false);
  const [isChangeModalLoading, setIsChangeModalLoading] = useState(false);
  const [engine, setEngine] = useState<SchemaRulesEngine>();
  const [loadingCount, setLoadingCount] = useState(0);

  const screenModes = useScreenBreakpoints();

  const showToast = useShowToast();

  const handleChangeTaskBooksCompleted = useCallback(
    (value: boolean) => {
      dispatch(updateTaskBooksCompleted(value));
    },
    [dispatch]
  );

  const handleSkeletonModalOnClose = useCallback(() => {
    setIsChangeModalLoading(false);
  }, []);

  const handleStatusChange = useCallback(
    ({
      request,
      status,
      workflow,
      policy,
    }: {
      request: ApiRequest;
      status: ApiRequestStatus;
      workflow: ApiWorkflow;
      policy: WorkflowPolicy;
    }) => {
      // redux, init request form,
      setIsChangeModalLoading(true);
      thunkDispatch(
        initRequestFormFromList({
          apiClient,
          account: currentAccount,
          user: currentUser,
          isProductAdmin,
          workflow,
          request,
        })
      )
        .then(unwrapResult)
        .then(({ schemaFields, taskbooks: taskbooksThunk }) => {
          const engine = new SchemaRulesEngine({
            account: currentAccount,
            form: "full",
            policy,
            workflow,
            user: currentUser,
            schemaFields,
            isProductAdmin,
          });

          setEngine(engine);

          const fields = engine.getVisibleFields({
            request,
            includeDeleted: false,
          });

          dispatch(setVisibleSchemaFields(fields));
          setIsChangeModalLoading(false);
          return thunkDispatch(
            updateRequest({
              apiClient,
              account: currentAccount,
              delta: { status },
              isChangeModal: false,
              idOrKey: request.key,
              request,
              isProductAdmin,
              engine,
            })
          ).then(async (err: any) => {
            if (err.payload.changeVerification || err.payload.delta) {
              if (
                err.payload.changeVerification.delta.status === "review" ||
                err.payload.changeVerification.delta.status === "closed"
              ) {
                const { data: taskbooks } =
                  request.totalTaskbooks > 0 &&
                  taskbooksThunk.length !== request.totalTaskbooks
                    ? await apiClient.findRequestTaskbooks(
                        currentAccount.id,
                        request.id
                      )
                    : taskbooksThunk.length > 0
                      ? { data: taskbooksThunk }
                      : ({
                          data: [] as ApiRequestTaskBook[],
                        } as ApiPagedResult<ApiRequestTaskBook>);

                if (
                  workflow?.requireTaskbooksCompletion &&
                  taskbooks.some((taskBook) => taskBook.completed === null)
                ) {
                  handleChangeTaskBooksCompleted(false);
                  toast({
                    description: err.status
                      ? `Status Code: ${err.status}`
                      : undefined,
                    duration: 2500,
                    id: "taskbooks-wo-err",
                    isClosable: true,
                    position: "top",
                    status: "error",
                    title:
                      "Please complete all taskbooks before completing the request.",
                  });
                  return;
                }
                handleChangeTaskBooksCompleted(true);
                return;
              }
              handleChangeTaskBooksCompleted(true);
              return;
            }
            handleChangeTaskBooksCompleted(true);
          });
        });
    },
    [
      thunkDispatch,
      dispatch,
      handleChangeTaskBooksCompleted,
      toast,
      apiClient,
      currentAccount,
      currentUser,
      isProductAdmin,
    ]
  );

  const handleOnSaveLaborTransaction = useCallback(
    (laborTransaction?: TransactionLaborerWithGroups) => {
      if (!laborTransaction) return;
      if (!laborTransaction.laborer.isGroup) {
        thunkDispatch(
          createTransaction({
            apiClient,
            account: currentAccount,
            transaction: laborTransaction,
            request,
          })
        )
          .then(unwrapResult)
          .catch(() => {
            showToast("error", "Error Saving Labor Transaction");
          });
      }

      if (
        laborTransaction.laborer.isGroup &&
        laborTransaction.laborer.groupMembers
      ) {
        const { groupMembers } = laborTransaction.laborer;
        Promise.all(
          groupMembers.map((member) => {
            return thunkDispatch(
              createTransaction({
                apiClient,
                account: currentAccount,
                transaction: { ...laborTransaction, laborer: member },
                request,
              })
            );
          })
        )
          .then(() => {
            showToast("success", "Labor transactions created successfully");
          })
          .catch((error) => {
            showToast("error", "Error Saving Labor Transactions");
          });
      }
    },
    [apiClient, currentAccount, request, showToast, thunkDispatch]
  );

  const handleChangeModalSave = useCallback(
    ({
      delta,
      comment,
      laborTransaction,
    }: {
      delta: Partial<ApiRequest>;
      comment?: ApiComment;
      laborTransaction?: ApiLaborTransaction;
    }) => {
      setIsChangeModalSaving(true);
      thunkDispatch(
        updateRequest({
          account: currentAccount,
          apiClient: apiClient,
          idOrKey: request!.key,
          request: request as ApiRequest,
          delta,
          engine: engine!,
          isChangeModal: true,
          isProductAdmin,
        })
      )
        .then(
          () => {
            toast({
              duration: 1500,
              id: "update-wo",
              isClosable: true,
              position: "top",
              status: "success",
              title: "Request Saved",
            });

            // reload list to remove issue from list (depending on filters)
            thunkDispatch(loadRequests({ apiClient, account: currentAccount }))
              .unwrap()
              .then((result) => {
                thunkDispatch(
                  loadTransitionMap({ requests: result.requests.data })
                );
              });
          },
          (err) => {
            if (err.changeVerification || err.delta) {
              return;
            }

            toast({
              description: err.status
                ? `Status Code: ${err.status}`
                : undefined,
              duration: 1500,
              id: "update-wo-err",
              isClosable: true,
              position: "top",
              status: "error",
              title: "Error Saving Request",
            });
          }
        )
        .finally(() => {
          setIsChangeModalSaving(false);
        });

      if (comment) {
        thunkDispatch(
          saveComment({
            apiClient,
            account: currentAccount,
            comment,
            request: request!,
          })
        )
          .then(unwrapResult)
          .catch((e) => {
            toast({
              duration: 1500,
              id: "update-wo-err-comment",
              isClosable: true,
              position: "top",
              status: "error",
              title: "Error Saving Comment",
            });
          });
      }

      handleOnSaveLaborTransaction(laborTransaction);
    },
    [
      thunkDispatch,
      currentAccount,
      apiClient,
      request,
      engine,
      isProductAdmin,
      handleOnSaveLaborTransaction,
      toast,
    ]
  );

  const handleChangeModalCancel = useCallback(() => {
    thunkDispatch(unloadForm());
  }, [thunkDispatch]);

  const handeCreateRequest = useCallback(() => {
    dispatch(setNewRequestDialogIsOpen(true));
  }, [dispatch]);

  /*
   * 👋 hello future devs,
   * The idea is this component controls loading of data and the page lifecycle.
   * It is easy to trigger a double load causing strange results from useEffect
   *
   * The lifecycle is as follows:
   * 1) check to see if there is a query string from a deep link, update redux if needed.
   * 2) load the initial data and the user's saved filters.
   * 3) Other components update the filters, triggering a reload of the list
   * 4) When the component is unloaded, clean up redux to reduce overal memory usage.
   */
  const initOnce = useRef(false);

  useEffect(() => {
    // We obtain the display mode from the filters. It will be override if null.
    // This executed before the requests filters initialization.
    let decodedFilter = null;
    try {
      decodedFilter = JSON.parse(
        decodeURIComponent(atob(query.get("filter") || ""))
      );
    } catch (e) {
      decodedFilter = null;
    }

    thunkDispatch(initDisplayModes({ filters: decodedFilter }));
  }, [thunkDispatch, userSettings, query]);

  useEffect(() => {
    if (initOnce.current) {
      return;
    }

    initOnce.current = true;
    // Loads user settings and sets up default filter
    thunkDispatch(
      initRequestList({
        account: currentAccount,
        apiClient,
        currentPage: filters.currentPage > 1 ? filters.currentPage : undefined,
      })
    ).then(() => {
      // parse query string before initializing making the first network call.
      // inline immediate execute try catch;
      let decodedFilter = null;
      try {
        decodedFilter = JSON.parse(
          decodeURIComponent(atob(query.get("filter") || ""))
        );
      } catch (e) {
        decodedFilter = null;
      }

      if (decodedFilter) {
        dispatch(
          updateRequestFilters({ ...decodedFilter, displayMode: displayMode })
        );
      }
      dispatch(setInitCompleted(true));
    });
  }, [
    dispatch,
    thunkDispatch,
    apiClient,
    currentAccount,
    query,
    filters.currentPage,
    displayMode,
  ]);

  useEffect(() => {
    if (!initCompleted || !initDisplayConfig) {
      return;
    }

    if (displayMode === "row") {
      thunkDispatch(
        loadRequests({
          apiClient,
          account: currentAccount,
        })
      )
        .unwrap()
        .then(({ requests }) => {
          thunkDispatch(loadTransitionMap({ requests: requests.data }));
        });
    }

    if (displayMode === "column") {
      thunkDispatch(
        loadRequestsByStatus({
          apiClient,
          accountId: currentAccount.id,
        })
      );
    }
  }, [
    initDisplayConfig,
    displayMode,
    apiClient,
    currentAccount,
    thunkDispatch,
    initCompleted,
    filters.workflows,
    filters.statuses,
    filters.priorities,
    filters.locations,
    filters.assignees,
    filters.requesters,
    filters.categories,
    filters.reasons,
    filters.created,
    filters.updated,
    filters.statusUpdated,
    filters.start,
    filters.due,
    filters.completed,
    filters.persons,
    filters.date,
    filters.dateRelative,
    filters.currentPage,
    filters.pageSize,
    filters.search,
    filters.quickFilter,
    filters.types,
    filters.assets,
    filters.events,
    filters.services,
    filters.scheduledRequest,
  ]);

  // Set query string for filter changes.
  useEffect(() => {
    if (!queryStringFilter || !initCompleted) {
      return;
    }

    const currentValue = query.get("filter");
    const mustUpdate = currentValue !== queryStringFilter;

    if (mustUpdate) {
      // this avoids react router unmounting/remounting the component
      // which was causing a bug when the query string was written
      // for the first time
      window.history.replaceState(
        null,
        "",
        `/requests?filter=${queryStringFilter}`
      );
    }
  }, [queryStringFilter, navigate, initCompleted, filters, query]);

  const { handleSelectAll, handleSingleSelectStateChange } = useMultiSelect();

  const isBulkAvailable = useCallback(
    (request: ApiRequest) => {
      const policy = policyMap[request.workflow.id];
      if (!policy) {
        return false;
      }
      const isTechinician =
        policy.technician && !policy.reviewer && !policy.admin;
      const isReviewer = policy.reviewer && !policy.admin;
      const isAdmin = policy.admin;
      return isTechinician || isReviewer || isAdmin || isProductAdmin;
    },
    [policyMap, isProductAdmin]
  );

  const setScrollPositionToSession = useCallback(() => {
    sessionStorage.setItem("scroll", window.scrollY.toString());
  }, []);

  const handleChildLoad = useCallback(() => {
    setLoadingCount((prev) => prev + 1);
  }, []);

  useEffect(() => {
    if (
      loading === "succeeded" &&
      loadingCount === requests.length &&
      requests.length > 0 &&
      sessionStorage.getItem("from") === "request"
    ) {
      const position = parseFloat(sessionStorage.getItem("scroll") || "0");
      window.scrollTo(0, position || 0);
      setLoadingCount(0);
      sessionStorage.removeItem("from");
    }
  }, [loading, loadingCount, requests.length]);

  useEffect(() => {
    return () => {
      dispatch(unloadColumnView());
      dispatch(unloadRequestList());
      thunkDispatch(unloadRequestsSlice());
    };
  }, [thunkDispatch, dispatch]);

  return (
    <Container
      maxWidth="8xl"
      sx={displayMode === "row" ? rowViewScrollStyles : columnViewScrollStyles}
    >
      <RequestListHeader mode={selectMode} handleSelectAll={handleSelectAll} />

      {displayMode === "column" && <ColumnView />}

      {displayMode === "row" && (
        <>
          {(loading === "idle" || loading === "pending") && (
            <Box pl="2">
              <SkeletonRequests includeBorder={true} />
            </Box>
          )}
          {loading === "succeeded" && requests.length > 0 && (
            <Stack gap={[4, null, 2]} pl="2">
              {requests.map((request, idx) => {
                return isBulkAvailable(request) ? (
                  <Box
                    onClick={setScrollPositionToSession}
                    key={`request:: ${request.key}`}
                  >
                    <RequestRowContainer
                      selected={requestsWihBulkStatus[idx].selected}
                      setSelectedState={() => {
                        handleSingleSelectStateChange(idx, request);
                      }}
                      linkTo={`/requests/${request.key}`}
                      canBeSelected={requestsWihBulkStatus[idx].canBeSelected}
                      currentPage={filters.currentPage}
                      screenMode={screenModes}
                      onLoad={handleChildLoad}
                    >
                      <RequestRow
                        key={`request::${request.id}`}
                        request={request}
                        onStatusChange={handleStatusChange}
                        selected={requestsWihBulkStatus[idx].selected}
                        screenMode={screenModes}
                      />
                    </RequestRowContainer>
                  </Box>
                ) : (
                  <StandardRequest
                    key={`request:: ${request.key}`}
                    onLoad={handleChildLoad}
                  >
                    <Box onClick={setScrollPositionToSession} key={request.id}>
                      <Link
                        as={RouterLink}
                        style={{
                          textDecoration: "none",
                        }}
                        to={`/requests/${request.key}`}
                        state={{
                          currentPage: filters.currentPage,
                        }}
                      >
                        <RequestRow
                          key={`request::${request.id}`}
                          request={request}
                          onStatusChange={handleStatusChange}
                          selected={requestsWihBulkStatus[idx].selected}
                          screenMode={screenModes}
                        />
                      </Link>
                    </Box>
                  </StandardRequest>
                );
              })}
            </Stack>
          )}

          {loading === "succeeded" && requests.length === 0 && (
            <Box pt={8}>
              {isRequesterOnly && !isProductAdmin ? (
                <Center pt={8}>
                  <Box textAlign="center">
                    <Text m={4}>You currently have no active requests.</Text>
                    <Button m={4} onClick={handeCreateRequest}>
                      Create a new request.
                    </Button>
                  </Box>
                </Center>
              ) : (
                <Text>No requests found matching the criteria</Text>
              )}
            </Box>
          )}

          {loading === "failed" && (
            <Box pt={8}>
              <Text>Error loading requests</Text>
            </Box>
          )}
          <SimpleGrid columns={screenModes.isMobile ? 1 : 2} pt={6}>
            {!screenModes.isMobile && (
              <Box pt={2}>
                <RequestDisplayCount />
              </Box>
            )}
            <Flex justifyContent={{ base: "center", md: "end", lg: "end" }}>
              <RequestPager disabled={selectMode === "bulk"} />
            </Flex>
          </SimpleGrid>
        </>
      )}
      {displayMode === "row" && isChangeModalLoading ? (
        <ChangeModalSkeleton
          isOpen={isChangeModalLoading}
          onCancel={handleSkeletonModalOnClose}
        />
      ) : (
        <>
          {displayMode === "row" &&
          taskBooksCompleted &&
          request &&
          (changeModal.changeVerification !== null ||
            changeModal.delta !== null) ? (
            <ChangeModal
              isOpen={
                taskBooksCompleted &&
                (changeModal.changeVerification !== null ||
                  changeModal.delta !== null)
              }
              onSave={handleChangeModalSave}
              onCancel={handleChangeModalCancel}
              isSaving={isChangeModalSaving}
            />
          ) : null}
        </>
      )}
    </Container>
  );
};
