import {
  ApiAccount,
  ApiClient,
  ApiInventoryRequest,
  ApiPagedResult,
  ApiRequestAttachment,
  ApiRequestTaskBook,
  ApiTransaction,
  ApiUser,
  WorkflowPolicy,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import {
  canReopen,
  generateTransition,
  isLocked,
} from "../request-form.helpers";
import { RequestSliceState } from "../request-form.slice";
import { loadLocationsGallery } from "./loadLocationGallery.thunk";

export interface InitRequestFormThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  user: ApiUser;
  key: string;
  isProductAdmin: boolean;
  hasInventory?: boolean;
}

export const initRequestForm = createAsyncThunk(
  "requests/initFullForm",
  async (params: InitRequestFormThunkParams, thunkAPI) => {
    const { apiClient, account, key, isProductAdmin, user, hasInventory } =
      params;
    const { dispatch } = thunkAPI;
    const projectSlug = key.split("-")[0];
    const { locationMap, categoriesMap, descendantsMap } = (
      thunkAPI.getState() as RootState
    ).localCache;

    const results = await Promise.allSettled([
      apiClient.getRequest(account.id, key),
      apiClient.getCurrentUserWorkflowContext(account.id, projectSlug),
    ]);

    const request = results[0].status === "fulfilled" ? results[0].value : null;

    const workflowContext =
      results[1].status === "fulfilled" ? results[1].value : null;

    const workflow = workflowContext
      ? workflowContext.workflow
      : await apiClient.getWorkflow(account.id, projectSlug);

    const policy = workflowContext ? workflowContext.policy : null;

    const [
      { data: schemaFields },
      { data: attachments },
      { data: taskbooks },
      expenses,
      labor,
      issuance,
      inventory,
    ] = await Promise.all([
      apiClient.findWorkflowSchemaFields(account.id, workflow.schema.id, {
        pageSize: 100,
      }),
      request
        ? apiClient.findRequestAttachments(account.id, request.key)
        : ({} as ApiPagedResult<ApiRequestAttachment>),
      request
        ? apiClient.findRequestTaskbooks(account.id, request.id)
        : ({} as ApiPagedResult<ApiRequestTaskBook>),
      request
        ? apiClient.findRequestTransactions(account.id, request.id, {
            type: "purchase",
          })
        : ({} as ApiPagedResult<ApiTransaction>),
      request
        ? apiClient.findRequestTransactions(account.id, request.id, {
            type: "labor",
          })
        : ({} as ApiPagedResult<ApiTransaction>),
      request && hasInventory
        ? apiClient.findRequestTransactions(account.id, request.id, {
            type: "issuance",
          })
        : ({} as ApiPagedResult<ApiTransaction>),
      request && hasInventory
        ? apiClient.findInventoryRequests(account.id, { request: request.id })
        : ({} as ApiPagedResult<ApiInventoryRequest>),
    ]);

    if (request && request.location) {
      dispatch(
        loadLocationsGallery({
          apiClient,
          accountId: account.id,
          locationId: request.location.id,
        })
      );
    }

    return {
      request,
      workflow,
      schemaFields,
      attachments,
      policy,
      isProductAdmin,
      taskbooks,
      locationMap,
      descendantsMap,
      categoriesMap,
      user,
      expenses,
      labor,
      issuance,
      inventory,
    };
  }
);

export const initRequestFormHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(initRequestForm.fulfilled, (state, action) => {
    const isCreator =
      action.payload.request?.createdBy.id === action.payload.user.id;
    const isRequester =
      action.payload.user.id === action.payload.request?.requester.id;
    const policies =
      action.payload.policy ??
      ({
        admin: false,
        approver: false,
        technician: false,
        reviewer: false,
        contractor: false,
        requester: isCreator || isRequester,
      } as WorkflowPolicy);

    const noPol =
      Object.values(policies).every((pol) => pol === false) &&
      !action.payload.isProductAdmin &&
      !action.payload.workflow.allowUsersToSubmitRequests;

    state.attachments = action.payload.attachments.map((x) => ({
      created: x.created,
      name: x.name,
      url: x.url,
      isNew: false,
      isUploading: false,
      uploadId: x.id,
      type: x.contentType,
    }));
    state.request = !noPol ? action.payload.request : null;
    state.schemaFields = action.payload.schemaFields;
    state.workflow = action.payload.workflow;
    state.taskbooks = action.payload.taskbooks;
    state.policy = policies;

    state.isCurrentRequester = isRequester;
    const { admin, approver, technician, reviewer, requester, contractor } =
      policies;
    state.isRequesterOnly =
      requester &&
      !approver &&
      !contractor &&
      !technician &&
      !reviewer &&
      !admin &&
      !action.payload.isProductAdmin;

    state.isContractor =
      contractor &&
      state.isCurrentRequester &&
      !approver &&
      !technician &&
      !reviewer &&
      !admin &&
      !action.payload.isProductAdmin;

    state.isContractorOnly =
      contractor &&
      !state.isCurrentRequester &&
      !approver &&
      !technician &&
      !reviewer &&
      !admin &&
      !action.payload.isProductAdmin;
    state.isApproverOnly =
      approver &&
      !technician &&
      !reviewer &&
      !admin &&
      !action.payload.isProductAdmin;

    state.canReopen =
      action.payload.request && !noPol
        ? canReopen(
            state.workflow!,
            state.policy!,
            action.payload.request,
            action.payload.isProductAdmin
          )
        : false;
    state.transition =
      action.payload.request && !noPol
        ? generateTransition(
            action.payload.workflow,
            policies,
            action.payload.request,
            action.payload.isProductAdmin,
            action.payload.descendantsMap,
            action.payload.categoriesMap
          )
        : null;
    state.formIsLocked =
      action.payload.request && !noPol
        ? isLocked(action.payload.request, state.transition)
        : true;
    state.isInit = true;
    state.taskbooksTotal = action.payload.request?.totalTaskbooks || 0;
    state.assetsTotal = action.payload.request?.totalAssets || 0;
    state.expensesTotal = action.payload.expenses.total;
    state.laborTotal = action.payload.labor.total;
    state.issuanceTotal = action.payload.issuance.total;
    state.inventoryTotal =
      action.payload.issuance.total + action.payload.inventory.total;
  });
};
