import { FindRequestsOptions } from "@operations-hero/lib-api-client";
import { createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../project-list";
import { findProjectRequests } from "./findRequests.thunk";

type ProjectRequestProps = {
  loadingStatus: LoadingStatus;
  options: FindRequestsOptions;
  total: number;
};

const initialState: ProjectRequestProps = {
  loadingStatus: "idle",
  options: {},
  total: 0,
};

const projectRequestsSlice = createSlice({
  name: "project/requests",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findProjectRequests.fulfilled, (state, action) => {
      const {
        options,
        requests: { total },
      } = action.payload;
      state.loadingStatus = "fulfilled";
      state.options = options;
      state.total = total;
    });
    builder.addCase(findProjectRequests.pending, (state, action) => {
      state.loadingStatus = "pending";
    });
    builder.addCase(findProjectRequests.rejected, (state, action) => {
      state.loadingStatus = "rejected";
    });
  },
});

export default projectRequestsSlice.reducer;
