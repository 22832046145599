import { ApiAccountSettings } from "@operations-hero/lib-api-client";

export enum Statuses {
  approved = "approved",
  queued = "queued",
  started = "started",
  review = "review",
  hold = "hold",
  closed = "closed",
  canceled = "canceled",
}
export interface StatusRequestEmail {
  status: Statuses;
  isChecked: boolean;
}

export const SETTING_USER_ALLOW_LAST_LOCATION_NEW_REQUESTS =
  "allow-last-location-new-requests";

export const SETTING_USER_SET_COLUMN_VIEW_AS_DEFAULT =
  "set-column-view-as-default";

export const SETTING_USER_ENABLE_STATUS_VISIBILITY_IN_COLUMN_VIEW =
  "enable-status-visibility-in-column-view";

export const SETTING_CLOSED_REQUEST_TIME_PERIOD = "closed-request-time-period";

export function getSettingOrDefault(
  setting: string | number | boolean | Record<string, any>
) {
  return setting === undefined ? true : (setting as boolean);
}

export interface SettingProps {
  update: (
    accountId: string,
    setting: ApiAccountSettings
  ) => Promise<ApiAccountSettings>;
  accountSettings: ApiAccountSettings;
  userSettings?: ApiAccountSettings;
  label?: string;
}

export function getUserOrAccountSettingDefault(
  accountSettings: ApiAccountSettings,
  key: string,
  userSettings?: ApiAccountSettings,
  customDefault?: boolean
) {
  if (userSettings === undefined) {
    return accountSettings[key] === undefined
      ? customDefault ?? true
      : (accountSettings[key] as boolean);
  }
  return userSettings[key] === undefined
    ? accountSettings[key] === undefined
      ? customDefault ?? true
      : (accountSettings[key] as boolean)
    : (userSettings[key] as boolean);
}
