import {
  Avatar,
  AvatarGroup,
  Box,
  Flex,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import {
  ApiProject,
  ApiProjectStatus,
  ApiUserSummary,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import { StatusBadge } from "../../../components/badges/StatusBadge";
import { UserBadge } from "../../../components/badges/UserBadge";
import {
  DataTable,
  DataTableColumn,
} from "../../../components/data-table/DataTable";
import { LocaleDate } from "../../../components/dates/LocaleDate";
import { useScreenBreakpoints } from "../../../hooks/useScreenBreakpoints";
import { ProjectCard } from "./responsive/ProjectCard";

export type ProjectsTableProps = {
  projects: ApiProject[];
};

export const ProjectTable: FC<ProjectsTableProps> = ({ projects }) => {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const { isDesktop } = useScreenBreakpoints();
  const formatter = useMemo(() => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
  }, []);

  const Assignee = (props: { assignees: ApiUserSummary[] }) => {
    const { assignees } = props;
    return (
      <Box w="fit-content">
        {assignees.length === 0 && <Text> - </Text>}
        {assignees.length === 1 && <UserBadge value={assignees[0]} />}
        {assignees.length > 1 && (
          <AvatarGroup max={3} size="xs" spacing={-1}>
            {assignees.map((a) => (
              <Avatar
                key={a.id}
                src={a.profileImage}
                name={`${a.firstName} ${a.lastName}`}
              />
            ))}
          </AvatarGroup>
        )}
      </Box>
    );
  };
  let columns = useMemo<DataTableColumn<ApiProject>[]>(
    () => [
      {
        Header: "Project",
        accessor: (project) => project,
        width: 200,
        Cell: ({ value }: CellProps<ApiProject, ApiProject>) => {
          return (
            <Flex
              flexDir="column"
              justifyContent="space-around"
              height="full"
              gap={2}
            >
              <Text isTruncated fontWeight="semibold">
                {value.name}
              </Text>
              <Assignee assignees={value.supervisors} />
            </Flex>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Budgets",
        accessor: (project) => project,
        disableSortBy: true,
        maxWidth: 100,
        width: 100,
        Cell: ({ value }: CellProps<ApiProject, ApiProject>) => {
          return (
            <Flex flexDir="column" height="full" justifyContent="center">
              <Text>{value.totalBudgets} budgets</Text>
              <Text color="gray" fontSize="smaller">
                Allocated: {formatter.format(value.fundsAllocated)}
              </Text>
            </Flex>
          );
        },
      },
      {
        Header: "Budget Summary",
        accessor: (project) => project,
        width: 100,
        disableSortBy: true,
        maxWidth: 100,
        Cell: ({ value }: CellProps<ApiProject, ApiProject>) => {
          return (
            <Flex flexDir="column" height="full" justifyContent="center">
              <Text>
                {`Remaning: `}
                <Text
                  as="span"
                  color={!!value.fundsRemaining ? "unset" : "red"}
                >
                  {`${formatter.format(value.fundsRemaining)}`}
                </Text>
              </Text>
              <Text
                color="gray.500"
                fontSize="smaller"
              >{`Spent: ${formatter.format(value.fundsSpent)}`}</Text>
            </Flex>
          );
        },
      },
      {
        Header: "Schedule",
        accessor: (project) => ({
          start: new Date(project.start),
          end: new Date(project.end),
        }),
        disableSortBy: true,
        maxWidth: 100,
        width: 100,
        Cell: ({
          value,
        }: CellProps<ApiProject, { start: Date; end: Date }>) => {
          return (
            <Flex flexDir="column" height="full" justifyContent="center">
              <Text>
                Start:{" "}
                <Text as="span" ml={1}>
                  <LocaleDate
                    date={value.start}
                    options={{
                      month: "short",
                      day: undefined,
                      year: "numeric",
                    }}
                  />
                </Text>
              </Text>
              <Text color="gray.500" fontSize="smaller">
                End:{" "}
                <Text as="span">
                  <LocaleDate
                    date={value.end}
                    options={{
                      month: "short",
                      day: undefined,
                      year: "numeric",
                    }}
                  />
                </Text>
              </Text>
            </Flex>
          );
        },
      },
      {
        Header: "Progress",
        accessor: (project) => {
          const startDate = new Date(project.start);
          const endDate = new Date(project.end);

          const durationInTime = endDate.getTime() - startDate.getTime();
          const durationInDays = Math.round(
            durationInTime / (1000 * 3600 * 24)
          );

          const upToNowInTime =
            new Date(Date.now()).getTime() - startDate.getTime();
          const upToNowInDays = Math.round(upToNowInTime / (1000 * 3600 * 24));
          return {
            durationInDays,
            upToNowInDays: upToNowInDays > 0 ? upToNowInDays : 0,
          };
        },
        width: 100,
        disableSortBy: true,
        maxWidth: 100,
        Cell: ({
          value,
        }: CellProps<
          ApiProject,
          { durationInDays: number; upToNowInDays: number }
        >) => {
          return (
            <Flex flexDir="column" height="full" justifyContent="center">
              <Text>
                {`Up to now: `}
                <Text as="span">{value.upToNowInDays} days</Text>
              </Text>
              <Text color="gray.500" fontSize="smaller">
                {`Estimated: `}
                <Text as="span">{value.durationInDays} days</Text>
              </Text>
            </Flex>
          );
        },
      },
      {
        Header: "Status",
        accessor: (project) => {
          const startDate = new Date(project.start);
          const endDate = new Date(project.end);
          const durationInTime = endDate.getTime() - startDate.getTime();
          const durationInDays = Math.round(
            durationInTime / (1000 * 3600 * 24)
          );
          return { durationInDays, status: project.status };
        },
        width: 100,
        maxWidth: 100,
        disableSortBy: true,
        Cell: ({
          value,
        }: CellProps<
          ApiProject,
          { durationInDays: number; status: ApiProjectStatus }
        >) => {
          return (
            <Flex flexDir="column" justifyContent="center" height="full">
              <StatusBadge
                status={value.status}
                badgeProps={{ width: "fit-content" }}
              />
            </Flex>
          );
        },
      },
    ],
    [formatter]
  );

  let cards = useMemo<DataTableColumn<ApiProject>[]>(() => {
    return [
      {
        Header: "Col1",
        accessor: (project) => project,
        width: 200,
        Cell: ({ value }: CellProps<ApiProject, ApiProject>) => {
          return <ProjectCard project={value} />;
        },
        boxProps: {
          borderStyle: "hidden",
        },
      },
    ];
  }, []);

  const onRowClick = useCallback(
    (project: ApiProject) => {
      navigate(`/planning/projects/${project.id}`);
    },
    [navigate]
  );

  return (
    <DataTable
      columns={isDesktop ? columns : cards}
      data={projects}
      headerRest={{ fontSize: "sm" }}
      onRowClick={onRowClick}
      hiddenHeader={isDesktop ? false : true}
      rowProps={{
        cursor: "pointer",
        _hover: isDesktop
          ? {
              bg: colorMode === "light" ? "gray.100" : "blue.800",
            }
          : undefined,
      }}
      rest={{
        overflow: "hidden",
      }}
    />
  );
};
